import paper from 'paper';
import {
  DrawerMouseTool,
  createPaperTool,
  ToolProps
} from '@/components/ImageAnnotator/tools';
import { SkeletonHelper } from '@/components/ImageAnnotator/skeleton/skeleton-helper';
import { SkeletonContainer } from '@/components/ImageAnnotator/annotation-container';

export function makeSkeletonTool({
  props
}: {
  props: ToolProps;
}): DrawerMouseTool {
  let mouseDown: paper.Point = null;
  let paperTool: paper.Tool = null;
  let skeleton: SkeletonHelper = null;
  let isDragging = false;

  const cursor = 'crosshair';

  function onMouseDown(event: paper.MouseEvent) {
    console.debug(`Skeleton tool: mouse down`, event.point);
    if (props.imageBounds.contains(event.point)) {
      mouseDown = event.point;
      return;
    }
    mouseDown = null;
  }

  function onMouseMove(_: paper.MouseEvent) {
    updateCursor();
  }

  function updateCursor() {
    props.setCursor?.(cursor);
  }

  /**
   * When mouse left button release, skeleton get stick to the mouse clicked position and creates new movable skeleton object.
   * @param event Paper Event
   */
  function onMouseUp(event: paper.MouseEvent) {
    console.debug(`Skeleton tool: mouse up`, event.point);
    if (!isDragging || !mouseDown) {
      isDragging = false;
      mouseDown = null;
      return;
    }

    if (!props.imageBounds.contains(event.point) || !skeleton) {
      return;
    }

    const clone = skeleton.clone();
    const pathContainer = SkeletonContainer.fromSkeletonHelper(clone);
    skeleton.remove();

    props.createAnnotation({ container: pathContainer });
  }

  function onMouseDrag(event: paper.MouseEvent) {
    // console.debug(`Skeleton tool: mouse drag`, event.point);
    isDragging = true;
    if (!props.imageBounds.contains(event.point)) {
      return;
    }
    if (skeleton) {
      skeleton.remove();
      skeleton = null;
    }

    const sizes = props.sizes.value;
    const graph = props.skeletonGraph.value;
    skeleton = SkeletonHelper.fromBox({
      topLeft: mouseDown,
      bottomRight: event.point,
      sizes,
      graph
    });
  }

  function deactivate() {
    if (skeleton) {
      skeleton.remove();
    }

    paperTool.remove();
  }

  function setupPaperTool() {
    const tool = createPaperTool({
      onMouseUp: ev => onMouseUp(ev),
      onMouseDown: ev => onMouseDown(ev),
      onMouseMove: ev => onMouseMove(ev),
      onMouseDrag: ev => onMouseDrag(ev),
      props,
      cursor
    });
    return tool;
  }

  paperTool = setupPaperTool();

  return {
    deactivate
  };
}
