




import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    element: {
      required: true,
      type: HTMLElement as PropType<HTMLElement>
    }
  },
  created() {
    this.element.addEventListener('wheel', this.handleWheelEvent);
  },
  beforeDestroy() {
    this.element.removeEventListener('wheel', this.handleWheelEvent);
  },
  methods: {
    handleWheelEvent(event: WheelEvent) {
      this.$emit('wheel', event);
    }
  }
});
