











































import { defineComponent, PropType } from '@vue/composition-api';
import { ImageAnnotation, ImageAnnotationGroup } from '../types';
import AddMetadataButton from './AddMetadataButton.vue';
import ManageLabelGroup from './ManageLabelGroup.vue';
import Tooltip from '@/components/molecules/Tooltip.vue';

export default defineComponent({
  props: {
    isSelected: {
      required: true,
      type: Boolean
    },
    label: {
      required: true,
      type: Object as PropType<ImageAnnotation>
    },
    groupsForLabel: {
      required: true,
      type: Array as PropType<ImageAnnotationGroup[]>
    },
    groups: {
      required: true,
      type: Array as PropType<ImageAnnotationGroup[]>
    }
  },
  components: {
    AddMetadataButton,
    ManageLabelGroup,
    Tooltip
  }
});
