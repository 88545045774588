var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"caption px-2 text-uppercase"},[_vm._v("Image annotation")]),_c('div',{staticClass:"d-flex flex-row justify-start mt-1"},[_c('div',{staticClass:"px-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey",attrs:{"x-small":"","icon":""},on:{"click":_vm.prevClicked}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('v-card',{staticClass:"pa-0 ma-0",attrs:{"color":"transparent","flat":""}},[_c('v-list-item',{staticClass:"ma-0 pa-0 white--text"},[_c('v-list-item-content',{},[_c('v-list-item-title',{staticClass:"white--text"},[_vm._v(" Navigate to previous image "),_c('kbd',{staticClass:"mx-3 my-3 py-3 white--text"},[_vm._v(" a ")])])],1)],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey ml-2",attrs:{"x-small":"","icon":""},on:{"click":_vm.nextClicked}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-chevron-right")])],1)]}}])},[_c('v-card',{staticClass:"pa-0 ma-0",attrs:{"color":"transparent","flat":""}},[_c('v-list-item',{staticClass:"ma-0 pa-0 white--text"},[_c('v-list-item-content',{},[_c('v-list-item-title',{staticClass:"white--text"},[_vm._v(" Navigate to next image "),_c('kbd',{staticClass:"mx-3 my-3 py-3 white--text"},[_vm._v(" d ")])])],1)],1)],1)],1)],1),_c('div',{staticClass:"align-self-center caption ml-2"},[_vm._v(" "+_vm._s(_vm.status.activeAssetIndex + 1)+"/"+_vm._s(_vm.status.assets)+" ")])]),(_vm.showImageMetadata)?_c('div',[(_vm.imageFileName)?_c('div',{staticClass:"caption px-2 d-inline-block text-truncate mt-2 image-metadata"},[_c('div',{staticClass:"caption font-weight-bold"},[_vm._v("Filename")]),_c('tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"image-metadata--content"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.imageFileName))])]}}],null,false,2147065974)},[_c('span',[_vm._v(_vm._s(_vm.imageFileName))])])],1):_vm._e()]):_vm._e(),(_vm.isAdmin)?_c('div',[_c('v-btn',{class:"ml-2 pa-0 primary--text caption font-weight-medium",attrs:{"id":"project-link","text":"","small":"","to":_vm.projectRoute}},[_vm._v("Back to project")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }