



















import { defineComponent } from '@vue/composition-api';
import KeyboardShortcutIcon from './KeyboardShortcutIcon.vue';
export default defineComponent({
  name: 'ImageAnnotationViewportControl',
  components: {
    KeyboardShortcutIcon
  }
});
