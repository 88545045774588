import paper from 'paper';
import {
  computed,
  onBeforeUnmount,
  ref,
  Ref,
  watch
} from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';
import { whenever } from '@vueuse/core';
import _find from 'lodash/find';
import {
  Box,
  ImageAnnotation,
  ImageAnnotationGeometry,
  Polygon,
  Skeleton
} from '@/components/ImageAnnotator/types';
import { ImageFx } from '@/components/ImageAnnotator/imagefx';
import {
  DEFAULT_COLOR,
  SkeletonGraphDefinition
} from '@/components/ImageAnnotator/config';
import {
  AnnotationContainer,
  annotationPathContainerFromGeometry,
  pixelGeometryFromAnnotationPathContainer
} from '@/components/ImageAnnotator/annotation-container';
import {
  scaleRelativeBoxToImage,
  scaleToRelativeBox
} from '@/components/ImageAnnotator/utils';
import {
  ANNOTATION_TOOLS,
  selectObject
} from '@/components/ImageAnnotator/tools';
import { VisualElementSizes } from './scaling';

async function drawImageRaster({
  source,
  view
}: {
  source: string;
  view: paper.View;
}): Promise<paper.Raster> {
  const raster = new paper.Raster({
    source,
    position: view.center
  });

  return new Promise(resolve => {
    // Move top-left to origin
    raster.onLoad = async () => {
      raster.translate(new paper.Point(-raster.bounds.x, -raster.bounds.y));
      resolve(raster);
    };
  });
}

export function useZoom({
  bounds,
  view
}: {
  bounds: Ref<paper.Rectangle | null>;
  view: Ref<paper.View | null>;
}) {
  const ZOOM_FACTOR = 1.1;

  function setCenter(point: paper.Point) {
    view.value.center = point;
  }

  function zoomBy(factor: number, point: paper.Point) {
    const view_ = view.value;
    if (!view_) {
      return;
    }
    const c = view_.center;
    const p = point;
    const pc = p.subtract(c);
    const beta = 1.0 / factor;
    const a = p.subtract(pc.multiply(beta)).subtract(c);
    view_.zoom = view_.zoom / beta;
    setCenter(c.add(a));
    onViewUpdate({ view: view_ });
  }

  function zoomIn(point: paper.Point) {
    zoomBy(ZOOM_FACTOR, point);
  }

  function zoomOut(point: paper.Point) {
    zoomBy(1.0 / ZOOM_FACTOR, point);
  }

  function moveCenter(delta: paper.Point) {
    setCenter(view.value.center.add(delta));
    onViewUpdate({ view: view.value });
  }

  function fitViewToImage() {
    const viewBounds = view.value.bounds;
    const scaleRatio = Math.min(
      viewBounds.width / bounds.value.width,
      viewBounds.height / bounds.value.height
    );
    view.value.translate(viewBounds.center.subtract(bounds.value.center));
    view.value.scale(scaleRatio);
    onViewUpdate({ view: view.value });
  }

  const initialized = computed(() => !!view.value && !!bounds.value);

  const viewUpdateListeners = [] as (({
    view
  }: {
    view: paper.View;
  }) => void)[];

  function registerViewUpdateListener(
    fn: ({ view }: { view: paper.View }) => void
  ) {
    viewUpdateListeners.push(fn);
  }

  function onViewUpdate({ view }: { view: paper.View }) {
    for (const listener of viewUpdateListeners) {
      listener({ view });
    }
  }

  // Center view once image raster is available
  whenever(initialized, () => {
    console.log('Fitting view to image');
    fitViewToImage();
  });

  return {
    setCenter,
    moveCenter,
    in: zoomIn,
    out: zoomOut,
    fitViewToImage,
    initialized,
    registerViewUpdateListener
  };
}

const BLACK = new paper.Color(0, 0, 0);
const GREEN = new paper.Color(0, 1, 0);

const LABEL_TEXT_FONT_WEIGHT = 700;

export function useUpdateObjectsOnViewUpdate({
  containers,
  sizes,
  view
}: {
  containers: Ref<AnnotationContainer[]>;
  sizes: Ref<VisualElementSizes>;
  view: Ref<paper.View>;
}) {
  function updateContainers() {
    for (const container of containers.value) {
      container.onVisualElementScaleUpdate?.({
        view: view.value,
        sizes: sizes.value
      });
    }
    view.value.update();
  }

  watch(sizes, updateContainers);

  const labelIds = computed(() =>
    containers.value.map(container => container.labelId)
  );

  // Hack: Update sizes when containers have been added or deleted.
  // Ideally, every container would set their scaling properly when drawn.
  watch(labelIds, updateContainers);

  return { update: updateContainers };
}

export interface LabelTexts {
  clear(): void;
  addLabelText(annotation: AnnotationContainer): void;
  scaleToView(): void;
}

export function useLabelTexts({
  sizes,
  getAnnotationById
}: {
  sizes: Ref<VisualElementSizes>;
  getAnnotationById: (labelId: string) => ImageAnnotation | undefined;
}): LabelTexts {
  const labelItems = ref(null) as Ref<paper.Group | null>;

  function clear() {
    labelItems.value?.remove();
  }

  function addLabelText(annotation: AnnotationContainer) {
    clear();

    const textContent = getAnnotationById(annotation.labelId)?.label;

    if (!textContent) {
      console.warn('Cannot add empty label text');
      return;
    }

    const bounds = annotation.bounds;
    const padding = 2.5;

    const position = bounds.topLeft.add(new paper.Point(-7.5, -20));
    const text = new paper.PointText(position);
    text.justification = 'left';
    text.fillColor = BLACK;
    text.fontWeight = LABEL_TEXT_FONT_WEIGHT;
    text.fontSize = sizes.value.fontSize;
    text.content = textContent;
    text.fillColor = BLACK;

    const textBounds = text.bounds;
    const rectBounds = new paper.Rectangle(
      textBounds.topLeft.add(new paper.Point(-2.5, -2.5)),
      new paper.Size(
        textBounds.width + 2 * padding,
        textBounds.height + 2 * padding
      )
    );
    const rect = new paper.Shape.Rectangle(rectBounds);
    rect.fillColor = GREEN;
    rect.strokeColor = BLACK;

    text.insertAbove(rect);
    labelItems.value = new paper.Group();
    labelItems.value.addChildren([rect, text]);
  }

  function scaleToView() {
    if (!labelItems.value) {
      return;
    }
    const rect = labelItems.value.children[0] as paper.Shape;
    const text = labelItems.value.children[1] as paper.PointText;
    const padding = 2.5;
    text.fontSize = sizes.value.fontSize;
    const textBounds = text.bounds;
    const rectangleWidth = textBounds.width + 2 * padding;
    const rectangleHeight = textBounds.height + 2 * padding;
    rect.size = new paper.Size(rectangleWidth, rectangleHeight);
    rect.position = text.position;
  }

  return { addLabelText, clear, scaleToView };
}

export function useObjects({
  annotations,
  labelColors,
  selectedAnnotationId,
  imageBounds,
  updateAnnotation,
  raster,
  layer,
  view,
  sizes
}: {
  annotations: Ref<ImageAnnotation[]>;
  labelColors: Ref<Record<string, paper.Color>>;
  selectedAnnotationId: Ref<string | null>;
  imageBounds: Ref<paper.Rectangle | null>;
  updateAnnotation: (id: string, newValue: ImageAnnotation) => void;
  raster: Ref<paper.Raster | null>;
  layer: Ref<paper.Layer | null>;
  view: Ref<paper.View | null>;
  sizes: Ref<VisualElementSizes>;
}) {
  const containers = ref([]) as Ref<AnnotationContainer[]>;

  function getAnnotationById(id: string): ImageAnnotation | undefined {
    return _find(annotations.value, annotation => annotation._id === id);
  }

  const labelTexts = useLabelTexts({
    sizes,
    getAnnotationById
  });

  const convertToPixelCoordinates = (geometry: ImageAnnotationGeometry) => {
    const boxToPixelCoordinates = (box: Box): Box => {
      const scaledRectangle = scaleRelativeBoxToImage(
        box.rectangle,
        imageBounds.value
      );
      return Box.fromPaperRectangle(scaledRectangle);
    };

    return geometry instanceof Box
      ? boxToPixelCoordinates(geometry)
      : geometry.fractionalToPixels(imageBounds.value);
  };

  function drawImageAnnotations(): void {
    const containers_ = [] as AnnotationContainer[];
    for (const annotation of annotations.value) {
      containers_.push(drawImageAnnotation({ annotation }));
    }
    containers.value = containers_;
  }

  function clearLabelTexts() {
    // For now, do not try to show the label text when moving segments or path
    labelTexts.clear();
  }

  function drawImageAnnotation({
    annotation
  }: {
    annotation: ImageAnnotation;
  }): AnnotationContainer {
    const labelId = annotation._id;
    const geometry = annotation.geometry;

    const scaled = convertToPixelCoordinates(geometry);

    console.log(`Drawing object on image`, scaled);

    const pathContainer = annotationPathContainerFromGeometry({
      labelId,
      geometry: scaled,
      sizes: sizes.value
    });

    setColorFromLabel(annotation, pathContainer);

    return pathContainer;
  }

  function hitTest(point: paper.Point, options: any): AnnotationContainer[] {
    return containers.value.filter(obj => !!obj.hitTest(point, options));
  }

  function setColorFromLabel(
    annotation: ImageAnnotation,
    pathContainer: AnnotationContainer
  ): void {
    if (!pathContainer.path) {
      return;
    }
    const stroke =
      labelColors.value[annotation.label] || new paper.Color(DEFAULT_COLOR);

    const transparent = new paper.Color(
      stroke.red,
      stroke.green,
      stroke.blue,
      0.1
    );
    pathContainer.path.fillColor = transparent;
    pathContainer.path.strokeColor = stroke;
  }

  function deselectPathContainer(container: AnnotationContainer) {
    container.deselect();
  }

  function updateAnnotationGeometryInStore(
    objPathContainer: AnnotationContainer
  ) {
    const object = _find(containers.value, obj => obj === objPathContainer);

    if (!object) {
      throw new Error(
        'Could not find object in the list of objects, cannot update'
      );
    }

    const id = object.labelId;
    const geometryInPixelCoordinates = pixelGeometryFromAnnotationPathContainer(
      objPathContainer
    );

    let geometryInRelativeCoordinates: ImageAnnotationGeometry;
    if (geometryInPixelCoordinates instanceof Box) {
      const scaledRectangle = scaleToRelativeBox(
        geometryInPixelCoordinates.rectangle,
        imageBounds.value
      );
      geometryInRelativeCoordinates = Box.fromPaperRectangle(scaledRectangle);
    } else if (geometryInPixelCoordinates instanceof Polygon) {
      geometryInRelativeCoordinates = geometryInPixelCoordinates.pixelsToFractional(
        imageBounds.value
      );
    } else if (geometryInPixelCoordinates instanceof Skeleton) {
      geometryInRelativeCoordinates = geometryInPixelCoordinates.pixelsToFractional(
        imageBounds.value
      );
    } else {
      throw new Error(
        `Unknown geometry type: ${typeof geometryInPixelCoordinates}`
      );
    }

    const oldAnnotation = getAnnotationById(id);

    console.debug(
      `Updating object from geometry to geometry`,
      oldAnnotation.geometry,
      geometryInRelativeCoordinates
    );

    const newAnnotation = {
      ...oldAnnotation,
      geometry: geometryInRelativeCoordinates
    };
    updateAnnotation(id, newAnnotation);
  }

  function deselectObjects(): void {
    for (const container of containers.value) {
      deselectPathContainer(container);
    }
    labelTexts.clear();
  }

  function selectPathContainer(container: AnnotationContainer) {
    container.select();
    if (view.value) {
      container.onVisualElementScaleUpdate?.({
        sizes: sizes.value,
        view: view.value
      });
    }
  }

  function getSelectedAnnotationPathContainer(): AnnotationContainer | null {
    const o = containers.value.find(obj => obj.selected);
    return o || null;
  }

  function redrawSelected() {
    const wasSelected = getSelectedAnnotationPathContainer();
    if (!wasSelected) {
      return;
    }

    // the idea is to make enough changes to the selected annotation path
    // that paper.js is tricked to update the canvas

    // the following seems to be enough
    deselectPathContainer(wasSelected);
    selectPathContainer(wasSelected);
  }

  function refreshSelection() {
    deselectObjects();

    const id = selectedAnnotationId.value;
    if (id == null) {
      return;
    }

    const container = _find(
      containers.value,
      objOnImage => objOnImage.labelId == id
    );

    if (!container) {
      console.error(
        'Cannot find annotation to select it',
        id,
        containers.value
      );
      return;
    }

    selectPathContainer(container);
    setColorFromLabel(getAnnotationById(id), container);
    labelTexts.addLabelText(container);
  }

  function removeAll() {
    labelTexts.clear();
    for (const container of containers.value) {
      container.remove();
    }

    // HACK: Also delete any other objects in layer.
    // These are left behind by some tools like autofit.
    layer.value.removeChildren();
  }

  watch(selectedAnnotationId, () => refreshSelection());
  whenever(raster, () => {
    console.log('Adding objects to image');

    layer.value.activate();
    drawImageAnnotations();
  });

  watch(
    annotations,
    () => {
      // for now, simply redraw all image objects
      // on any change to stored annotations

      // potentially could diff the stored annotations against the
      // objects already on the canvas
      // to prevent e.g. self-invoked events from redrawing
      console.log(`Annotations changed, redrawing all objects`);

      layer.value.activate();
      removeAll();
      drawImageAnnotations();
      refreshSelection();
    },
    { deep: true }
  );

  return {
    containers,
    clearLabelTexts,
    drawImageAnnotations,
    removeAll,
    redrawSelected,
    refreshSelection,
    updateAnnotationGeometryInStore,
    hitTest,
    getSelectedAnnotationPathContainer,
    labelTexts
  };
}

export function useAttachEventHandlers({
  selectedAnnotationId,
  bounds,
  containers,
  selectedToolIndex
}: {
  selectedAnnotationId: Ref<string | null>;
  bounds: Ref<paper.Rectangle | null>;
  containers: Ref<AnnotationContainer[]>;
  selectedToolIndex: Ref<number>;
}) {
  function detachAll() {
    for (const container of containers.value) {
      container.detachEventHandlers?.();
    }
  }

  function attachEventHandlersForSelected() {
    if (!selectedAnnotationId.value) {
      return;
    }
    const selected = _find(
      containers.value,
      container => container.labelId === selectedAnnotationId.value
    );

    console.debug(
      `Attaching event handlers for annotation: ${selected.labelId}`
    );
    selected?.attachEventHandlers?.({ bounds: bounds.value });
  }

  const isSelectToolSelected = computed(() => selectedToolIndex.value === 0);

  function updateEventHandlers() {
    detachAll();
    if (isSelectToolSelected.value) {
      attachEventHandlersForSelected();
    }
  }

  watch(
    [selectedAnnotationId, selectedToolIndex, containers],
    updateEventHandlers
  );

  onBeforeUnmount(detachAll);
}

export function useCreateNewAnnotation({
  imageBounds,
  selectedLabel,
  addAnnotation,
  selectedSkeletonGraph
}: {
  imageBounds: Ref<paper.Rectangle | null>;
  selectedLabel: Ref<string>;
  addAnnotation: (annotation: ImageAnnotation) => void;
  selectedSkeletonGraph: Ref<SkeletonGraphDefinition | null>;
}) {
  function createAnnotationFromPathContainer({
    container
  }: {
    container: AnnotationContainer;
  }): void {
    const geometryInPixelCoordinates = pixelGeometryFromAnnotationPathContainer(
      container
    );

    let geometryInRelativeCoordinates: ImageAnnotationGeometry;
    let label: string;
    if (geometryInPixelCoordinates.kind == 'Box') {
      const scaledRectangle = scaleToRelativeBox(
        geometryInPixelCoordinates.rectangle,
        imageBounds.value
      );
      geometryInRelativeCoordinates = Box.fromPaperRectangle(scaledRectangle);
      label = selectedLabel.value;
    } else if (geometryInPixelCoordinates.kind == 'Polygon') {
      geometryInRelativeCoordinates = geometryInPixelCoordinates.pixelsToFractional(
        imageBounds.value
      );
      label = selectedLabel.value;
    } else if (geometryInPixelCoordinates.kind == 'Skeleton') {
      // Skeleton
      geometryInRelativeCoordinates = geometryInPixelCoordinates.pixelsToFractional(
        imageBounds.value
      );
      label = selectedSkeletonGraph.value.name;
    } else {
      throw new Error(
        `Unknown geometry type: ${typeof geometryInPixelCoordinates}`
      );
    }

    const newAnnotation: ImageAnnotation = {
      _id: uuidv4(),
      label,
      geometry: geometryInRelativeCoordinates,
      context: {}
    };
    addAnnotation(newAnnotation);
  }
  return { createAnnotationFromPathContainer };
}

export function usePaperJS({
  canvas
}: {
  canvas: Ref<HTMLCanvasElement | null>;
}) {
  // Scope
  const scope = ref(null) as Ref<paper.PaperScope | null>;

  const imageLayer = ref(null) as Ref<paper.Layer>;
  const drawLayer = ref(null) as Ref<paper.Layer>;
  const maskLayer = ref(null) as Ref<paper.Layer>;

  whenever(canvas, () => {
    console.log(`Setting up paper scope`);
    paper.setup(canvas.value);
    scope.value = paper;
    imageLayer.value = new scope.value.Layer();
    drawLayer.value = new scope.value.Layer();
    maskLayer.value = new scope.value.Layer();
  });

  const view = computed(() => {
    return scope.value?.view;
  });

  function purge() {
    scope.value?.project.remove();
  }

  return { scope, imageLayer, drawLayer, maskLayer, purge, view };
}

export function useBackgroundImage({
  imageSrc,
  scope,
  layer
}: {
  imageSrc: Ref<string>;
  scope: Ref<paper.PaperScope | null>;
  layer: Ref<paper.Layer | null>;
}) {
  // Background image
  const raster = ref(null) as Ref<paper.Raster | null>;
  const imageBounds = computed(() => raster.value?.bounds);
  const imageElement = ref(null) as Ref<HTMLImageElement | null>;

  const view = computed(() => scope.value?.view);
  const ready = ref(false);

  const imageFromSrc = (src: string): Promise<HTMLImageElement> => {
    const img = new Image();
    img.src = src;
    return new Promise(resolve => {
      img.onload = () => {
        resolve(img);
      };
    });
  };

  // Draw image once source and view are available
  watch([imageSrc, scope], async () => {
    if (!imageSrc.value || !scope.value) {
      ready.value = false;
      raster.value = null;
      return;
    }

    console.log('Starting drawing background image...');
    layer.value.activate();
    imageElement.value = await imageFromSrc(imageSrc.value);
    raster.value = await drawImageRaster({
      source: imageSrc.value,
      view: view.value
    });
    ready.value = true;
    console.log('Background image drawn');
  });

  return { raster, bounds: imageBounds, ready, imageElement };
}

export function useImageFx({ raster }: { raster: Ref<paper.Raster | null> }) {
  const imageFx = computed(() => {
    return raster.value ? new ImageFx({ raster: raster.value }) : null;
  });

  return { imageFx };
}

export function useHitTest({
  scope,
  hitTestObjects
}: {
  scope: Ref<paper.PaperScope | null>;
  hitTestObjects: (point: paper.Point, options: any) => AnnotationContainer[];
}) {
  function hitTest(point: paper.Point, options: any) {
    const hitResult = scope.value?.project.hitTest(point, options);

    const objects = hitTestObjects(point, options);

    return { hitResult, objects };
  }
  return { hitTest };
}

export function useMask({
  scope,
  layer
}: {
  scope: Ref<paper.PaperScope | null>;
  layer: Ref<paper.Layer | null>;
}) {
  function show({
    maskBase64,
    center
  }: {
    maskBase64: string;
    center: paper.Point;
  }) {
    console.log(`Adding mask`);

    if (!scope.value) {
      return;
    }
    const activeLayerOld = scope.value.project.activeLayer;
    layer.value.activate();
    const raster = new paper.Raster(maskBase64);
    raster.position = center;
    raster.opacity = 0.8;
    activeLayerOld.activate();
    return raster;
  }

  function remove() {
    layer.value?.removeChildren();
  }

  return {
    show,
    remove
  };
}

export function useUpdateLabelTextsOnViewUpdate({
  labelTexts,
  sizes,
  view
}: {
  labelTexts: LabelTexts;
  sizes: Ref<VisualElementSizes>;
  view: Ref<paper.View>;
}) {
  function updateLabelTexts() {
    labelTexts.scaleToView();
    view.value.update();
  }

  watch(sizes, updateLabelTexts);
}

export type Objects = ReturnType<typeof useObjects>;
export type Zoom = ReturnType<typeof useZoom>;
export type Mask = ReturnType<typeof useMask>;
