













import Vue, { PropType } from 'vue';
import { ToolUIConnector } from './tools';

export default Vue.extend({
  name: 'AnnotationToolHelp',
  props: {
    tool: {
      required: true,
      type: Object as PropType<ToolUIConnector>
    }
  }
});
