











































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import { ImageAnnotationGroup } from '../types';
import Tooltip from '@/components/molecules/Tooltip.vue';

export default defineComponent({
  props: {
    group: {
      required: true,
      type: Object as PropType<ImageAnnotationGroup>
    }
  },
  components: {
    Tooltip
  },
  setup(props, { emit }) {
    const { group } = toRefs(props);

    const edit = ref(false);

    const name = ref(group.value.name);

    watch(
      group,
      function() {
        name.value = group.value.name;
      },
      {
        deep: true
      }
    );

    const isNameValid = computed(() => {
      return name.value.length > 0;
    });

    function cancel() {
      name.value = group.value.name;
      edit.value = false;
    }

    function save() {
      if (group.value.name === name.value) {
        edit.value = false;
        return;
      }
      if (!isNameValid.value) {
        cancel();
        return;
      }
      const newGroup = { ...group.value, name: name.value };
      edit.value = false;
      emit('update', newGroup);
    }

    return { edit, name, save, cancel };
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  }
});
