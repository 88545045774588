










































import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs
} from '@vue/composition-api';
import _indexOf from 'lodash/indexOf';

import { firstTruthy, or } from '@/utils';
import { ANNOTATION_UI_PROPS } from '@/components/annotations/props';
import { ImageAnnotationGroup, UserSelections } from './types';
import LoadingModal from '../LoadingModal.vue';
import ImageAnnotationView from './ImageAnnotationView.vue';
import { resolveParameters } from './config';
import { ImageAnnotation } from './types';
import { imageAnnotationToJSON } from './transport';
import {
  useConfirmDialog,
  useHasUnsavedLabels,
  useImageAnnotationGroups,
  useImageAnnotationLabels,
  useInMemoryGroups,
  useInMemoryLabels,
  useSaving
} from './functions';
import { useActiveImageSrc, useSaveStatus } from '../functions';
import ConfirmDialog from './ConfirmDialog.vue';
import InvalidLabelsSnackbar from '@/components/ImageAnnotator/molecules/InvalidLabelsSnackbar.vue';
import { useSAM } from '@/components/ImageAnnotator/sam/use';

export default defineComponent({
  name: 'ImageAnnotatorController',
  components: {
    ImageAnnotationView,
    LoadingModal,
    ConfirmDialog,
    InvalidLabelsSnackbar
  },
  props: ANNOTATION_UI_PROPS,
  setup(props, { emit }) {
    const {
      layers,
      activeAsset,
      annotationConfiguration,
      fetchMedia,
      onAnnotateAsset,
      status,
      assets,
      onFinishAnnotating,
      registerRouteUpdateListener
    } = toRefs(props);

    const assetId = computed(() => activeAsset.value.id);

    const {
      annotation: assetAnnotationFromServer,
      loading: loadingAssetAnnotation,
      error: errorLoadingAssetAnnotation,
      mutate: reloadAssetAnnotation,
      loadedForAssetId: assetAnnotationLoadedForAssetId
    } = layers.value.useAnnotation(assetId);

    const {
      annotation: assetPredictionFromServer,
      loading: loadingAssetPrediction,
      error: errorLoadingAssetPrediction,
      loadedForAssetId: assetPredictionLoadedForAssetId
    } = layers.value.usePrediction(assetId);

    const hasPreviousAssetAnnotationFromServer = computed(
      () => !!assetAnnotationFromServer.value
    );

    const { groupsFromServer } = useImageAnnotationGroups({
      assetAnnotationFromServer
    });

    const uiParameters = computed(() => {
      const parameters = annotationConfiguration.value?.parameters || {};
      return resolveParameters(parameters);
    });

    const { labelsFromServer } = useImageAnnotationLabels({
      assetAnnotationFromServer,
      uiParameters
    });

    const {
      // These are null until refreshed (when asset changes)
      inMemoryLabels: labelsInMemory,
      setInMemoryLabels,
      loadedForAssetId: labelsInMemoryLoadedForAssetId,
      invalidLabels
    } = useInMemoryLabels({
      assetId,
      uiParameters,
      assetAnnotationFromServer,
      assetPredictionFromServer,
      assetAnnotationLoadedForAssetId,
      assetPredictionLoadedForAssetId
    });

    const {
      inMemoryGroups: groupsInMemory,
      setInMemoryGroups,
      loadedForAssetId: groupsInMemoryLoadedForAssetId
    } = useInMemoryGroups({
      assetId,
      assetAnnotationFromServer,
      assetAnnotationLoadedForAssetId,
      assetPredictionFromServer,
      assetPredictionLoadedForAssetId
    });

    const hasPreviousAssetPredictionFromServer = computed(
      () => !!assetPredictionFromServer.value
    );

    const hasUnsavedLabels = useHasUnsavedLabels({
      labelsFromServer,
      labelsInMemory,
      groupsFromServer,
      groupsInMemory
    });

    const {
      src: activeImageSrc,
      loading: loadingImage,
      error: errorLoadingImage
    } = useActiveImageSrc(activeAsset, fetchMedia);

    // Flag for loading SAM
    const loadSAM = ref(false);

    const {
      embeddings,
      loading: loadingSAM,
      session,
      error: errorLoadingSAM
    } = useSAM({ asset: activeAsset, load: loadSAM });

    const sam = reactive({
      embeddings,
      loading: loadingSAM,
      error: errorLoadingSAM,
      session
    });

    const loading = or(
      computed(
        () =>
          loadingAssetAnnotation.value &&
          assetAnnotationLoadedForAssetId.value !== activeAsset.value.id
      ),
      loadingAssetPrediction,
      loadingImage
    );

    const initializing = computed(
      () =>
        loading.value ||
        labelsInMemoryLoadedForAssetId.value !== assetId.value ||
        labelsInMemory.value == null ||
        groupsInMemoryLoadedForAssetId.value !== assetId.value
    );

    const errorLoading = firstTruthy(
      errorLoadingAssetAnnotation,
      errorLoadingAssetPrediction,
      errorLoadingImage
    );

    const {
      saving,
      error: errorSaving,
      success: successSaving,
      saveLabels
    } = useSaving({
      asset: activeAsset,
      onAnnotateAsset,
      reloadAssetAnnotation,
      labelsFromServer,
      groupsFromServer
    });

    const saveState = reactive({
      saving,
      error: errorSaving,
      success: successSaving
    });

    async function saveAnnotations(
      assetId: string,
      labels: ImageAnnotation[],
      groups: ImageAnnotationGroup[]
    ): Promise<ImageAnnotation[]> {
      const labelsAsJSONs = labels.map(label => imageAnnotationToJSON(label));
      console.log(
        `Saving ${labelsAsJSONs.length} labels for asset`,
        labelsAsJSONs
      );
      await saveLabels(assetId, labels, groups);
      return labelsFromServer.value;
    }

    const activeAssetIndex = computed(() => {
      return _indexOf(assets.value, activeAsset.value);
    });

    const annotationStatus = computed(() => {
      return {
        assets: status.value.assets,
        annotated: status.value.annotations,
        activeAssetIndex: activeAssetIndex.value
      };
    });

    const isInitialized = computed(() => !initializing.value);

    const saveStatus = useSaveStatus({
      loading,
      isInitialized,
      errorSaving,
      saving,
      hasUnsavedLabels,
      loadingAssetAnnotation,
      hasAnnotation: hasPreviousAssetAnnotationFromServer
    });

    const onClickNext = () => {
      const ind = activeAssetIndex.value;
      const next = ind + 1;
      if (next >= assets.value.length) {
        return onFinishAnnotating.value();
      }
      emit('update:activeAsset', assets.value[next]);
    };

    const onClickPrevious = () => {
      const ind = activeAssetIndex.value;
      const prev = ind - 1;
      if (prev < 0) {
        return;
      }
      emit('update:activeAsset', assets.value[prev]);
    };

    const persistentUserSelections = ref({} as UserSelections);

    function saveUserSelections(sel: UserSelections): void {
      persistentUserSelections.value = sel;
    }

    async function saveNow() {
      await saveAnnotations(
        assetId.value,
        labelsInMemory.value,
        groupsInMemory.value
      );
    }

    function nextKeyPressed() {
      onClickNext();
    }

    function previousKeyPressed() {
      onClickPrevious();
    }

    const confirmDialogRefs = useConfirmDialog(
      saveNow,
      saveState,
      hasUnsavedLabels,
      registerRouteUpdateListener
    );

    return {
      activeImageSrc,
      assetAnnotationFromServer,
      assetPredictionFromServer,
      loading,
      uiParameters,
      errorLoading,
      errorLoadingMedia: errorLoadingImage,
      labelsFromServer,
      labelsInMemory,
      invalidLabels,
      saveAnnotations,
      annotationStatus,
      activeAssetIndex,
      initializing,
      saving,
      errorSaving,
      saveLabels,
      saveState,
      hasUnsavedLabels,
      saveStatus,
      isInitialized,
      setInMemoryLabels,
      hasPreviousAssetAnnotationFromServer,
      hasPreviousAssetPredictionFromServer,
      onClickNext,
      onClickPrevious,
      persistentUserSelections,
      saveUserSelections,
      ...confirmDialogRefs,
      nextKeyPressed,
      previousKeyPressed,
      // Groups
      groupsInMemory,
      groupsFromServer,
      setInMemoryGroups,
      sam
    };
  }
});
