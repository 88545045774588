import paper from 'paper';
import _indexOf from 'lodash/indexOf';
import { SkeletonNode } from '@/components/ImageAnnotator/types';
import {
  baseSizes,
  VisualElementSizes
} from '@/components/ImageAnnotator/scaling';
import { SkeletonGraphDefinition } from '@/components/ImageAnnotator/config';

const NODE_COLOR = '#FCFCFC';
const STROKE_COLOR = '#2c6dcb';

const LABEL_TEXT_COLOR = '#FCFCFC';

function makeLabelText({
  position,
  text,
  sizes
}: {
  position: paper.Point;
  text: string;
  sizes: VisualElementSizes;
}) {
  const padding = 2.5;
  // Margin between position and text box
  const marginY = sizes.circleRadius + 3;

  const textItem = new paper.PointText(new paper.Point(0, 0));
  textItem.justification = 'left';
  textItem.fillColor = new paper.Color(0, 0, 0);
  textItem.fontWeight = 400;
  textItem.fontSize = sizes.fontSize;
  textItem.content = text;
  textItem.fillColor = new paper.Color(0, 0, 0);

  const textBounds = textItem.bounds;

  const rectangleWidth = textBounds.width + 2 * padding;
  const rectangleHeight = textBounds.height + 2 * padding;
  const rectangleX = position.x;
  const rectangleY = position.y - rectangleHeight - marginY;

  const rectangleTopLeft = new paper.Point(rectangleX, rectangleY);
  const rectBounds = new paper.Rectangle(
    rectangleTopLeft,
    new paper.Size(rectangleWidth, rectangleHeight)
  );
  const rect = new paper.Shape.Rectangle(rectBounds);
  rect.fillColor = new paper.Color(LABEL_TEXT_COLOR);
  rect.strokeColor = new paper.Color(STROKE_COLOR);

  textItem.insertAbove(rect);
  // Center of text is in the center of rectangle
  textItem.position = rect.position;

  const group = new paper.Group([rect, textItem]);

  function scaleToView({ sizes }: { sizes: VisualElementSizes }) {
    textItem.fontSize = sizes.fontSize;
    const rectangleWidth = textItem.bounds.width + 2 * padding;
    const rectangleHeight = textItem.bounds.height + 2 * padding;

    rect.size = new paper.Size(rectangleWidth, rectangleHeight);
    const rectanglePosition = position.add(
      new paper.Point(rect.size.width / 2, -rect.size.height / 2 - marginY)
    );
    rect.position = rectanglePosition;
    textItem.position = rect.position;
  }

  function remove() {
    group.remove();
  }

  return { group, scaleToView, remove };
}

type LabelText = ReturnType<typeof makeLabelText>;

/** Helper class for rendering keypoint label texts */
class LabelTextHelper {
  private readonly helper: SkeletonHelper;
  private labelTexts: LabelText[] = [];

  constructor({ helper }: { helper: SkeletonHelper }) {
    this.helper = helper;
  }

  public add({ sizes }: { sizes: VisualElementSizes }) {
    this.remove();

    for (const node of this.helper.nodes) {
      const position = node.point;

      const labelText = makeLabelText({ position, text: node.name, sizes });

      this.labelTexts.push(labelText);
    }
  }

  public remove() {
    for (const labelText of this.labelTexts) {
      labelText.remove();
    }
    this.labelTexts = [];
  }

  public scaleToView({ sizes }: { sizes: VisualElementSizes }) {
    for (const labelText of this.labelTexts) {
      labelText.scaleToView({ sizes });
    }
  }

  public bringToFront() {
    for (const labelText of this.labelTexts) {
      labelText.group.bringToFront();
    }
  }
}

/** Helper class for handling mouse events */
class EventHandler {
  private readonly helper: SkeletonHelper;

  constructor({ helper }: { helper: SkeletonHelper }) {
    this.helper = helper;
  }

  public attach({ bounds }: { bounds: paper.Rectangle }) {
    console.debug(`Attaching event handlers to skeleton`);
    for (const item of this.helper.nodesGroup.children) {
      item.onMouseDrag = (ev: paper.MouseEvent) => {
        if (ev.modifiers.space) {
          // Dragging
          return;
        }
        const index = _indexOf(this.helper.nodesGroup.children, item);
        this.helper.moveNode(this.helper.nodes[index], ev.delta, bounds);
      };
    }
  }

  public detach() {
    console.debug(`Detaching event handlers from skeleton`);
    for (const item of this.helper.nodesGroup.children) {
      item.onMouseUp = null;
      item.onMouseDrag = null;
      item.onMouseDown = null;
    }
    this.helper.rectangle.onMouseDrag = null;
  }
}

/**
 * Helper class for rendering and editing skeleton
 * */
export class SkeletonHelper {
  public readonly nodesGroup: paper.Group;
  public readonly nodes: SkeletonNode[];
  public readonly graph: SkeletonGraphDefinition;
  public readonly rectangle: paper.Shape.Rectangle;
  private readonly labelTexts: LabelTextHelper;
  public readonly eventHandler: EventHandler;
  private sizes: VisualElementSizes;

  constructor({
    nodesGroup,
    nodes,
    graph,
    rectangle,
    sizes = baseSizes
  }: {
    nodesGroup: paper.Group;
    nodes: SkeletonNode[];
    graph: SkeletonGraphDefinition;
    rectangle: paper.Shape.Rectangle;
    sizes?: VisualElementSizes;
  }) {
    this.nodesGroup = nodesGroup;
    this.nodes = nodes;
    this.graph = graph;
    this.rectangle = rectangle;
    this.labelTexts = new LabelTextHelper({ helper: this });
    this.eventHandler = new EventHandler({ helper: this });
    this.sizes = sizes;
  }

  public clone(): SkeletonHelper {
    const cloneGroup = this.nodesGroup.clone();
    return new SkeletonHelper({
      nodesGroup: cloneGroup,
      nodes: this.nodes,
      graph: this.graph,
      rectangle: this.rectangle.clone(),
      sizes: this.sizes
    });
  }

  public bringToFront() {
    this.nodesGroup.bringToFront();
    this.labelTexts.bringToFront();
  }

  hitTest(point: paper.Point, options) {
    return (
      this.nodesGroup.hitTest(point, options) ||
      this.rectangle.hitTest(point, options)
    );
  }

  onVisualElementScaleUpdate({ sizes }: { sizes: VisualElementSizes }) {
    this.sizes = sizes;
    for (const circle of this.nodesGroup.children) {
      (circle as paper.Shape.Circle).radius = sizes.circleRadius;
      (circle as paper.Shape.Circle).strokeWidth = sizes.circleStrokeWidth;
    }
    this.labelTexts.scaleToView({ sizes });
  }

  /**
   * Move skeleton by delta, keeping it inside image bounds.
   * */
  move(delta: paper.Point, bounds: paper.Rectangle) {
    // Do not try to move the label texts, they're added back when moving finishes
    this.labelTexts.remove();

    const newBoundingRectangleTopLeft = this.rectangle.bounds.topLeft.add(
      delta
    );
    const newBoundingRectangle = new paper.Rectangle(
      newBoundingRectangleTopLeft,
      this.rectangle.size
    );

    if (!bounds.contains(newBoundingRectangle)) {
      return;
    }

    this.nodesGroup.position = this.nodesGroup.position.add(delta);
    this.rectangle.position = this.rectangle.position.add(delta);

    this.nodes.forEach(node => {
      node.point = node.point.add(delta);
    });
  }

  public moveNode(
    node: SkeletonNode,
    delta: paper.Point,
    bounds: paper.Rectangle
  ) {
    const position = node.point;

    const newPosition = position.add(delta);

    if (!bounds.contains(newPosition)) {
      return;
    }

    const index = _indexOf(this.nodes, node);

    const item = this.nodesGroup.children[index];
    item.position = item.position.add(delta);

    node.point = node.point.add(delta);
  }

  /**
   * Build SkeletonHelper inside a box.
   * The nodes are scaled so that the extreme points match the box.
   */
  public static fromBox({
    topLeft,
    bottomRight,
    sizes,
    graph
  }: {
    topLeft: paper.Point;
    bottomRight: paper.Point;
    sizes: VisualElementSizes;
    graph: SkeletonGraphDefinition;
  }): SkeletonHelper {
    // TODO Handle cases where width or height are zero.
    const skeletonNodeXs = graph.nodes.map(node => node.x);
    const skeletonNodeYs = graph.nodes.map(node => node.y);

    const skeletonNodeXMin = Math.min(...skeletonNodeXs);
    const skeletonNodeXMax = Math.max(...skeletonNodeXs);
    const skeletonNodeYMin = Math.min(...skeletonNodeYs);
    const skeletonNodeYMax = Math.max(...skeletonNodeYs);

    const nodes = [] as SkeletonNode[];

    for (const node of graph.nodes) {
      const nodeX =
        topLeft.x +
        ((node.x - skeletonNodeXMin) / (skeletonNodeXMax - skeletonNodeXMin)) *
          (bottomRight.x - topLeft.x);
      const nodeY =
        topLeft.y +
        ((node.y - skeletonNodeYMin) / (skeletonNodeYMax - skeletonNodeYMin)) *
          (bottomRight.y - topLeft.y);

      const skeletonNode = {
        point: new paper.Point(nodeX, nodeY),
        name: node.name
      };

      nodes.push(skeletonNode);
    }

    const { nodes: nodesGroup, rectangle } = SkeletonHelper.makeGroup({
      nodes,
      sizes
    });

    return new SkeletonHelper({
      nodesGroup,
      graph,
      nodes,
      rectangle,
      sizes
    });
  }

  get bounds(): paper.Rectangle {
    return this.nodesGroup.bounds;
  }

  private static makeGroup({
    nodes,
    sizes
  }: {
    nodes: SkeletonNode[];
    sizes: VisualElementSizes;
  }): { nodes: paper.Group; rectangle: paper.Shape.Rectangle } {
    const group = new paper.Group();

    const circles = [] as paper.Shape.Circle[];
    for (const node of nodes) {
      const skeletonNodeCircle = new paper.Shape.Circle({
        center: new paper.Point(node.point.x, node.point.y),
        radius: sizes.circleRadius
      });
      skeletonNodeCircle.name = node.name;
      skeletonNodeCircle.fillColor = new paper.Color(NODE_COLOR);
      skeletonNodeCircle.strokeColor = new paper.Color(STROKE_COLOR);
      skeletonNodeCircle.strokeWidth = sizes.circleStrokeWidth;
      circles.push(skeletonNodeCircle);
    }

    group.addChildren(circles);

    const rectangle = new paper.Shape.Rectangle(group.bounds);
    rectangle.strokeColor = new paper.Color(0, 0, 0, 0.5);
    rectangle.fillColor = new paper.Color(1, 1, 1, 0.05);
    rectangle.sendToBack();

    return { nodes: group, rectangle };
  }

  get selected(): boolean {
    return this.rectangle.selected;
  }

  select() {
    this.rectangle.selected = true;
    this.labelTexts.add({ sizes: this.sizes });
  }

  deselect() {
    this.rectangle.selected = false;
    this.labelTexts.remove();
  }

  public static fromNodes({
    nodes,
    graph,
    sizes
  }: {
    nodes: SkeletonNode[];
    graph: SkeletonGraphDefinition;
    sizes: VisualElementSizes;
  }) {
    const { nodes: nodesGroup, rectangle } = SkeletonHelper.makeGroup({
      nodes,
      sizes
    });
    return new SkeletonHelper({
      nodesGroup,
      graph,
      nodes,
      rectangle,
      sizes
    });
  }

  public remove() {
    this.nodesGroup.remove();
    this.rectangle.remove();
    this.labelTexts.remove();
  }
  /**
   * Generates connections between nodes according to the given connection by SKELETON_NODES.connections
   */
  /* private generateNodeConnections(nodes: paper.Group): paper.Group {
    const skeletonEdgesGroup = new paper.Group();
    for (const node of SKELETON_NODES) {
      if (node.connections.length > 0) {
        for (const key in node.connections) {
          const nodeConnection = new paper.Path.Line({
            from: [
              nodes.children[node.connections[key][0] - 1].position.x,
              nodes.children[node.connections[key][0] - 1].position.y
            ],
            to: [
              nodes.children[node.connections[key][1] - 1].position.x,
              nodes.children[node.connections[key][1] - 1].position.y
            ],
            strokeColor: NODE_CONNECTION_COLOR
          });

          // This nodeConnection.name will use in the skeleton nodes edit function
          nodeConnection.name =
            nodes.children[node.connections[key][0] - 1].name +
            '_' +
            nodes.children[node.connections[key][1] - 1].name;
          skeletonEdgesGroup.addChild(nodeConnection);
        }
      }
    }
    return skeletonEdgesGroup;
  } */
}
