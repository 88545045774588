






























































































import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import { PropType } from 'vue';
import { ImageFx } from '@/components/ImageAnnotator/imagefx';

export default defineComponent({
  props: {
    imageFx: {
      required: true,
      type: Object as PropType<ImageFx | null>
    }
  },
  setup(props, { emit }) {
    const { imageFx } = toRefs(props);

    const brightness = ref(0);
    const contrast = ref(0);

    const fxDisabled = computed(() => !imageFx.value?.enabled);

    watch(brightness, function(value: number) {
      console.debug(`Setting brightness`, value);
      imageFx.value?.brightnessContrast(value / 100.0, contrast.value / 100.0);
    });

    watch(contrast, function(value: number) {
      console.debug(`Setting contrast`, value);
      imageFx.value?.brightnessContrast(
        brightness.value / 100.0,
        value / 100.0
      );
    });

    watch(imageFx, function() {
      brightness.value = 0;
      contrast.value = 0;
    });

    function fitToView() {
      emit('fitViewToImage');
    }

    const fxDisabledMessage =
      'WebGL not supported, please try a more recent browser or device with hardware acceleration.';

    return { brightness, contrast, fxDisabled, fxDisabledMessage, fitToView };
  }
});
