import _fromPairs from 'lodash/fromPairs';
import _invert from 'lodash/invert';
import paper from 'paper';

import {
  ImageAnnotationGeometry,
  ImageAnnotation,
  Polygon,
  Box,
  Skeleton,
  PaperMouseEvent
} from './types';

import {
  DEFAULT_COLOR,
  ImageAnnotatorParameters,
  ImageObjectCategory
} from './config';
import { imageAnnotationFromJSON, imageAnnotationToJSON } from './transport';

export const SELECT_ANNOTATION_TOOL_EVENT = 'selectedAnnotationTool';

export const isImageObjectCategoryType = (
  label: ImageObjectCategory | string
): label is ImageObjectCategory => {
  return (label as ImageObjectCategory).name !== undefined;
};

export const getLabelName = (label: ImageObjectCategory | string): string => {
  return isImageObjectCategoryType(label) ? label['name'] : label;
};

export const getLabelColor = (
  label: ImageObjectCategory | string
): paper.Color => {
  return isImageObjectCategoryType(label)
    ? new paper.Color(label['color'])
    : new paper.Color(DEFAULT_COLOR);
};

export const getLabelNamesFromConfig = (
  labels: Array<ImageObjectCategory | string>
): Array<string> => {
  if (!labels) {
    return [];
  }
  return labels.map(label => getLabelName(label));
};

export const getLabelColorsFromConfig = (
  labels: Array<ImageObjectCategory | string>
): Record<string, paper.Color> => {
  if (!labels) {
    return {};
  }
  return _fromPairs(
    labels.map(label => {
      const name = getLabelName(label);
      const color = getLabelColor(label);
      return [name, color];
    })
  );
};

export interface LabelShortcutHandler {
  handle(ev: paper.KeyEvent): void;
  labelToKey: Record<string, string>;
}

/**
 * Handler for keyboard shortcuts to switch between labels.
 */
export const useLabelShortcutHandler = ({
  labels,
  updateSelectedLabel
}: {
  labels: Array<ImageObjectCategory | string>;
  updateSelectedLabel: (name: string) => void;
}): LabelShortcutHandler => {
  const labelToKeyMap: Record<string, string> = _fromPairs(
    labels
      .map((label, index) => {
        const shortcutKey = index <= 9 ? String((index + 1) % 10) : undefined;
        const name = getLabelName(label);
        return [name, shortcutKey];
      })
      .filter(([_, key]) => key !== undefined)
  );

  const keyToLabelMap = _invert(labelToKeyMap);

  function handle(ev: paper.KeyEvent): void {
    const character = ev.character;

    const maybeLabel = keyToLabelMap[character];

    if (!maybeLabel) {
      return;
    }

    updateSelectedLabel(maybeLabel);
  }

  return { handle, labelToKey: labelToKeyMap };
};

export const geometriesMatch = (
  obj1: ImageAnnotationGeometry,
  obj2: ImageAnnotationGeometry
): boolean => {
  const TOLERANCE = 1e-7;
  if (obj1 instanceof Box) {
    return (
      obj2 instanceof Box &&
      obj1.expand(TOLERANCE).contains(obj2) &&
      obj2.expand(TOLERANCE).contains(obj1)
    );
  } else if (obj1 instanceof Polygon) {
    return obj1.equalWithinTolerance(obj2, TOLERANCE);
  } else if (obj1 instanceof Skeleton) {
    return obj1.equalWithinTolerance(obj2, TOLERANCE);
  }
  throw new Error(`Unknown geometry type: ${typeof obj1}`);
};

export const scaleToRelativeBox = (
  boxIn: paper.Rectangle,
  imageBounds: paper.Rectangle
): paper.Rectangle => {
  const bounds = boxIn;
  const x1 = bounds.topLeft.x;
  const x2 = bounds.topRight.x;
  const y1 = bounds.topLeft.y;
  const y2 = bounds.bottomLeft.y;

  const width = imageBounds.width;
  const height = imageBounds.height;

  const imageTopLeftX = imageBounds.topLeft.x;
  const imageTopLeftY = imageBounds.topLeft.y;

  const scaledX1 = (x1 - imageTopLeftX) / width;
  const scaledX2 = (x2 - imageTopLeftX) / width;
  const scaledY1 = (y1 - imageTopLeftY) / height;
  const scaledY2 = (y2 - imageTopLeftY) / height;

  const scaledBounds = new paper.Rectangle(
    new paper.Point(scaledX1, scaledY1),
    new paper.Size(scaledX2 - scaledX1, scaledY2 - scaledY1)
  );
  return scaledBounds;
};

export const scaleRelativeBoxToImage = (
  relative: paper.Rectangle,
  imageBounds: paper.Rectangle
): paper.Rectangle => {
  const imageX = imageBounds.x;
  const imageY = imageBounds.y;
  const imageWidth = imageBounds.width;
  const imageHeight = imageBounds.height;

  const inImageBox = new paper.Rectangle(
    imageX + imageWidth * relative.x,
    imageY + imageHeight * relative.y,
    imageWidth * relative.width,
    imageHeight * relative.height
  );
  return inImageBox;
};

export const annotationsMatch = (
  obj1: ImageAnnotation,
  obj2: ImageAnnotation
): boolean => {
  // TODO More fine-grained match?
  // should context also be included in comparison?
  // what about traceid?

  const description1 = obj1.context ? obj1.context.description : null;
  const description2 = obj2.context ? obj2.context.description : null;

  return (
    obj1.label === obj2.label &&
    description1 === description2 &&
    geometriesMatch(obj1.geometry, obj2.geometry)
  );
};

/**
 * Deep-copy of labels by serialization and deserialization. Required
 * because ImageAnnotatorStore mutates annotations in-place, which can
 * lead to tricky bugs.
 * */
export const deepCopyLabels = (
  labels: ImageAnnotation[],
  uiParameters: ImageAnnotatorParameters
): ImageAnnotation[] => {
  return labels
    .map(label => imageAnnotationToJSON(label))
    .map(label => imageAnnotationFromJSON(label, uiParameters));
};

export const isPanning = (event: PaperMouseEvent): boolean => {
  return event.modifiers.space || event.event.buttons == 4;
};
