



















import {
  defineComponent,
  PropType,
  ref,
  toRefs,
  watchEffect
} from '@vue/composition-api';

export default defineComponent({
  name: 'InvalidLabelsSnackbar',
  props: {
    invalidLabels: {
      required: true,
      type: Array as PropType<any[]>
    }
  },
  setup(props) {
    const { invalidLabels } = toRefs(props);
    const show = ref(false);

    watchEffect(function() {
      show.value = invalidLabels.value?.length > 0;
    });

    return { show };
  }
});
