import paper from 'paper';
import util, { assert, bringWithinBounds, cv, GC_NEUTRAL } from './util';
import seedrandom from 'seedrandom';
import {
  BoundingBox,
  ObjectMask,
  OpenCVImage,
  Point2D,
  RefinementGrabcutOutput,
  RefinementInput,
  RefinementMode,
  RefinementOptions,
  RefinementOutput
} from './refine-types';
import {
  RefineBox,
  RefineBoxResult,
  RefineClicks,
  RefineClicksResult
} from '../types';
import { scaleRelativeBoxToImage, scaleToRelativeBox } from '../utils';

const REFINE_DEBUG_OUTPUT = true;

function debugLogToConsole(...args) {
  if (REFINE_DEBUG_OUTPUT) {
    console.log(...args);
  }
}

export const refineBoxIdentity: RefineBox = async (
  rect: paper.Rectangle
): Promise<RefineBoxResult> => {
  return { box: rect };
};

export const refineClicks: RefineClicks = async (
  positiveClicks: paper.Point[],
  negativeClicks: paper.Point[],
  img: HTMLImageElement
): Promise<RefineClicksResult> => {
  // place for speed improvement if needed: convert only the input bounding box area + some margin
  // remember to map the output back to original image coordinates

  const mat = imgToOpenCvMat(img);
  try {
    return refineClicks_(positiveClicks, negativeClicks, mat);
  } finally {
    mat.delete();
  }
};

async function refineClicks_(
  positiveClicks: paper.Point[],
  negativeClicks: paper.Point[],
  mat: OpenCVImage
): Promise<RefineClicksResult> {
  const bboxImg = new paper.Rectangle(0, 0, mat.cols - 1, mat.rows - 1);

  const positiveArray: Point2D[] = positiveClicks.map(p => {
    const w = bringWithinBounds(p, bboxImg);
    return { x: Math.round(w.x), y: Math.round(w.y) };
  });

  const negativeArray: Point2D[] = negativeClicks.map(p => {
    const w = bringWithinBounds(p, bboxImg);
    return { x: Math.round(w.x), y: Math.round(w.y) };
  });

  const refinementInput: RefinementInput = {
    clicks: positiveArray,
    negativeClicks: negativeArray
  };

  debugLogToConsole(`Pre-processed refinement input`, refinementInput);
  const refinementOutput = refineAutomatic(mat, refinementInput);
  debugLogToConsole(`Refinement output`, refinementOutput);

  const bbox = refinementOutput.bbox;

  let b64Mask = '';
  if (refinementOutput.objectMask) {
    const tempCanvas = document.createElement('canvas');
    const dst = util.singleChannelMaskToRGBA(refinementOutput.objectMask);
    cv.imshow(tempCanvas, dst);
    b64Mask = tempCanvas.toDataURL();
    refinementOutput.objectMask.delete();
    dst.delete();
  }

  return {
    box: bbox ? new paper.Rectangle(bbox[0], bbox[1], bbox[2], bbox[3]) : undefined,
    b64Mask
  };
}

const imgToOpenCvMat = (img: HTMLImageElement): OpenCVImage => {
  const canvas = document.createElement('canvas');
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  const srcMat = cv.matFromImageData(imgData);
  const mat = new cv.Mat();
  srcMat.convertTo(mat, cv.CV_8U);
  cv.cvtColor(mat, mat, cv.COLOR_RGBA2RGB);

  if (REFINE_DEBUG_OUTPUT) {
    console.log(`Rendering img to canvas ${canvas.width}x${canvas.height}`);
    // console.log(`OpenCV srcMat ${srcMat.cols} ${srcMat.rows}`);
    console.log(`OpenCV mat ${mat.cols} ${mat.rows}`);
  }

  srcMat.delete();
  canvas.remove();

  return mat;
};

export const refineBox: RefineBox = async (
  rect: paper.Rectangle,
  img: HTMLImageElement
): Promise<RefineBoxResult> => {
  const mat = imgToOpenCvMat(img);
  try {
    return refineBox_(rect, mat);
  } finally {
    mat.delete();
  }
};

async function refineBox_(
  rect: paper.Rectangle,
  mat: OpenCVImage
): Promise<RefineBoxResult> {
  const imageBounds = new paper.Rectangle(0, 0, mat.cols, mat.rows);
  const imageCoordinatesRect = scaleRelativeBoxToImage(rect, imageBounds);

  const bbox = [
    imageCoordinatesRect.topLeft.x,
    imageCoordinatesRect.topLeft.y,
    imageCoordinatesRect.width,
    imageCoordinatesRect.height
  ];

  const truncatedBBox = bbox.map(v => Math.trunc(v));

  const clippedBBox = util.cutRectToImage(truncatedBBox, mat);

  const refinementInput: RefinementInput = {
    bbox: clippedBBox,
    enclosingBbox: true,
    negativeClicks: []
  };

  if (REFINE_DEBUG_OUTPUT) {
    console.log(`Pre-processed refinement input`, refinementInput);
  }

  const refinementOutput = refineAutomatic(mat, refinementInput);

  if (REFINE_DEBUG_OUTPUT) {
    console.log(`Refinement output`, refinementOutput);
  }

  const bboxOut = refinementOutput.bbox;

  if (refinementOutput.objectMask) {
    // TODO Handle object mask
    refinementOutput.objectMask.delete();
  }
  if(!bboxOut){
    return { box: undefined};
  }

  const outputRectInImageCoordinates = new paper.Rectangle(
    bboxOut[0],
    bboxOut[1],
    bboxOut[2],
    bboxOut[3]
  );

  const outputRect = scaleToRelativeBox(
    outputRectInImageCoordinates,
    imageBounds
  );

  
  return { box: outputRect };
}

/**
 * Calculates the intersection over union metric (IoU) of the overlap of
 * two rectangles
 *
 * @param {array} bbox1 in the format [x, y, w, h]
 * @param {array} bbox2 in the format [x, y, w, h]
 */
export function intersectionOverUnion(
  bbox1: BoundingBox,
  bbox2: BoundingBox
): number {
  // determine the (x, y)-coordinates of the intersection rectangle corners
  const xA = Math.max(bbox1[0], bbox2[0]);
  const yA = Math.max(bbox1[1], bbox2[1]);
  const xB = Math.min(bbox1[0] + bbox1[2] - 1, bbox2[0] + bbox2[2] - 1);
  const yB = Math.min(bbox1[1] + bbox1[3] - 1, bbox2[1] + bbox2[3] - 1);

  // compute the area of intersection rectangle
  const interArea = Math.max(0, xB - xA + 1) * Math.max(0, yB - yA + 1);

  // compute the area of both the prediction and ground-truth
  // rectangles
  const boxAArea = bbox1[2] * bbox1[3];
  const boxBArea = bbox2[2] * bbox2[3];

  // # compute the intersection over union by taking the intersection
  // # area and dividing it by the sum of prediction + ground-truth
  // # areas - the interesection area
  const iou = interArea / (boxAArea + boxBArea - interArea);
  return iou;
}

/**
 * Determines the bounding box of an array of points
 * when unitary co-ordinate system with pixel width one
 * is used
 *
 * @param {*} clickArray : array of opencv points =  {"x": val, "y":val}
 */
export function bboxOfClicksUnitary(clickArray: Point2D[]): BoundingBox {
  if (clickArray.length === 0) {
    return undefined;
  }

  const xArray = clickArray.map(click => click.x);
  const yArray = clickArray.map(click => click.y);

  const minX = util.arrayMin(xArray);
  const minY = util.arrayMin(yArray);
  const maxX = util.arrayMax(xArray);
  const maxY = util.arrayMax(yArray);

  const w = maxX - minX + 1;
  const h = maxY - minY + 1;

  return [minX, minY, w, h];
}

/**
 * Determines the non-inclusive bounding box of an array of points
 * (applicable when pixels are thought to have vanishing width)
 *
 * @param {*} clickArray : array of opencv points =  {"x": val, "y":val}
 */

export function bboxOfClicksNonInclusive(clickArray: Point2D[]) {
  if (clickArray.length === 0) {
    return undefined;
  }

  const xArray = clickArray.map(click => click.x);
  const yArray = clickArray.map(click => click.y);

  const minX = util.arrayMin(xArray);
  const minY = util.arrayMin(yArray);
  const maxX = util.arrayMax(xArray);
  const maxY = util.arrayMax(yArray);

  const w = maxX - minX;
  const h = maxY - minY;

  return [minX, minY, w, h];
}

/**
 * The refinement module high level entry point for automatically refining an
 * approximate location annotation within the input image.
 * The actual calculation task is relegated to an implementation
 * of a computational algorithm that has been chosen as the default one
 * by the module developers.
 *
 * @param {OpenCV Mat object} img must be of type CV_8UC3 = three channel image w/
 *     8-bit depth
 * @param {object} locationIn: an object specifying the approximate input annotation.
 *     Must contain one of the fields
 *         - 'bbox': input bounding box in the [x, y, width, height] format
 *         - 'clicks': array of clicks on the object, each click is in format {x:x, y:y}
 *     The following fields are optional:
 *         - 'negativeClicks': list of clicks outside the object,
 *               each click is each click is in format {x:x, y:y}
 *         - 'enclosingBbox': if true, the bounding box is interpreted to fully
 *               enclose the object and will be only shrunk in the refinement process
 *
 *     Additional fields may be specified to control parameters of the calculation.
 *     Any additional fields are passed through to the actual computational
 *     implementation.
 * @param {HTML canvas dom element} debugCanvas
 *     can be left undefined
 *
 * @returns {object} locationOut: an object with the following fields
 *    - 'bbox': refined bounding box in the [x, y, width, height] format
 *    - 'objectMask': estimated segmentation mask in the bounding box area as
 *         a one-channel image with size of the bounding box
 *
 * An examples how to call:
 *
 * locationIn = {
 *          clicks: this.clickarray,
 *          negativeClicks: this.negativearray,
 *   };
 * let locationOut = refine.refineAutomatic(this.srcdata,
 *      locationIn,
 *      this.$refs.viscanvas
 *   );
 *
 */
export function refineAutomatic(
  img: OpenCVImage,
  locationIn: RefinementInput,
  debugCanvas = undefined
): RefinementOutput {
  assert(
    ('bbox' in locationIn && locationIn['bbox'].length === 4) ||
      'clicks' in locationIn
  );

  assert(img.type() === cv.CV_8UC3);

  const MIN_CROP_SIZE = 100;
  const MAX_CROP_SIZE = 200;

  let grabcutResult;

  //     # choose different processing logic based on the type of the input
  if ('clicks' in locationIn) {
    const fgLabeling = [GC_NEUTRAL, GC_NEUTRAL];
    const bgLabeling = [cv.GC_PR_BGD, cv.GC_BGD, GC_NEUTRAL];

    grabcutResult = _refineGrabcut(img, locationIn, {
      labelRects: bgLabeling.concat(fgLabeling),
      clampPositiveClicks: true,
      clampNegativeClicks: true,
      scaleSmallBoxesTo: MIN_CROP_SIZE,
      scaleLargeBoxesTo: MAX_CROP_SIZE,
      debugCanvas
    });
  } else {
    const fgLabeling = [cv.GC_PR_FGD, cv.GC_FGD];
    const bgLabeling = locationIn.enclosingBbox
      ? [cv.GC_PR_BGD, cv.GC_BGD, cv.GC_BGD]
      : [cv.GC_PR_BGD, cv.GC_BGD, cv.GC_PR_BGD];

    grabcutResult = _refineGrabcut(img, locationIn, {
      labelRects: bgLabeling.concat(fgLabeling),
      scaleSmallBoxesTo: MIN_CROP_SIZE,
      scaleLargeBoxesTo: MAX_CROP_SIZE,
      clampNegativeClicks: true,
      debugCanvas
    });
  }

  const locationOut = {
    bbox: grabcutResult.bbox,
    objectMask: grabcutResult.mask
  };
  return locationOut;
}

/**
 * Function that generates random images with one rectangle
 * on homogeneous background and tests if refineAutomatic
 * finds their location when the corner points of the boxes
 * are given as input
 *
 * Tests three operation modes: any, shrink and clicks
 *
 * The intention is to eventually move this functionality
 * to a separate testing module.
 *
 * @param {Number} nTries number of test images generated for
 *    testing each operation mode
 * @returns{Boolean} true if all tests pass without errors
 */
export function refineAutomaticSanityChecks(nTries = 100): boolean {
  /**
   * Helper function
   * Generates random image
   *
   * @returns {Array} [img, object rectangle]
   *     - object rectangle in XYWH format and clipped to the generated image
   */
  function generateRandomImage(): [OpenCVImage, BoundingBox] {
    const imgMinDim = 100;
    const imgMaxDim = 2000;

    const imgW = util.randomInteger(imgMinDim, imgMaxDim);
    const imgH = util.randomInteger(imgMinDim, imgMaxDim);

    // select random object rectangle within area larger than the image
    // so that there's a decent chance of the object touching the boundaries

    const objectMinDim = 20;
    const fractionExcess = 0.2;

    const x1 = util.randomInteger(-fractionExcess * imgW, imgW - objectMinDim);
    const y1 = util.randomInteger(-fractionExcess * imgH, imgH - objectMinDim);
    const x2 = util.randomInteger(
      Math.max(0, x1) + objectMinDim,
      (1 + fractionExcess) * imgW
    );
    const y2 = util.randomInteger(
      Math.max(0, y1) + objectMinDim,
      (1 + fractionExcess) * imgH
    );

    const colourBackground = [
      util.randomInteger(0, 255),
      util.randomInteger(0, 255),
      util.randomInteger(0, 255),
      0
    ];
    const mindiffColour = 20;

    let colourForeground;
    do {
      colourForeground = [
        util.randomInteger(0, 255),
        util.randomInteger(0, 255),
        util.randomInteger(0, 255),
        0
      ];
    } while (
      util.arrayDistEuclidean(colourBackground, colourForeground) <
      mindiffColour
    );

    const img = new cv.Mat(imgH, imgW, cv.CV_8UC3, colourBackground);
    const rect = util.cutRectToImage([x1, y1, x2 - x1 + 1, y2 - y1 + 1], img);

    util.drawRectangle(img, rect, { thickness: -1, colour: colourForeground });

    return [img, rect];
  }

  function isWithinTolerance(
    cornersIn: BoundingBox,
    cornersOut: BoundingBox,
    img: OpenCVImage
  ): boolean {
    const toleranceFraction = 0.05;
    const toleranceMin = 3;

    const allowedToleranceX = Math.max(
      toleranceMin,
      toleranceFraction * img.cols
    );
    const allowedToleranceY = Math.max(
      toleranceMin,
      toleranceFraction * img.rows
    );

    return (
      Math.max(
        Math.abs(cornersIn[0] - cornersOut[0]),
        Math.abs(cornersIn[2] - cornersOut[2])
      ) <= allowedToleranceX &&
      Math.max(
        Math.abs(cornersIn[1] - cornersOut[1]),
        Math.abs(cornersIn[3] - cornersOut[3])
      ) <= allowedToleranceY
    );
  }

  /**
   * A helper function performing repeated sanity checks
   * int the specified refinement mode
   *
   * @param {Number} nTries Number of repetitions
   * @param {String} mode refinement mode. Must be one of ['any','shrink','clicks']
   * @param {Boolean} breakOnError is set to true, the function returns as soon as the first
   *     trial fails (making the return value binary)
   * @returns{Number} the number of errors within the trials
   */
  function performSanityChecks(
    nTries: number,
    mode: RefinementMode,
    breakOnError = true
  ): number {
    assert(['any', 'shrink', 'clicks'].includes(mode));

    let errorCount = 0;
    let trialCount = 0;

    for (let i = 0; i < nTries; i++) {
      trialCount++;

      const [img, bbox] = generateRandomImage();
      const cornersIn = util.boxXYWHToX1Y1X2Y2Unitary(bbox);

      let locationIn = {};
      switch (mode) {
        case 'any':
          locationIn = { bbox };
          break;
        case 'shrink':
          locationIn = { bbox, enclosingBbox: true };
          break;
        case 'clicks':
          locationIn = {
            clicks: [
              { x: cornersIn[0], y: cornersIn[1] },
              { x: cornersIn[0], y: cornersIn[3] },
              { x: cornersIn[2], y: cornersIn[1] },
              { x: cornersIn[2], y: cornersIn[3] }
            ]
          };
          break;
      }

      const locationOut = refineAutomatic(img, locationIn);
      const cornersOut = locationOut.bbox
        ? util.boxXYWHToX1Y1X2Y2Unitary(locationOut.bbox)
        : [0, 0, 0, 0];

      if (!isWithinTolerance(cornersIn, cornersOut, img)) {
        debugLogToConsole(`Error in self-test case #${i}: `);
        debugLogToConsole('rect ', bbox);
        debugLogToConsole('in image of size', img.size());
        errorCount++;
      } else {
        debugLogToConsole(`test case #${i} passed`);
      }

      if (locationOut.objectMask) {
        locationOut.objectMask.delete();
      }

      img.delete();
      debugLogToConsole(
        `mode ${mode}: ${errorCount} errors in ${trialCount} trials`
      );
      if (errorCount > 0 && breakOnError) {
        break;
      }
    }

    return errorCount;
  }

  const errorFlag =
    performSanityChecks(nTries, 'any') > 0 ||
    performSanityChecks(nTries, 'shrink') > 0 ||
    performSanityChecks(nTries, 'clicks') > 0;
  return !errorFlag;
}

/**
 * Function implementing Grabcut-based location refinement
 *
 * @param {object} img OpenCV Mat similarly as in the entry point function refineAutomatic()
 * @param {object} locationIn similarly as in the entry point function refineAutomatic()
 * @param {object} opts that specifies named arguments. The following fields are recognised:
 *    - initMode: a string specifying the way the GrabCut seed mask is generated.
 *        Currently following modes are recognised:
 *        - "centerBox": a geometric division of area around the bounding box into
 *            five telescoping rectangles (default)
 *        - "maskinitSimple": seed mask generation on basis of estimated object mask
 *             (not yet implemented)
 *     - returnVisualisations: (boolean) flag whether visualisations of intermediate
 *         algorithm results should be generated and included in the function return
 *         values
 *     - delta: the starting co - ordinates of the innermost rectangle(initmode "centerbox")
 *     - clampPositiveClicks: boolean whether click points should be clamped as certain
 *          foreground
 *     - clampNegativeClicks: boolean whether negative click points should be clamped as certain
 *          background
 *     - labelRect: labels for the seed mask rectangles(initmode "centerBox")
 *     - maskIn: required argument for the initmode "maskinitSimple" specifying the input mask
 *     - scaleSmallBoxesTo: if specified, scale up small analysis regions  for processing
 *         to have smaller dimension this size. Analysis region is the bounding box of
 *         the input inflated with a certain margin (that the function selects
 *         internally). The analysis result is scaled back to original scale.
 *     - scaleLargeBoxesTo:  if specified, scale down large analysis regions down
 *         for processing to have smaller dimension this size.  The analysis result is
 *         scaled back to original scale.
 *     - debugCanvas: canvas element for debug output images
 *
 *  @returns {object} with the following keys:
 *     - bbox: refined bounding box in the [x, y, width, height] format
 *     - mask: estimated segmentation mask in the bounding box area as a one-channel
 *         image with size of the bounding box
 *     optionally also
 *     - visualisations: an array of OpenCV Mat objects for visualisation
 */
function _refineGrabcut(
  img: OpenCVImage,
  locationIn: RefinementInput,
  opts: RefinementOptions
): RefinementGrabcutOutput {
  // set defaults for unpecified opts

  util.setDefaultValues(opts, {
    initMode: 'centerBox',
    returnVisualisations: false
  });

  const visualisations = [];

  let bboxFromClicks = false;

  if ('clicks' in locationIn) {
    if (!('bbox' in locationIn)) {
      bboxFromClicks = true;
      locationIn['bbox'] = bboxOfClicksUnitary(locationIn['clicks']);
    }
  }

  assert('bbox' in locationIn);
  assert(locationIn['bbox'].length === 4);

  const [w, h] = locationIn['bbox'].slice(2, 4);

  const maximumBoxExpansion: [number, number] = bboxFromClicks
    ? [0.5 * w + 10, 0.5 * h + 10]
    : [0.5 * w + 5, 0.5 * h + 5];

  const [
    imgCrop,
    clipBoxOrig,
    bboxInCropScaled,
    bboxInCropOrigScale
  ] = _grabcutStageCropAndScale(img, locationIn, {
    ...opts,
    maximumBoxExpansion
  });

  debugLogToConsole('clipBoxOrig', clipBoxOrig);

  if (opts.returnVisualisations) {
    visualisations.push(imgCrop.clone());
  }

  let seedMask = _grabcutStageGenerateSeedMask(
    imgCrop,
    clipBoxOrig,
    bboxInCropScaled,
    locationIn,
    opts
  );

  if (opts.debugCanvas) {
    const visMat = util.visualiseSingleChannelMask(seedMask);
    cv.imshow(opts.debugCanvas, visMat);
    visMat.delete();
  }

  let maskGrabcut = _grabcutStagePerformGrabcut(
    imgCrop,
    bboxInCropScaled,
    seedMask
  );

  debugLogToConsole('grabcut done');

  let postProcessedMask;
  [
    postProcessedMask,
    seedMask,
    maskGrabcut
  ] = _grabCutStageRestoreScaleAndPostprocess(
    maskGrabcut,
    seedMask,
    imgCrop,
    clipBoxOrig
  );

  debugLogToConsole(
    `postprocessing done, postProcessedMask size: ${postProcessedMask.cols}x${postProcessedMask.rows}`
  );

  const [bboxRet, maskRet] = _grabcutStageSelectOutput(
    postProcessedMask,
    seedMask,
    clipBoxOrig,
    bboxInCropOrigScale,
    img
  );

  imgCrop.delete();
  seedMask.delete();
  maskGrabcut.delete();
  postProcessedMask.delete();

  const maybeVisualisations = opts.returnVisualisations
    ? { visualisations }
    : {};

  const ret = { bbox: bboxRet, mask: maskRet, ...maybeVisualisations };

  debugLogToConsole('grabcut finished');

  return ret;
}

// Stages of the grabcut algorithm

/**
 * Stage of the GrabCut based refinement algorithm that crops
 * from input image the part around the input bounding box
 * and scales it according to specs if necessary
 *
 * @param {*} imgOrig
 * @param {*} locationIn
 * @param {*} opts
 *
 * @returns {array} [imgCrop, sizeOrig, bboxInCropScaled, bboxInCropOrigScale ] where
 *     - imgCrop is the cropped part of the image as OpenCV Mat()
 *         If no scaling is needed, this is just a view to the original image.
 *         Otherwise, the caller is responsible for deleting the matrix.
 *     - clipBoxOrig the area in the original (unpadded) image that corresponds
 *         to imgCrop
 *     - bboxInCropScaled: the input bounding box in the coordinate system
 *         of the cropped and scaled image ( format [x, y, w, h])
 *      - bboxInCropOrigScale: the input bounding box in the coordinate system
 *         of the cropped original unscaled image (=output mask scale) ( format [x, y, w, h])
 * */

function _grabcutStageCropAndScale(
  imgOrig: OpenCVImage,
  locationIn: RefinementInput,
  opts: RefinementOptions
): [OpenCVImage, BoundingBox, BoundingBox, BoundingBox] {
  function padColourFrom(
    imgCrop: OpenCVImage,
    imgOrig: OpenCVImage,
    bboxOrig: BoundingBox
  ) {
    // determine the colour of padding as the mean colour
    // of the cropped image outside the bounding box

    const bboxCut = util.cutRectToImage(bboxOrig, imgOrig);
    const areaCrop = imgCrop.cols * imgCrop.rows;
    const areaBbox = bboxCut[2] * bboxCut[3];

    const meanCrop = cv.mean(imgCrop);

    const bbRoi = imgOrig.roi(new cv.Rect(...bboxCut));
    const meanBbox = cv.mean(bbRoi);
    bbRoi.delete();

    return meanCrop.map((value, index) => {
      const sumCrop = value * areaCrop;
      const sumBbox = meanBbox[index] * areaBbox;
      return (sumCrop - sumBbox) / (areaCrop - areaBbox);
    });
  }

  assert(
    opts.maximumBoxExpansion !== undefined,
    'maximumBoxExpansion must be specified'
  );
  assert(opts.maximumBoxExpansion.length === 2);

  const [x, y, w, h] = locationIn['bbox'];

  let clipBox = [
    x - opts.maximumBoxExpansion[0],
    y - opts.maximumBoxExpansion[1],
    w + 2 * opts.maximumBoxExpansion[0],
    h + 2 * opts.maximumBoxExpansion[1]
  ];

  clipBox = clipBox.map(v => Math.trunc(v));
  const clipBoxP1P2 = util.boxXYWHToX1Y1X2Y2Unitary(clipBox);

  const padLeft = Math.max(0, -clipBoxP1P2[0]);
  const padTop = Math.max(0, -clipBoxP1P2[1]);
  const padRight = Math.max(0, clipBoxP1P2[2] - imgOrig.cols + 1);
  const padBottom = Math.max(0, clipBoxP1P2[3] - imgOrig.rows + 1);

  const clipBoxCut = util.cutRectToImage(clipBox, imgOrig);

  const [xMin, yMin] = clipBox;

  const rect = new cv.Rect(...clipBoxCut);
  let imgCrop = imgOrig.roi(rect);

  if (padLeft > 0 || padTop > 0 || padRight > 0 || padBottom > 0) {
    const bboxCut = util.cutRectToImage(locationIn['bbox'], imgOrig);
    const areaCrop = imgCrop.cols * imgCrop.rows;
    const areaBbox = bboxCut[2] * bboxCut[3];

    const padColour =
      areaCrop > areaBbox
        ? padColourFrom(imgCrop, imgOrig, locationIn['bbox'])
        : new cv.Scalar(0, 0, 0, 255);

    cv.copyMakeBorder(
      imgCrop,
      imgCrop,
      padTop,
      padBottom,
      padLeft,
      padRight,
      cv.BORDER_CONSTANT,
      padColour
    );
  }

  let bboxTranslated = [...locationIn['bbox']];
  bboxTranslated[0] -= xMin;
  bboxTranslated[1] -= yMin;

  const bboxTranslatedOrigScale = [...bboxTranslated];

  const minSize = opts.scaleSmallBoxesTo;
  const maxSize = opts.scaleLargeBoxesTo;

  if (minSize !== undefined && Math.min(imgCrop.rows, imgCrop.cols) < minSize) {
    const localScaleFactor = minSize / Math.min(imgCrop.rows, imgCrop.cols);
    bboxTranslated = bboxTranslated.map(v => Math.trunc(localScaleFactor * v));

    const dsize = new cv.Size(
      imgCrop.cols * localScaleFactor,
      imgCrop.rows * localScaleFactor
    );

    const scaledMat = new cv.Mat();
    cv.resize(imgCrop, scaledMat, dsize, 0, 0, cv.INTER_LANCZOS4);
    imgCrop.delete();
    imgCrop = scaledMat;
  }

  if (maxSize !== undefined && Math.min(imgCrop.rows, imgCrop.cols) > maxSize) {
    const localScaleFactor = maxSize / Math.min(imgCrop.rows, imgCrop.cols);
    bboxTranslated = bboxTranslated.map(v => Math.trunc(localScaleFactor * v));

    const dsize = new cv.Size(
      imgCrop.cols * localScaleFactor,
      imgCrop.rows * localScaleFactor
    );

    const scaledMat = new cv.Mat();
    cv.resize(imgCrop, scaledMat, dsize, 0, 0, cv.INTER_LANCZOS4);
    imgCrop.delete();
    imgCrop = scaledMat;
  }

  // to avoid numerical problems, add mild noise over the image and
  // smooth a little

  util.addUniformNoiseToMat(imgCrop, 2);
  const ksize = new cv.Size(3, 3);
  cv.GaussianBlur(imgCrop, imgCrop, ksize, 0);

  return [imgCrop, clipBox, bboxTranslated, bboxTranslatedOrigScale];
}

/**
 * Stage of the GrabCut based refinement algorithm that generates the seed mask for
 * the OpenCV grabcut implementation
 *
 * @param {OpenCV Mat} imgCrop
 * @param {array(4)} clipBoxOrig the area in the original image that corresponds
 *         to imgCrop
 * @param {*} bboxInCrop
 * @param {*} locationIn
 * @param {object} opts pass through from the upper level driver routine.
 *     Keys of interest here:
 *     - initMode (string)
 *     - delta (array(2))
 *     - labelRects (array(5))
 *
 * @returns {OpenCV Mat} the generated seed mask
 */

function _grabcutStageGenerateSeedMask(
  imgCrop: OpenCVImage,
  clipBoxOrig: BoundingBox,
  bboxInCrop: BoundingBox,
  locationIn: RefinementInput,
  opts: RefinementOptions
) {
  let seedMask;

  if (opts.initMode === 'centerBox') {
    seedMask = _createGrabcutInitMask(imgCrop, bboxInCrop, {
      delta: opts.delta,
      labelRects: opts.labelRects,
      neutralZoneWidth: 3
    });
  } else if (opts.initMode === 'maskInitSimple') {
    throw 'initmode maskInitSimple not yet implemented';
  } else {
    throw 'unknown initmode ';
  }

  const [xMin, yMin] = clipBoxOrig.slice(0, 2);
  const totalScaleFactor = imgCrop.cols / clipBoxOrig[2];

  const translatedClicks =
    'clicks' in locationIn
      ? locationIn['clicks'].map(c => ({
          x: totalScaleFactor * (c.x - xMin),
          y: totalScaleFactor * (c.y - yMin)
        }))
      : [];

  const translatedNegativeClicks =
    'negativeClicks' in locationIn
      ? locationIn['negativeClicks'].map(c => ({
          x: totalScaleFactor * (c.x - xMin),
          y: totalScaleFactor * (c.y - yMin)
        }))
      : [];

  if (opts.clampNegativeClicks) {
    _clampNegativeClicksIntoSeedMask(
      seedMask,
      translatedNegativeClicks,
      bboxInCrop
    );
  }
  if (opts.clampPositiveClicks) {
    _clampClicksIntoSeedMask(seedMask, translatedClicks);
  }
  return seedMask;
}
/**
 * Stage of the GrabCut based refinement algorithm that
 * repeats calling OpenCV until it produces some result
 *
 * @param {*} imgCrop
 * @param {*} seedMask
 * @param {*} opts
 */
function _grabcutStagePerformGrabcut(
  imgCrop: OpenCVImage,
  bboxInCrop: BoundingBox,
  seedMask: ObjectMask
): OpenCVImage {
  const maskGrabcut = seedMask.clone();

  const bgdModel = new cv.Mat();
  const fgdModel = new cv.Mat();

  const nIter = 3;
  const rectGrabcut = new cv.Rect(0, 0, 0, 0);

  let tryCount = 0;
  const MAX_TRIES = 10;

  do {
    tryCount += 1;
    if (tryCount > 1) {
      debugLogToConsole(`GrabCut take #${tryCount}`);
    }

    const grabcutStartTime = new Date().getTime();

    cv.grabCut(
      imgCrop,
      maskGrabcut,
      rectGrabcut,
      bgdModel,
      fgdModel,
      nIter,
      cv.GC_INIT_WITH_MASK
    );
    const grabcutEndTime = new Date().getTime();

    debugLogToConsole(
      'Real refine time taken by actual grabcut',
      grabcutEndTime - grabcutStartTime
    );
    debugLogToConsole(`for imgCrop of size ${imgCrop.cols}x${imgCrop.rows}`);
  } while (
    tryCount < MAX_TRIES &&
    util.countGCForegroundWithinRect(maskGrabcut, bboxInCrop) < 20
  );

  return maskGrabcut;
}
/**
 * Stage of the GrabCut based refinement algorithm that
 * takes the raw grabcut output, rescales it
 * and processes it
 *
 * @param {OpenCV Mat} maskGrabcut raw grabcut output mask
 * @param {*} imgCrop
 * @param {*} clipBoxOrig
 *
 * @returns {array(3)} [postProcessedMask, seedMask, maskGrabcut];
 *     - postProcessedMask {OpenCV Mat} binary mask in the original image scale
 *         with potentially several disconnected components
 *         (newly allocated matrix, caller is responsible for deleting)
 *     - seedMask {OpenCV Mat} potentially re-scaled and re-allocated seed mask
 *       (caller deletes)
 *     - maskGrabcut {OpenCV Mat} potentially re-scaled and re-allocated
 *       raw grabcut output (caller deletes)
 *
 */
function _grabCutStageRestoreScaleAndPostprocess(
  maskGrabcut: OpenCVImage,
  seedMask: OpenCVImage,
  imgCrop: OpenCVImage,
  clipBoxOrig: BoundingBox
) {
  const totalScaleFactor = imgCrop.cols / clipBoxOrig[2];

  if (totalScaleFactor != 1.0) {
    const sizeOrig = new cv.Size(clipBoxOrig[2], clipBoxOrig[3]);
    let resized = new cv.Mat();
    cv.resize(maskGrabcut, resized, sizeOrig, 0, 0, cv.INTER_NEAREST);
    maskGrabcut.delete();
    maskGrabcut = resized;

    resized = new cv.Mat();
    cv.resize(seedMask, resized, sizeOrig, 0, 0, cv.INTER_NEAREST);
    seedMask.delete();
    seedMask = resized;
  }

  const mask2 = new cv.Mat();
  const oneMat = cv.matFromArray(1, 1, cv.CV_8UC1, [1]);
  cv.bitwise_and(maskGrabcut, oneMat, mask2);

  const DETAIL_RADIUS = 3;

  const kernel = cv.getStructuringElement(cv.MORPH_ELLIPSE, {
    width: DETAIL_RADIUS,
    height: DETAIL_RADIUS
  });

  const mask3 = new cv.Mat();
  cv.morphologyEx(mask2, mask3, cv.MORPH_OPEN, kernel);

  mask2.delete();
  kernel.delete();

  return [mask3, seedMask, maskGrabcut];
}

/**
 * Stage of the GrabCut based refinement algorithm that
 * determines the components of the post-processed output mask,
 * and forms final location estimates (bbox and mask)
 *
 * @param {OpenCV Mat} postProcessedMask
 * @param {OpenCV Mat} seedMask
 * @param {Array} clipBoxOrig format [x, y, w, h]
 * @param {Array} bboxInCropOrigScale format [x, y, w, h] in the coordinates of postprocessed mask=original cropped image
 * @param {OpenCV Mat} imgOrig origin<al image (only size is used here to clip output)
 *
 * @returns {array(2)} [bboxRet, maskRet]
 *     - bboxRet is of format [x, y, w, h] in original image coordinates
 *     - maskRet is binary object mask OpenCV Mat in the scale of original image
 *         but limited to area of bboxRet
 */
function _grabcutStageSelectOutput(
  postProcessedMask: OpenCVImage,
  seedMask: OpenCVImage,
  clipBoxOrig: BoundingBox,
  bboxInCropOrigScale: BoundingBox,
  imgOrig: OpenCVImage
) {
  //const totalScaleFactor = postProcessedMask.cols / clipBoxOrig[2];
  assert(
    postProcessedMask.cols === clipBoxOrig[2] &&
      postProcessedMask.rows === clipBoxOrig[3]
  );

  const [xMin, yMin, xMax, yMax] = util.boxXYWHToX1Y1X2Y2Unitary(clipBoxOrig);

  const lbl = new cv.Mat();
  const componentCount = cv.connectedComponents(
    postProcessedMask,
    lbl,
    8,
    cv.CV_16U
  );

  debugLogToConsole('componentCount', componentCount);

  // in the un-scaled coordinates of the postProcessedMask
  const bb = bboxInCropOrigScale;
  const componentArea = new Array(componentCount).fill(0);

  for (let row = bb[1]; row < bb[1] + bb[3]; row++) {
    for (let col = bb[0]; col < bb[0] + bb[2]; col++) {
      // componentArea[(0, lbl.ushortAt(row, col))] += 1;
      componentArea[lbl.ushortAt(row, col)] += 1;
    }
  }

  debugLogToConsole('areas counted');
  debugLogToConsole(componentArea);

  // consider only foreground labels
  componentArea[0] = 0;
  const largestLabel = util.argMax(componentArea);

  const allowedLabels = new Set();

  for (let x = bb[0]; x < bb[0] + bb[2]; x++) {
    for (let y = bb[1]; y < bb[1] + bb[3]; y++) {
      if (seedMask.ucharAt(y, x) === cv.GC_FGD) {
        if (lbl.ushortAt(y, x) > 0) allowedLabels.add(lbl.ushortAt(y, x));
      }
    }
  }

  if (largestLabel !== 0) {
    allowedLabels.add(largestLabel);
  }

  debugLogToConsole('allowedLabels', allowedLabels);

  let bbMinX = xMax + 1;
  let bbMinY = yMax + 1;
  let bbMaxX = -1;
  let bbMaxY = -1;
  let ptCount = 0;

  for (let x = xMin; x < xMax; x++) {
    for (let y = yMin; y < yMax; y++) {
      if (allowedLabels.has(lbl.ushortAt(y - yMin, x - xMin))) {
        ptCount++;
        bbMinX = Math.min(x, bbMinX);
        bbMinY = Math.min(y, bbMinY);
        bbMaxX = Math.max(x, bbMaxX);
        bbMaxY = Math.max(y, bbMaxY);
      }
    }
  }

  const bboxRet =
    ptCount > 0
      ? util.cutRectToImage(
          [bbMinX, bbMinY, bbMaxX - bbMinX + 1, bbMaxY - bbMinY + 1],
          imgOrig
        )
      : undefined;

  let maskRet = undefined;
  if (ptCount > 0) {
    maskRet = cv.Mat.zeros(bboxRet[3], bboxRet[2], cv.CV_8UC1);
    for (let dx = 0; dx < bboxRet[2]; dx++) {
      for (let dy = 0; dy < bboxRet[3]; dy++) {
        if (
          allowedLabels.has(
            lbl.ushortAt(dy + bboxRet[1] - yMin, dx + bboxRet[0] - xMin)
          )
        ) {
          maskRet.ucharPtr(dy, dx)[0] = 1;
        }
      }
    }
  }

  lbl.delete();

  return [bboxRet, maskRet];
}

// Helper functions ------------------------

/**
 * Inflate a rectangle with the specified margin
 *
 * @param {array} bboxIn as a four element array [x, y, w, h]
 * @param {number} d
 * @returns four element array corresponding to the inflated box
 */
function _inflateBox(bboxIn: BoundingBox, d: number): BoundingBox {
  const ret = [...bboxIn];
  ret[0] -= d;
  ret[1] -= d;
  ret[2] += 2 * d;
  ret[3] += 2 * d;

  return ret;
}

/**
 * Maps special values GC_NEUTRAL into an  even mix of cv.GC_PR_FGD and
 * cv.GC_PR_BGD in an OpenCV GrabCut seed matrix
 *
 * @param {object} mask a single-channel 8-bit OpenCV Mat
 * @returns {*} nothing, alters the input matrix
 */
function _grabcutMaskMapNeutral(mask: OpenCVImage): void {
  const rng = seedrandom(`${mask.rows}${mask.cols}`);

  for (let y = 0; y < mask.rows; y++) {
    const rowPtr = mask.ucharPtr(y);
    rowPtr.forEach((value, index) => {
      if (value === GC_NEUTRAL) {
        rowPtr[index] = util.randomChoice([cv.GC_PR_BGD, cv.GC_PR_FGD], rng);
      }
    });
  }
}

/**
 * Generates GrabCut seed mask by a geometric division of area around the bounding box
 * into five telescoping rectangles
 *
 * @param {OpenCV Mat object} img: input image cropped to area near the bounding box
 * @param {*} bbox: bounding box as [x, y, w, h] relative to the cropped (input) image
 * @param {*} opts: an object specifying named arguments. The following keys are
 * recognised:
 *     - delta: if specified, bypasses the default starting coordinates of the
 *         innermost rectangle
 *     - labelRects: lists the five labels of the telescoping seed mask rectangles
 *         from outer to inner
 *     - neutralZoneWidth: if specified, defines a zone with simulated neutral
 *         label GC_NEUTRAL to be generated on both sides of the bounding box border
 *
 * @returns {OpenCV Mat object} a a one channel 8-bit seed mask for GrabCut
 */
function _createGrabcutInitMask(
  img: OpenCVImage,
  bbox: BoundingBox,
  opts: RefinementOptions
): OpenCVImage {
  // OBS: could make the neutralZoneWidth argument two-sided for
  // different inner and outer neutral zone widths

  const labelRects = opts.labelRects || [
    cv.GC_PR_BGD,
    cv.GC_BGD,
    cv.GC_PR_BGD,
    cv.GC_PR_FGD,
    cv.GC_FGD
  ];

  //     margin in order[left, top, right, bottom]
  const marginOutsideBbox = [
    bbox[0],
    bbox[1],
    img.cols - bbox[2] - bbox[0],
    img.rows - bbox[3] - bbox[1]
  ];

  debugLogToConsole('marginOutsideBbox=', marginOutsideBbox);

  let rectBkgndInner = bbox.map(v => Math.trunc(v));

  rectBkgndInner[0] -= 0.33 * marginOutsideBbox[0];
  rectBkgndInner[1] -= 0.33 * marginOutsideBbox[1];
  rectBkgndInner[2] += 0.33 * (marginOutsideBbox[0] + marginOutsideBbox[2]);
  rectBkgndInner[3] += 0.33 * (marginOutsideBbox[1] + marginOutsideBbox[3]);

  rectBkgndInner = util.cutRectToImage(rectBkgndInner, img);

  const rectBkgndOuter = [...rectBkgndInner];
  rectBkgndOuter[0] -= 0.33 * marginOutsideBbox[0];
  rectBkgndOuter[1] -= 0.33 * marginOutsideBbox[1];
  rectBkgndOuter[2] += 0.33 * (marginOutsideBbox[0] + marginOutsideBbox[2]);
  rectBkgndOuter[3] += 0.33 * (marginOutsideBbox[1] + marginOutsideBbox[3]);

  const CORE_FRACTION = 0.35;

  const rectBboxInner = bbox.map(v => Math.trunc(v));
  rectBboxInner[0] += (1 - CORE_FRACTION) * 0.5 * rectBboxInner[2];
  rectBboxInner[1] += (1 - CORE_FRACTION) * 0.5 * rectBboxInner[3];
  rectBboxInner[2] *= CORE_FRACTION;
  rectBboxInner[3] *= CORE_FRACTION;

  if (opts.delta !== undefined) {
    const [dx, dy] = opts.delta;
    rectBboxInner[0] += dx;
    rectBboxInner[1] += dy;
  }

  const mask = cv.Mat.zeros(img.rows, img.cols, cv.CV_8UC1);
  mask.data.fill(labelRects[0]);

  util.drawRectangle(mask, rectBkgndOuter, {
    colour: labelRects[1],
    thickness: -1
  });
  util.drawRectangle(mask, rectBkgndInner, {
    colour: labelRects[2],
    thickness: -1
  });

  if (opts.neutralZoneWidth) {
    const bbox_shrunk = _inflateBox(bbox, -opts.neutralZoneWidth);
    const bbox_inflated = _inflateBox(bbox, opts.neutralZoneWidth);
    util.drawRectangle(mask, bbox_inflated, {
      colour: GC_NEUTRAL,
      thickness: -1
    });
    util.drawRectangle(mask, bbox_shrunk, {
      colour: labelRects[3],
      thickness: -1
    });
  } else {
    util.drawRectangle(mask, bbox, { colour: labelRects[3], thickness: -1 });
  }

  util.drawRectangle(mask, rectBboxInner, {
    colour: labelRects[4],
    thickness: -1
  });

  _grabcutMaskMapNeutral(mask);

  return mask;
}

/**
 * Marks fixed size circles next to click locations as sure foreground
 * in a GrabCut seed mask
 *
 * @param {OpenCV Mat} seedMask
 * @param {array} clicks: array of objects of type {'x':val, 'y':val} (=cv.Point)
 *
 * @returns {*} nothing, alters the input matrix
 */
function _clampClicksIntoSeedMask(
  seedMask: OpenCVImage,
  clicks: Point2D[]
): void {
  const CLAMP_RADIUS = 2 * 7;

  if (clicks.length === 0) return;

  const bbox = bboxOfClicksUnitary(clicks);
  const centrePt = {
    x: Math.trunc(bbox[0] + 0.5 * bbox[2]),
    y: Math.trunc(bbox[1] + 0.5 * bbox[3])
  };

  clicks.forEach(click => {
    const diff = { x: click.x - centrePt.x, y: click.y - centrePt.y };

    // corner towards the click centroid
    const otherCorner = {
      x: click.x + (diff.x < 0 ? CLAMP_RADIUS - 1 : -CLAMP_RADIUS + 1),
      y: click.y + (diff.y < 0 ? CLAMP_RADIUS - 1 : -CLAMP_RADIUS + 1)
    };

    const clampRect = [
      Math.min(click.x, otherCorner.x),
      Math.min(click.y, otherCorner.y),
      CLAMP_RADIUS,
      CLAMP_RADIUS
    ];

    const colour = [cv.GC_FGD, 0, 0, 0];
    //cv.circle(seedMask, clampCentre, CLAMP_RADIUS, colour, cv.FILLED);
    util.drawRectangle(seedMask, clampRect, { colour, thickness: -1 });
  });
}

/**
 * Marks fixed size circles next to click locations as sure background
 * in a GrabCut seed mask
 *
 * @param {OpenCV Mat} seedMask
 * @param {array} negativeClicks: array of objects of type {'x':val, 'y':val} (=cv.Point)
 * @param {array} boundingRectangle: [x, y, w, h] array specifying the object rectangle
 *
 * @returns {*} nothing, alters the input matrix
 */
function _clampNegativeClicksIntoSeedMask(
  seedMask: OpenCVImage,
  negativeClicks: Point2D[],
  boundingRectangle: BoundingBox
) {
  if (negativeClicks.length === 0) {
    return;
  }

  const CLAMP_RADIUS = 2 * 7;
  //     # find out the center of click bbox
  const bboxPositive = boundingRectangle;
  const centrePt = {
    x: Math.trunc(bboxPositive[0] + 0.5 * bboxPositive[2]),
    y: Math.trunc(bboxPositive[1] + 0.5 * bboxPositive[3])
  };

  negativeClicks.forEach(click => {
    const diff = { x: click.x - centrePt.x, y: click.y - centrePt.y };

    // corner away from the click centroid
    const otherCorner = {
      x: click.x + (diff.x < 0 ? -CLAMP_RADIUS + 1 : CLAMP_RADIUS - 1),
      y: click.y + (diff.y < 0 ? -CLAMP_RADIUS + 1 : CLAMP_RADIUS - 1)
    };

    const clampRect = [
      Math.min(click.x, otherCorner.x),
      Math.min(click.y, otherCorner.y),
      CLAMP_RADIUS,
      CLAMP_RADIUS
    ];

    const colour = [cv.GC_BGD, 0, 0, 0];
    util.drawRectangle(seedMask, clampRect, { colour, thickness: -1 });
  });
}

export default {
  bboxOfClicksUnitary,
  bboxOfClicksNonInclusive,
  refineAutomatic,
  refineAutomaticSanityChecks,
  intersectionOverUnion
};
