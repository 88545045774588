








































































































import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  Ref,
  toRefs
} from '@vue/composition-api';
import _fromPairs from 'lodash/fromPairs';
import { v4 as uuidv4 } from 'uuid';
import { PropType } from 'vue';

import { ImageAnnotation, ImageAnnotationGroup } from './types';
import {
  isAnnotationInGroup,
  updateSelectedGroupsForAnnotation
} from './groups';
import ImageAnnotationListLabelRow from './molecules/ImageAnnotationListLabelRow.vue';
import ImageAnnotationListGroupRow from './molecules/ImageAnnotationListGroupRow.vue';
import Tooltip from '@/components/molecules/Tooltip.vue';

export interface GroupWithAnnotations {
  group: ImageAnnotationGroup;
  labels: ImageAnnotation[];
}

const useGroupsWithLabels = ({
  annotations,
  groups
}: {
  annotations: Ref<ImageAnnotation[]>;
  groups: Ref<ImageAnnotationGroup[]>;
}) => {
  const groupsWithLabels = computed(() => {
    return groups.value.map(group => {
      const annotationsInGroup = annotations.value.filter(annotation =>
        isAnnotationInGroup(annotation, group)
      );
      return {
        group,
        labels: annotationsInGroup
      };
    });
  });

  const labelsWithoutAGroup = computed(() => {
    const annotationsInGroups = groupsWithLabels.value.flatMap(group =>
      group.labels.map(label => label._id)
    );
    return annotations.value.filter(
      annotation => !annotationsInGroups.includes(annotation._id)
    );
  });

  return { groupsWithLabels, labelsWithoutAGroup };
};

export default defineComponent({
  components: {
    ImageAnnotationListLabelRow,
    ImageAnnotationListGroupRow,
    Tooltip
  },
  props: {
    annotations: {
      required: true,
      type: Array as PropType<Array<ImageAnnotation>>
    },
    selectedAnnotationId: {
      required: false,
      type: String
    },
    groups: {
      required: true,
      type: Array as PropType<ImageAnnotationGroup[]>
    }
  },
  setup(props, { emit }) {
    const { annotations, groups } = toRefs(props);

    const showSearch = ref(false);
    const searchText = ref('');

    const filteredAnnotations = computed(() =>
      annotations.value.filter(annotation =>
        annotation.label.includes(searchText.value)
      )
    );

    const groups_ = computed({
      get() {
        return groups.value;
      },
      set(newValue: ImageAnnotationGroup[]) {
        console.log(`Updating groups with value`, newValue);
        emit('update:groups', newValue);
      }
    });

    function createGroup() {
      const existingGroups = groups.value;

      const newGroupName = `Group ${existingGroups.length + 1}`;

      const newGroup = { _id: uuidv4(), name: newGroupName, members: [] };
      const newGroups = [...groups_.value, newGroup];
      groups_.value = newGroups;
    }

    const { groupsWithLabels, labelsWithoutAGroup } = useGroupsWithLabels({
      annotations,
      groups: groups_
    });

    const groupsByAnnotation: ComputedRef<Record<
      string,
      ImageAnnotationGroup[]
    >> = computed(() => {
      return _fromPairs(
        annotations.value.map(annotation => {
          return [
            annotation._id,
            groups_.value.filter(group =>
              isAnnotationInGroup(annotation, group)
            )
          ];
        })
      );
    });

    function updateGroupsForAnnotation(
      annotation: ImageAnnotation,
      groups: ImageAnnotationGroup[]
    ) {
      console.log(`Updating new groups for annotation`, groups);
      const allGroups = groups_.value;
      const selectedGroups = groups;
      const newGroups = updateSelectedGroupsForAnnotation({
        annotation,
        selectedGroups,
        groups: allGroups
      });

      emit('update:groups', newGroups);
    }

    const treeItems = computed(() => {
      const groups = groupsWithLabels.value.map(group => {
        const groupChildren = group.labels.map(label => ({
          ...label,
          name: label.label
        }));
        return { ...group, name: group.group.name, children: groupChildren };
      });

      const rest = labelsWithoutAGroup.value.map(label => ({
        ...label,
        name: label.label
      }));

      return [...groups, ...rest];
    });

    function deleteAnnotation(id: string) {
      emit('deleteAnnotation', id);
    }

    function selectAnnotation(id: string) {
      emit('update:selectedAnnotationId', id);
    }

    function deleteGroup(group: ImageAnnotationGroup) {
      const newGroups = groups_.value.filter(
        group_ => group_._id !== group._id
      );
      groups_.value = newGroups;
    }

    function updateGroup(group: ImageAnnotationGroup) {
      const newGroups = groups_.value.map(group_ => {
        if (group_._id !== group._id) {
          return group_;
        }
        console.log(`Updating group`, group);
        return group;
      });
      groups_.value = newGroups;
    }

    const filterVisibleLabels = computed(() => {
      const filtered = filteredAnnotations.value;
      function isVisible(label: ImageAnnotation): boolean {
        return filtered.some(l => l._id === label._id);
      }

      function filterVisible(labels: ImageAnnotation[]): ImageAnnotation[] {
        return labels.filter(label => isVisible(label));
      }
      return filterVisible;
    });

    return {
      createGroup,
      groups_,
      groupsWithLabels,
      filterVisibleLabels,
      labelsWithoutAGroup,
      groupsByAnnotation,
      updateGroupsForAnnotation,
      treeItems,
      deleteAnnotation,
      selectAnnotation,
      deleteGroup,
      updateGroup,
      showSearch,
      searchText,
      filteredAnnotations
    };
  },
  methods: {
    deleteItem(itemId: string) {
      this.$emit('deleteAnnotation', itemId);
    },
    updateAnnotation(annotation: ImageAnnotation) {
      this.$emit('updateAnnotation', annotation._id, annotation);
    }
  },
  computed: {
    imageAnnotationIds(): string[] {
      return this.annotations.map(annotation => annotation._id);
    },
    localSelectedAnnotationId: {
      get: function(): null | string {
        return this.selectedAnnotationId;
      },
      set: function(newValue: null | string): void {
        console.log(
          `Emitting event for updating the selected annotation`,
          newValue
        );
        this.$emit('update:selectedAnnotationId', newValue);
      }
    }
  }
});
