export const enum GeometryKind {
  Box = 'box',
  Polygon = 'polygon',
  Skeleton = 'skeleton'
}

interface Point {
  x: number;
  y: number;
}

interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Polygon {
  points: Point[];
}

interface SkeletonNode {
  name: string;
  x: number;
  y: number;
}

interface Skeleton {
  nodes: SkeletonNode[];
}

export interface BoxGeometry {
  kind: GeometryKind.Box;
  box: BoundingBox;
}

export interface PolygonGeometry {
  kind: GeometryKind.Polygon;
  polygon: Polygon;
}

export interface SkeletonGeometry {
  kind: GeometryKind.Skeleton;
  skeleton: Skeleton;
}

export type ImageAnnotationJSONGeometry =
  | BoxGeometry
  | PolygonGeometry
  | SkeletonGeometry;
