



































import paper from 'paper';
import Vue, { PropType } from 'vue';
import { DEFAULT_COLOR } from './config';
import KeyboardShortcutIcon from './KeyboardShortcutIcon.vue';

export default Vue.extend({
  name: 'LabelsToolbar',
  components: {
    KeyboardShortcutIcon
  },
  props: {
    availableLabels: {
      required: true,
      type: Array as PropType<Array<string>>
    },
    labelColors: {
      required: true,
      type: Object as PropType<Record<string, paper.Color>>
    },
    labelShortcuts: {
      required: false,
      type: Object as PropType<Record<string, string>>
    },
    selectedLabel: {
      required: true,
      type: String
    }
  },
  computed: {
    localSelectedLabel: {
      get: function(): null | string {
        return this.selectedLabel;
      },
      set: function(newValue: string) {
        this.$emit('update:selectedLabel', newValue);
      }
    }
  },
  methods: {
    getLabelColor(label: string): string {
      const availableColor = this.labelColors[label] as paper.Color;
      return (availableColor || new paper.Color(DEFAULT_COLOR)).toCSS(true);
    },
    getLabelShortcut(label: string): string | null {
      const labelShortcut = this.labelShortcuts[label];
      return labelShortcut || null;
    }
  }
});
