





















































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import Tooltip from '@/components/molecules/Tooltip.vue';
import { ImageAnnotation, ImageAnnotationGroup } from '../types';

export const useUpdateGroupDialog = () => {
  const isOpen = ref(false);

  function closeDialog() {
    isOpen.value = false;
  }

  return {
    isOpen,
    closeDialog
  };
};

export default defineComponent({
  name: 'ManageLabelGroup',
  components: { Tooltip },
  props: {
    annotation: {
      required: true,
      type: Object as PropType<ImageAnnotation>
    },
    selected: {
      required: true,
      type: Array as PropType<ImageAnnotationGroup[]>
    },
    groups: {
      required: true,
      type: Array as PropType<ImageAnnotationGroup[]>
    }
  },
  setup(props, { emit }) {
    const { groups, selected } = toRefs(props);

    const { closeDialog, ...submitMenu } = useUpdateGroupDialog();

    const isDisabled = computed(() => groups.value.length === 0);

    const selectedGroups = ref(selected.value);

    watch(selected, function() {
      selectedGroups.value = selected.value;
    });

    function submit() {
      console.log(`Updating groups`, selectedGroups.value);
      emit('update:groups', selectedGroups.value);
      closeDialog();
    }

    return { isDisabled, closeDialog, ...submitMenu, selectedGroups, submit };
  }
});
