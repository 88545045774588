



























































































import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import _findKey from 'lodash/findKey';
import { PropType } from 'vue';
import { Asset } from '@/types';
import { ImageAnnotatorParameters } from './config';
import { AnnotationStatus } from './types';
import Tooltip from '@/components/molecules/Tooltip.vue';
import { useIsAdmin } from '@/api/use';
import { GenericTaskObject } from '@/layers';
import { useCurrentUser, useSelectedOrganization } from '@/store/use';

export default defineComponent({
  name: 'NavigationPanel',
  components: {
    Tooltip
  },
  props: {
    status: {
      type: Object as PropType<AnnotationStatus>,
      required: true
    },
    asset: {
      type: Object as PropType<Asset>,
      required: true
    },
    config: {
      type: Object as PropType<ImageAnnotatorParameters>,
      required: true
    },
    task: {
      required: true,
      type: Object as PropType<GenericTaskObject>
    }
  },
  setup(props, { emit, root: { $store: store } }) {
    const { asset, task } = toRefs(props);
    const { user } = useCurrentUser(store);
    const { selectedOrganization } = useSelectedOrganization(store);
    const showImageMetadata = ref(true);

    const imageMedia = computed(() => {
      const medias = asset.value.media;

      const imageMediaSlug = _findKey(medias, val =>
        val.media_type.startsWith('image/')
      );

      if (!imageMediaSlug) {
        return null;
      }
      return medias[imageMediaSlug];
    });

    const imageFileName = computed(() => imageMedia.value?.filename);

    function nextClicked() {
      emit('onNavigateToNext');
    }

    function prevClicked() {
      emit('onNavigateToPrevious');
    }

    const isAdmin = useIsAdmin({
      organization: selectedOrganization,
      user
    });

    const projectRoute = computed(() => ({
      name: 'ProjectTasks',
      params: {
        projectId: task.value.project.id,
        orgId: selectedOrganization.value?.id
      }
    }));

    return {
      showImageMetadata,
      imageMedia,
      imageFileName,
      nextClicked,
      prevClicked,
      isAdmin,
      projectRoute
    };
  }
});
