














































import { defineComponent } from '@vue/composition-api';
import { PropType } from 'vue';
import _debounce from 'lodash/debounce';
import { SaveStatus, SaveStatusStrings } from '../functions';
import Tooltip from '@/components/molecules/Tooltip.vue';

const DEBOUNCE_SAVE_TIME_MS = 1500;
export default defineComponent({
  name: 'SaveStatusPanel',
  components: { Tooltip },
  props: {
    saveStatus: {
      required: true,
      type: String as PropType<SaveStatusStrings>
    },
    unsavedChangesText: {
      required: false,
      type: String,
      default: "Press 's' to save"
    },
    hasUnsavedAnnotations: {
      required: true,
      type: Boolean
    }
  },
  setup() {
    return { SaveStatus };
  },
  data() {
    return {
      saveDebounced: _debounce(
        function(this) {
          this.$emit('save');
        },
        DEBOUNCE_SAVE_TIME_MS,
        {
          leading: true,
          trailing: false
        }
      ).bind(this)
    };
  }
});
