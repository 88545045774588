import paper from 'paper';
import { SkeletonGraphDefinition } from '@/components/ImageAnnotator/config';
import {
  ImageAnnotationJSONGeometry,
  GeometryKind
} from '@/components/ImageAnnotator/geometry/types';

export type RefineBox = (
  rect: paper.Rectangle,
  img: HTMLImageElement
) => Promise<RefineBoxResult>;

export interface RefineBoxResult {
  box: paper.Rectangle;
}

export type RefineClicks = (
  // discrepancy here:
  // clicks are in image coordinates
  // whereas RefineBox uses relative input scaled to [0,1]
  // and the same goes to the interpretation of the result
  positiveClicks: paper.Point[],
  negativeClicks: paper.Point[],
  img: HTMLImageElement
) => Promise<RefineClicksResult>;

export interface RefineClicksResult {
  box: paper.Rectangle;
  b64Mask: string;
}

export interface UserSelections {
  label?: string | null;
  toolIndex?: number | null;
  skeleton?: SkeletonGraphDefinition;
}

export type SaveUserSelections = (sel: UserSelections) => void;

export type SaveImageAnnotations = (
  assetId: string,
  annotations: ImageAnnotation[],
  groups: ImageAnnotationGroup[]
) => Promise<ImageAnnotation[]>;

export class Box {
  public readonly rectangle: paper.Rectangle;
  readonly kind = 'Box';

  private constructor(rectangle: paper.Rectangle) {
    this.rectangle = rectangle;
  }

  public get x() {
    return this.rectangle.topLeft.x;
  }

  public get y() {
    return this.rectangle.topLeft.y;
  }

  public get width() {
    return this.rectangle.width;
  }

  public get height() {
    return this.rectangle.height;
  }

  public get x1() {
    return this.rectangle.topLeft.x;
  }

  public get y1() {
    return this.rectangle.topLeft.y;
  }

  public get x2() {
    return this.rectangle.bottomRight.x;
  }

  public get y2() {
    return this.rectangle.bottomRight.y;
  }

  public static fromPaperRectangle(rectangle: paper.Rectangle) {
    return new Box(rectangle);
  }

  public expand(x: number): Box {
    return Box.fromPaperRectangle(this.rectangle.expand(x));
  }

  public contains(other: Box): boolean {
    return this.rectangle.contains(other.rectangle);
  }

  public static fromXYWH(data: {
    x: number;
    y: number;
    width: number;
    height: number;
  }): Box {
    return new Box(new paper.Rectangle(data));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public equals(o: any): boolean {
    if (!(o instanceof Box)) {
      return false;
    }

    return this.rectangle.equals(o.rectangle);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equalWithinTolerance(other: any, tolerance = 1e-5): boolean {
    if (!(other instanceof Box)) {
      return false;
    }

    return (
      this.expand(tolerance).contains(other) &&
      other.expand(tolerance).contains(this)
    );
  }
}

export interface SkeletonNode {
  point: paper.Point;
  name: string;
}

export class Skeleton {
  readonly nodes: SkeletonNode[];
  readonly graph: SkeletonGraphDefinition;
  readonly kind = 'Skeleton';

  constructor({
    nodes,
    graph
  }: {
    nodes: SkeletonNode[];
    graph: SkeletonGraphDefinition;
  }) {
    this.nodes = nodes;
    this.graph = graph;
  }

  fractionalToPixels(bounds: paper.Rectangle): Skeleton {
    const sizeAsPoint = new paper.Point(bounds.size);
    const nodes = this.nodes.map(node => ({
      ...node,
      point: node.point.multiply(sizeAsPoint).add(bounds.point)
    }));
    return new Skeleton({ nodes, graph: this.graph });
  }

  pixelsToFractional(bounds: paper.Rectangle): Skeleton {
    if (bounds.width === 0 && bounds.height === 0) {
      throw new Error(`Invalid bounds of zero`);
    }
    const sizeAsPoint = new paper.Point(bounds.size);
    const nodes = this.nodes.map(node => ({
      ...node,
      point: node.point.subtract(bounds.point).divide(sizeAsPoint)
    }));
    return new Skeleton({ nodes, graph: this.graph });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equalWithinTolerance(other: any, tolerance = 1e-5): boolean {
    if (!(other instanceof Skeleton)) {
      return false;
    }
    if (this.nodes.length !== other.nodes.length) {
      return false;
    }

    return this.nodes.every(
      (node, index) =>
        node.point.getDistance(other.nodes[index].point) < tolerance
    );
  }
}

export class Polygon {
  readonly points: paper.Point[];
  readonly kind = 'Polygon';
  private startIndex: number;

  constructor(points: paper.Point[] = [], startIndex = 0) {
    this.points = points;
    this.startIndex = startIndex;
  }

  /**
   * @deprecated
   * Create polygon from legacy coordinates.data object.
   * Return null if obj.type is not polygon.
   */
  static tryToCreateFromObject(obj): Polygon | null {
    const expectedType = GeometryKind.Polygon;
    if (obj.type !== expectedType) {
      return null;
    }

    const startIndex: number = `x0` in obj && `y0` in obj ? 0 : 1;

    const points = [];
    for (let i = startIndex; ; i++) {
      const xkey = `x${i}`;
      const ykey = `y${i}`;
      if (xkey in obj && ykey in obj) {
        const xVal = Number.parseFloat(obj[xkey]);
        const yVal = Number.parseFloat(obj[ykey]);
        if (!Number.isNaN(xVal) && !Number.isNaN(yVal)) {
          points.push(new paper.Point(xVal, yVal));
        }
      } else {
        break;
      }
    }
    return new Polygon(points, startIndex);
  }

  /**
   * @deprecated
   * Create legacy "coordinates.data" object from Polygon instance.
   */
  asLegacyCoordinatesDataObject(): { [k: string]: string | number } {
    const type = GeometryKind.Polygon;
    const ret = { type };
    this.points.forEach((point, index) => {
      ret[`x${index + this.startIndex}`] = point.x;
      ret[`y${index + this.startIndex}`] = point.y;
    });
    return ret;
  }

  fractionalToPixels(bounds: paper.Rectangle): Polygon {
    const sizeAsPoint = new paper.Point(bounds.size);
    return new Polygon(
      this.points.map(point => point.multiply(sizeAsPoint).add(bounds.point))
    );
  }

  pixelsToFractional(bounds: paper.Rectangle): Polygon {
    if (bounds.width !== 0 && bounds.height !== 0) {
      const sizeAsPoint = new paper.Point(bounds.size);
      return new Polygon(
        this.points.map(point =>
          point.subtract(bounds.point).divide(sizeAsPoint)
        )
      );
    }
    return new Polygon();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equals(other: any): boolean {
    if (!(other instanceof Polygon)) {
      return false;
    }
    if (this.points.length !== other.points.length) {
      return false;
    }

    return this.points.every((value, index) =>
      value.equals(other.points[index])
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equalWithinTolerance(other: any, tolerance = 1e-5): boolean {
    if (!(other instanceof Polygon)) {
      return false;
    }
    if (this.points.length !== other.points.length) {
      return false;
    }

    return this.points.every(
      (value, index) => value.getDistance(other.points[index]) < tolerance
    );
  }
}

export interface AnnotationStatus {
  assets: number;
  annotated: number;
  activeAssetIndex: number;
}

export type SaveAnnotations = (annotations: ImageAnnotation[]) => Promise<any>;

export type ImageAnnotationGeometryKind = 'Box' | 'Polygon' | 'Skeleton';
export type ImageAnnotationGeometry = Box | Polygon | Skeleton;

/**
 * Internal representation of image annotations such as boxes or polygons.
 */
export interface ImageAnnotation {
  _id: string;
  label: string;
  traceId?: string;
  geometry: ImageAnnotationGeometry;
  context?: ImageAnnotationContext;
}

/**
 * Defines the expected type of a serialized ImageAnnotation,
 * as stored in the server. Replaces the old legacy format where
 * coordinates, instead of geometry, was used.
 */
export interface ImageAnnotationJSON {
  _id: string;
  label: string;
  /** Geometry in relative coordinates */
  geometry: ImageAnnotationJSONGeometry;
  traceId?: string;
  context?: ImageAnnotationContext;
}

export interface ImageAnnotationContext {
  [key: string]: string;
}

export type ImageLabel = ImageAnnotation;

/**
 * Defines the expected type of a serialized ImageAnnotationGroup,
 * as stored in the server.
 */
export interface ImageAnnotationGroupJSON {
  _id: string;
  name: string;
  members: ImageAnnotationGroupMemberJSON[];
}

export interface ImageAnnotationGroupMemberJSON {
  _id: string;
}

export interface ImageAnnotationGroupMember {
  _id: string;
}

export interface ImageAnnotationGroup {
  _id: string;
  name: string;
  members: ImageAnnotationGroupMember[];
}

export interface SkeletonNodes {
  pointId: number;
  name: string;
  x: number;
  y: number;
  connections: number[][];
}

export interface SaveState {
  error: Error | null;
  success: boolean;
  saving: boolean;
}

export interface PaperMouseEvent extends paper.MouseEvent {
  event: MouseEvent; // paper.ToolEvent has this but is not typed
}
