




















































import { computed, defineComponent, toRefs } from '@vue/composition-api';
import HelpIcon from '../molecules/HelpIcon.vue';
import AnnotationToolHelp from './AnnotationToolHelp.vue';

import { ANNOTATION_TOOLS } from './tools';
import KeyboardShortcutIcon from './KeyboardShortcutIcon.vue';
import Tooltip from '@/components/molecules/Tooltip.vue';

export default defineComponent({
  name: 'AnnotationToolbar',
  components: {
    HelpIcon,
    AnnotationToolHelp,
    KeyboardShortcutIcon,
    Tooltip
  },
  props: {
    selectedToolIndex: {
      required: true,
      type: Number
    },
    skeletonToolEnabled: {
      required: true,
      type: Boolean
    }
  },
  setup(props) {
    const { skeletonToolEnabled } = toRefs(props);

    const tools = computed(() =>
      skeletonToolEnabled.value
        ? ANNOTATION_TOOLS
        : ANNOTATION_TOOLS.filter(tool => tool.name !== 'Skeleton')
    );

    return {
      tools
    };
  },
  computed: {
    localSelectedIndex: {
      get: function(): null | number {
        return this.selectedToolIndex;
      },
      set: function(newValue: number) {
        this.$emit('update:selectedToolIndex', newValue);
      }
    }
  }
});
