import paper from 'paper';
import { fx } from '@/components/ImageAnnotator/glfx';

let _fxCanvas = undefined;

function buildFxCanvasSingleton(): HTMLCanvasElement | null {
  /**
   * Re-use one canvas as otherwise browser complains
   * of too many active WebGL contexts.
   */
  if (_fxCanvas) {
    return _fxCanvas;
  }

  try {
    _fxCanvas = fx.canvas();
    return _fxCanvas;
  } catch (err) {
    console.error(`Failed loading glfx`, err);
    _fxCanvas = null;
    return _fxCanvas;
  }
}

export class ImageFx {
  /**
   * Image effects using glfx.js.
   * New instance should be created for every raster for proper
   * initialization. Canvas element is re-used to avoid too many
   * active WebGL contexts.
   */
  private readonly raster: paper.Raster;
  private readonly fxCanvas: any | null;
  private readonly texture: any;

  constructor({ raster }: { raster: paper.Raster }) {
    this.raster = raster;
    this.fxCanvas = buildFxCanvasSingleton();

    if (this.fxCanvas) {
      this.texture = this.fxCanvas.texture(raster.canvas);
    }
  }

  public get enabled() {
    return this.fxCanvas !== null;
  }

  public brightnessContrast(brightness: number, contrast: number) {
    if (!this.enabled) {
      return;
    }

    this.fxCanvas
      .draw(this.texture)
      .brightnessContrast(brightness, contrast)
      .update();

    this.raster.drawImage(this.fxCanvas, new paper.Point(0, 0));
  }

  public destroy() {
    if (!this.enabled) {
      return;
    }
    console.log(`Destroying glfx.js texture`);
    this.texture.destroy();
  }
}
