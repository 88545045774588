import paper from 'paper';
import _indexOf from 'lodash/indexOf';
import _max from 'lodash/max';
import _min from 'lodash/min';
import seedrandom from 'seedrandom';
import {
  BoundingBox,
  Coordinates2D,
  OpenCVImage,
  OpenCV
} from './refine-types';

export let cv: OpenCV;
export let GC_NEUTRAL: number;

export function initOpenCV(newCv: OpenCV): void {
  cv = newCv;
  GC_NEUTRAL = Math.max(cv.GC_PR_BGD, cv.GC_BGD, cv.GC_PR_FGD, cv.GC_FGD) + 1;
}

export const getCv = async (): Promise<OpenCV> => {
  // Assume loaded already
  if (!cv) {
    throw Error('OpenCV not loaded');
  }
  return cv;
};

/**
 * Select integer at random using Math.random()
 *
 * @param {Number} min
 * @param {Number} max
 * @returns{Number} a random integer in range [min,max] (both bounds included)
 */
export function randomInteger(min: number, max: number): number {
  return (
    Math.floor(Math.random() * (Math.trunc(max) - Math.trunc(min) + 1)) +
    Math.trunc(min)
  );
}

/**
 * @param {Array} array of numbers
 * @returns{Number} the maximum value in the input array
 */
export function arrayMax(array: number[]): number {
  return _max(array);
}

/**
 * Returns (one) index of the maximum element in the input array
 * @param {array} array of numbers
 * @returns {number} the index of maximal element
 *
 */
export function argMax(array: number[]): number {
  return _indexOf(array, arrayMax(array));
}

/**
 * @param {Array} array of numbers
 * @returns{Number} the minimum value in the input array
 */
export function arrayMin(array: number[]): number {
  return _min(array);
}

/**
 * Throws an error if the "condition" is not met
 *
 * @param {boolean} condition
 * @param {string} message
 */
export function assert(condition: boolean, message?: string) {
  if (!condition) {
    message = message || 'Assertion failed';
    if (typeof Error !== 'undefined') {
      throw new Error(message);
    }
    throw message; // Fallback
  }
}

/**
 * Function for setting an aynchronic sleep timer
 * @param {number} duration in seconds
 * @returns {Promise} promise that resolves after the given number of seconds
 */
export function sleep(duration: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, duration * 1000);
  });
}

/**
 * Uagments the missing fields of an object with the specified default values
 * @param {object} obj
 * @param {object} defaults
 */
export function setDefaultValues(obj, defaults): void {
  for (const key of Object.keys(defaults)) {
    if (!(key in obj)) {
      obj[key] = defaults[key];
    }
  }
}

/**
 * Clips the number into interval [lower, upper] (endpoints included)
 * @param {number} value
 * @param {number} lower
 * @param {number} upper
 */
export function clip(value: number, lower: number, upper: number): number {
  return Math.min(upper, Math.max(value, lower));
}

/**
 * Visualises a one channel OpenCV Mat as a randomly coloured matrix
 * @param {OpenCV Mat} mask of type CV_8UC1 or CV_16UC1
 * @returns {OpenCV Mat} coloured matrix of type CV_8UC3 with each label of the
 *     input matrix represented with a pseudorandom colour
 */
export function visualiseSingleChannelMask(mask: OpenCVImage): OpenCVImage {
  const colourTable = {};

  const rng = seedrandom('seed-string');

  const visMat = cv.Mat.zeros(mask.rows, mask.cols, cv.CV_8UC3);

  for (let row = 0; row < mask.rows; row++) {
    let rowVec;
    if (mask.type() === cv.CV_8U) {
      rowVec = mask.ucharPtr(row);
    } else if (mask.type() === cv.CV_16U) {
      rowVec = mask.ushortPtr(row);
    } else {
      throw 'unsupported data type in visualiseSingleChannelMask()';
    }

    const rowOffset = 3 * row * mask.cols;

    rowVec.forEach((maskval, index) => {
      if (!(maskval in colourTable)) {
        colourTable[maskval] = [rng() * 256, rng() * 256, rng() * 256];
      }
      const c = colourTable[maskval];
      visMat.data[rowOffset + 3 * index] = c[0];
      visMat.data[rowOffset + 3 * index + 1] = c[1];
      visMat.data[rowOffset + 3 * index + 2] = c[2];
    });
  }

  return visMat;
}

/**
 * Adds uniformly distributed noise over an image matrix
 *
 * @param {OpenCV Mat} mat Must be of type CV_8UC3
 * @param {*} amplitude Noise amplitude, defaults to 1
 * @returs nothing. Input mat is altered with the noise and
 *   the resulting values clipped to range [0,255]
 */
export function addUniformNoiseToMat(mat: OpenCVImage, amplitude = 1): void {
  assert(mat.type() === cv.CV_8UC3);

  for (let row = 0; row < mat.rows; row++) {
    const rowVec = mat.ucharPtr(row);

    rowVec.forEach((val, index) => {
      rowVec[index] = clip(val + randomInteger(-amplitude, amplitude), 0, 255);
    });
  }
}

/**
 * Produces RGB version of a single channel OpenCV Mat
 * setting nonzero pixels white and zeros black
 *
 * @param {OpenCV Mat} mask
 * @returns {OpenCV Mat} RGB representation of the mask
 */
export function singleChannelMaskToRGB(mask: OpenCVImage): OpenCVImage {
  const retMat = cv.Mat.zeros(mask.rows, mask.cols, cv.CV_8UC3);

  for (let row = 0; row < mask.rows; row++) {
    let rowVec;
    if (mask.type() === cv.CV_8U) {
      rowVec = mask.ucharPtr(row);
    } else if (mask.type() === cv.CV_16U) {
      rowVec = mask.ushortPtr(row);
    } else {
      throw 'unsupported data type in singleChannelMaskToRGB()';
    }

    const rowOffset = 3 * row * mask.cols;

    rowVec.forEach((maskval, index) => {
      const c = maskval > 0 ? 255 : 0;
      retMat.data[rowOffset + 3 * index] = c;
      retMat.data[rowOffset + 3 * index + 1] = c;
      retMat.data[rowOffset + 3 * index + 2] = c;
      // console.log(`${maskval} => ${c}`)
    });
  }

  return retMat;
}

/**
 * Produces RGBA version of a single channel OpenCV Mat
 * with nonzero pixels white and zeros black
 * (= opaque and transparent on the A channel)
 * @param {OpenCV Mat} mask
 * @returns {OpenCV Mat} RGBA representation of the mask
 */
export function singleChannelMaskToRGBA(mask: OpenCVImage): OpenCVImage {
  const retMat = cv.Mat.zeros(mask.rows, mask.cols, cv.CV_8UC4);

  for (let row = 0; row < mask.rows; row++) {
    let rowVec;
    if (mask.type() === cv.CV_8U) {
      rowVec = mask.ucharPtr(row);
    } else if (mask.type() === cv.CV_16U) {
      rowVec = mask.ushortPtr(row);
    } else {
      throw 'unsupported data type in singleChannelMaskToRGBA()';
    }

    const rowOffset = 4 * row * mask.cols;

    rowVec.forEach((maskval, index) => {
      const c = maskval > 0 ? 255 : 0;
      retMat.data[rowOffset + 4 * index] = c;
      retMat.data[rowOffset + 4 * index + 1] = c;
      retMat.data[rowOffset + 4 * index + 2] = c;
      retMat.data[rowOffset + 4 * index + 3] = c;
      // console.log(`${maskval} => ${c}`)
    });
  }

  return retMat;
}

/**
 * Randomly choose between array elements
 * @param {array} arr
 * @param {function} rng: pseudo random number returning number in [0,1)
 *     if left unspecified, Math.random is used
 * @returns {*} the chosen element
 */
export function randomChoice<T>(arr: T[], rng?: () => number): T {
  const index = Math.floor((rng ? rng() : Math.random()) * arr.length);
  return arr[index];
}

/**
 * In an OpenCV Grabcut output mask, count labels representing sure and probable
 * foreground within a rectangle
 * @param {OpenCV Mat} mask
 * @param {array} rect (format [x, y, w, h])
 * @returns {number} the count
 */
export function countGCForegroundWithinRect(
  mask: OpenCVImage,
  rect: BoundingBox
) {
  // mask is cv Mat
  // rect is an array [x, y, v, h]

  // ensure that the rect has integer coordinates
  const r = new cv.Rect(...rect.map(v => Math.trunc(v)));
  const maskCrop = mask.roi(r);

  const fgMat = new cv.Mat();
  const oneMat = cv.matFromArray(1, 1, cv.CV_8UC1, [1]);
  cv.bitwise_and(maskCrop, oneMat, fgMat);

  const count = cv.countNonZero(fgMat);
  maskCrop.delete();
  fgMat.delete();

  return count;
}

/**
 * Clips coordinates of a rectangle to the area of an image
 *
 * @param {array} rect The rectangle in the format [x, y, w, h]
 * @param {object} img The input image as OpenCV Mat object
 * @returns {array} the truncated rect
 */
export function cutRectToImage(
  rect: BoundingBox,
  img: OpenCVImage
): BoundingBox {
  const tlbr = boxXYWHToX1Y1X2Y2Unitary(rect);
  tlbr[0] = Math.max(0, tlbr[0]);
  tlbr[1] = Math.max(0, tlbr[1]);
  tlbr[2] = Math.min(tlbr[2], img.cols - 1);
  tlbr[3] = Math.min(tlbr[3], img.rows - 1);
  return [tlbr[0], tlbr[1], tlbr[2] - tlbr[0] + 1, tlbr[3] - tlbr[1] + 1];
}

/**
 * Converts the input rectangle representation [x, y, w, h] to
 * the format [x1, y1, x2, y2]
 * Works properly only if the pixel size is one length unit
 *
 * @param {array} box Input rectangle
 * @returns {array} the converted representation
 *  */
export function boxXYWHToX1Y1X2Y2Unitary(box: BoundingBox): BoundingBox {
  const ret = [...box];
  ret[2] += ret[0] - 1;
  ret[3] += ret[1] - 1;
  return ret;
}

/**
 * Converts the input rectangle representation [x, y, w, h] to
 * the format [x1, y1, x2, y2]
 * The output coordinates (x2,y2) denote the pixel just outside the nox
 *
 * @param {array} box Input rectangle
 * @returns {array} the converted representation
 *  */
export function boxXYWHToX1Y1X2Y2NonInclusive(box: BoundingBox): Coordinates2D {
  const ret = [...box];
  ret[2] += ret[0];
  ret[3] += ret[1];
  return ret;
}

/**
 * Calculates the Euclidean distance between two vectors
 * represented as arrays of numbers
 *
 * @param {*} a1 array of N numbers
 * @param {*} a2 array of N numbers
 * @returns {Number} the Euclidean distance between a1 and a2
 */

export function arrayDistEuclidean(a1: number[], a2: number[]): number {
  assert(a1.length === a2.length);
  let sqrD = 0;
  a1.forEach((value, index) => {
    const d = value - a2[index];
    sqrD += d * d;
  });
  return Math.sqrt(sqrD);
}

interface DrawRectangleOptions {
  colour?: number | number[];
  thickness?: number;
}

/**
 * Wrapper to the OpenCV rectangle function having defaults for colour and thickness
 * arguments
 *
 * @param {OpenCV Mat object} img
 * @param {array} bbox: the rectangle to be drawn in the [x, y, w, h] format
 * @param {object} opts: optional arguments as an object with following keys recognised
 *     - colour: colour of the rectangle (default [255,0,0])
 *     - thickness: line width of the rectangle. Value < 0 signifies filled rectangle
 */
export function drawRectangle(
  img: OpenCVImage,
  bbox: BoundingBox,
  opts: DrawRectangleOptions
) {
  assert(bbox.length === 4);
  let colour = opts.colour === undefined ? [255, 0, 0, 1] : opts.colour;
  const thickness = opts.thickness === undefined ? 1 : opts.thickness;

  // OpenCV.js requires colour to be 4 element vector even for
  // images with fewer channels

  if (Array.isArray(colour)) {
    if (colour.length > 4) {
      colour = colour.slice(0, 4);
    } else {
      while (colour.length < 4) {
        colour.push(0);
      }
    }
  } else {
    colour = [colour, 0, 0, 0];
  }

  cv.rectangle(
    img,
    { x: Math.trunc(bbox[0]), y: Math.trunc(bbox[1]) },
    {
      x: Math.trunc(bbox[0] + bbox[2] - 1),
      y: Math.trunc(bbox[1] + bbox[3] - 1)
    },
    colour,
    thickness
  );
}

export function bringWithinBounds(
  pt: paper.Point,
  bounds: paper.Rectangle
): paper.Point {
  return paper.Point.min(
    bounds.bottomRight,
    paper.Point.max(bounds.topLeft, pt)
  );
}

export default {
  assert,
  argMax,
  clip,
  visualiseSingleChannelMask,
  randomChoice,
  setDefaultValues,
  sleep,
  countGCForegroundWithinRect,
  cutRectToImage,
  singleChannelMaskToRGB,
  singleChannelMaskToRGBA,
  boxXYWHToX1Y1X2Y2Unitary,
  boxXYWHToX1Y1X2Y2NonInclusive,
  randomInteger,
  arrayDistEuclidean,
  drawRectangle,
  arrayMax,
  arrayMin,
  addUniformNoiseToMat,
  initOpenCV,
  bringWithinBounds
};
