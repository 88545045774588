var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{key:_vm.label._id,staticClass:"d-flex justify-space-between label pl-1",class:{
      'on-hover': hover,
      'on-active': _vm.isSelected
    },on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select')}}},[_c('div',{staticClass:"align-self-center text-truncate"},[_vm._v(" "+_vm._s(_vm.label.label)+" ")]),_c('div',{staticClass:"buttons d-flex align-center",on:{"click":function($event){$event.stopPropagation();}}},[_c('add-metadata-button',{attrs:{"annotation":_vm.label,"onSubmit":function (label) { return _vm.$emit('update:label', label); }}}),_c('manage-label-group',{attrs:{"annotation":_vm.label,"groups":_vm.groups,"selected":_vm.groupsForLabel},on:{"update:groups":function (groups) { return _vm.$emit('update:groups', groups); }}}),_c('tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('delete')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete object")])])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }