import { BoxGeometry, GeometryKind, PolygonGeometry } from './geometry/types';
import { Polygon } from './types';

interface CoordinatesDataRectangle {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

/**
 * @deprecated
 * Parse box geometry from legacy `coordinates.data`.
 */
export function geometryFromBoxCoord(
  data: CoordinatesDataRectangle
): { geometry: BoxGeometry } {
  console.log(`Parsing legacy box coordinates`, data);
  return {
    geometry: {
      kind: GeometryKind.Box,
      box: {
        x: data.x1,
        y: data.y1,
        width: data.x2 - data.x1,
        height: data.y2 - data.y1
      }
    }
  };
}

/**
 * @deprecated
 * Parse polygon geometry from legacy `coordinates.data`.
 */
export function geometryFromPolygonCoord(
  data: Record<string, string | number>
): { geometry: PolygonGeometry } {
  console.log(`Parsing legacy polygon coordinates`, data);
  const polygon = Polygon.tryToCreateFromObject(data);
  if (!polygon) {
    throw Error(
      `Coordinates data is not a valid polygon: ${JSON.stringify(data)}.`
    );
  }
  return {
    geometry: {
      kind: GeometryKind.Polygon,
      polygon: {
        points: polygon.points.map(p => {
          return { x: p.x, y: p.y };
        })
      }
    }
  };
}
