import paper from 'paper';
import { Point } from 'paper/dist/paper-core';
import { v4 as uuidv4 } from 'uuid';
import {
  geometryFromBoxCoord,
  geometryFromPolygonCoord
} from './backwards_compatibility';
import { DEFAULT_SKELETON, ImageAnnotatorParameters } from './config';
import {
  BoxGeometry,
  ImageAnnotationJSONGeometry,
  GeometryKind,
  PolygonGeometry,
  SkeletonGeometry
} from './geometry/types';
import {
  ImageAnnotation,
  ImageAnnotationGeometry,
  ImageAnnotationJSON,
  Box,
  Polygon,
  Skeleton,
  ImageAnnotationGroup,
  ImageAnnotationGroupJSON
} from './types';

function boxToBoxGeometry(box: Box): BoxGeometry {
  const { x, y, width, height } = box;
  return { kind: GeometryKind.Box, box: { x, y, width, height } };
}

function polygonToPolygonGeometry(polygon: Polygon): PolygonGeometry {
  return {
    kind: GeometryKind.Polygon,
    polygon: {
      points: polygon.points.map(p => {
        return { x: p.x, y: p.y };
      })
    }
  };
}

function skeletonToSkeletonGeometry(skeleton: Skeleton): SkeletonGeometry {
  // Map internal representation of a skeleton to "external" representation
  return {
    kind: GeometryKind.Skeleton,
    skeleton: {
      nodes: skeleton.nodes.map(n => ({
        x: n.point.x,
        y: n.point.y,
        name: n.name
      }))
    }
  };
}

function geometryToJSON(
  geometry: ImageAnnotationGeometry
): { geometry: ImageAnnotationJSONGeometry } {
  if (geometry instanceof Box) {
    return { geometry: boxToBoxGeometry(geometry) };
  } else if (geometry instanceof Polygon) {
    return { geometry: polygonToPolygonGeometry(geometry) };
  } else if (geometry instanceof Skeleton) {
    return { geometry: skeletonToSkeletonGeometry(geometry) };
  }

  throw Error(`Object ${geometry} is not recognized as a valid geometry.`);
}

function geometryFromJSON(
  json: ImageAnnotationJSON,
  config: ImageAnnotatorParameters
): { geometry: ImageAnnotationGeometry } {
  switch (json.geometry.kind) {
    case GeometryKind.Box: {
      return {
        geometry: Box.fromPaperRectangle(new paper.Rectangle(json.geometry.box))
      };
    }

    case GeometryKind.Polygon: {
      return {
        geometry: new Polygon(
          json.geometry.polygon.points.map(p => {
            return new Point(p.x, p.y);
          })
        )
      };
    }

    case GeometryKind.Skeleton: {
      const nodes = json.geometry.skeleton.nodes.map(node => ({
        point: new paper.Point(node.x, node.y),
        name: node.name
      }));

      const graph =
        config.skeletons.length > 0 ? config.skeletons[0] : DEFAULT_SKELETON;

      // Crude check that the label matches the graph by name
      if (json.label !== graph.name) {
        throw Error(
          `Got skeleton with name ${json.label}, does not match the name in graph: ${graph.name}`
        );
      }

      return {
        geometry: new Skeleton({ nodes, graph })
      };
    }

    default:
      throw Error(
        `Object ${json} is not recognized as a valid image annotation label.`
      );
  }
}

/**
 * @deprecated
 * Create legacy "coordinates.data" object from internal image label representation
 * for backwards-compatibility.
 */
function geometryToLegacyJSON(
  geometry: ImageAnnotationGeometry
): { coordinates: { data: Record<string, string | number> } } {
  if (geometry instanceof Box) {
    return {
      coordinates: {
        data: {
          type: 'box',
          x1: geometry.x1,
          y1: geometry.y1,
          x2: geometry.x2,
          y2: geometry.y2
        }
      }
    };
  } else if (geometry instanceof Polygon) {
    return {
      coordinates: {
        data: geometry.asLegacyCoordinatesDataObject()
      }
    };
  } else if (geometry instanceof Skeleton) {
    return { coordinates: { data: {} } };
  }
  throw new Error(
    `Unknown geometry type: ${typeof geometry}, ${JSON.stringify(geometry)}`
  );
}

export function imageAnnotationToJSON(
  annotation: ImageAnnotation
): ImageAnnotationJSON {
  return {
    ...annotation,
    ...geometryToJSON(annotation.geometry),
    ...geometryToLegacyJSON(annotation.geometry)
  };
}

export function imageAnnotationGroupToJSON(
  group: ImageAnnotationGroup
): ImageAnnotationGroupJSON {
  return {
    name: group.name,
    _id: group._id,
    members: group.members.map(member => ({ _id: member._id }))
  };
}

function resolveJSON(json: any): ImageAnnotationJSON {
  /**
   * Resolve an unknown label JSON into an ImageAnnotationJSON.
   * Migrate old "coordinates.data" labels to new "geometry" JSON format.
   * Add "_id" field if none exists yet.
   */
  if (!json.label || !(json.geometry || json.coordinates)) {
    throw Error(
      `Label ${JSON.stringify(
        json
      )} is not valid. It must contain "label" and either "geometry" or "coordinates".`
    );
  }

  const annotation = {
    _id: json._id || uuidv4(),
    label: json.label,
    geometry: json.geometry,
    context: json.context,
    trace_id: json.trace_id
  };

  // Even if coordinates is present, priority is given to geometry.
  if (annotation.geometry) {
    return annotation;
  }

  // For legacy labels, the "box" type is assumed if coordinates.data.type
  // is not present. Otherwise, type must be "polygon".
  const expectedType = json.coordinates.data.type || GeometryKind.Box;
  return expectedType == GeometryKind.Box
    ? { ...annotation, ...geometryFromBoxCoord(json.coordinates.data) }
    : { ...annotation, ...geometryFromPolygonCoord(json.coordinates.data) };
}

export function imageAnnotationFromJSON(
  json: any,
  config: ImageAnnotatorParameters
): ImageAnnotation {
  const json_ = resolveJSON(json);
  return { ...json_, ...geometryFromJSON(json_, config) };
}

export function imageAnnotationGroupFromJSON(json: any): ImageAnnotationGroup {
  return {
    _id: json._id || uuidv4(),
    name: json.name,
    members: json.members
  };
}
