import paper from 'paper';
import { Ref, unref } from '@vue/composition-api';
import { Mask, Objects, Zoom } from '@/components/ImageAnnotator/drawer';
import { RefineBox } from '@/components/ImageAnnotator/types';
import {
  scaleRelativeBoxToImage,
  scaleToRelativeBox
} from '@/components/ImageAnnotator/utils';
import {
  refineBox,
  refineBoxIdentity,
  refineClicks
} from '@/components/ImageAnnotator/algorithms/refine';
import { sleep } from '@/components/ImageAnnotator/algorithms/util';
import {
  AnnotationContainer,
  BoundingBoxPathContainer,
  PolygonPathContainer
} from '@/components/ImageAnnotator/annotation-container';
import { ClickTool } from '@/components/ImageAnnotator/click-tool';
import { PolygonTool } from '@/components/ImageAnnotator/polygon-tool';
import { makeSkeletonTool } from '@/components/ImageAnnotator/skeleton/skeleton-tool';
import { VisualElementSizes } from '@/components/ImageAnnotator/scaling';
import { SkeletonGraphDefinition } from './config';
import { PaperMouseEvent } from '@/components/ImageAnnotator/types';

const ZOOM_IN_KEY = '+';
const ZOOM_OUT_KEY = '-';
const PAN_TOGGLE_KEY = 'space';
const PAN_TOGGLE_KEY_TOUCHPAD = 'p';

const FILTER_ALPHA = 0.8;

export interface DrawerMouseTool {
  deactivate(): void;
}

export interface ToolProps {
  objects: Objects;
  zoom: Zoom;
  mask: Mask;
  sizes: Ref<VisualElementSizes>;
  selectedAnnotationId: Ref<string>;
  selectAnnotationId: (id: string | null) => void;
  hitTest: (
    point: paper.Point,
    options: any
  ) => { hitResult: paper.HitResult; objects: AnnotationContainer[] };
  setCursor(cursor: string): void;
  onKeyUp?(event: paper.KeyEvent): void;
  imageBounds: paper.Rectangle;
  activeClassColor(): paper.Color;
  imageElement: HTMLImageElement;
  createAnnotation: ({ container }: { container: AnnotationContainer }) => void;
  skeletonGraph: Ref<SkeletonGraphDefinition>;
}

export interface ToolUIConnector {
  build: (props: ToolProps) => DrawerMouseTool;
  name: string;
  shortcut?: string;
  helptext?: string;
  icon: string;
}

const hitOptions = {
  segments: true,
  stroke: true,
  fill: true,
  tolerance: 5
};

let mousePoint: paper.Point = undefined;

export function createPaperTool({
  onKeyUp,
  onKeyDown,
  onMouseDown,
  onMouseUp,
  onMouseDrag,
  onMouseMove,
  props,
  cursor
}: {
  onKeyUp?: (ev: paper.KeyEvent) => void;
  onKeyDown?: (ev: paper.KeyEvent) => void;
  onMouseDown?: (ev: PaperMouseEvent) => void;
  onMouseUp?: (ev: paper.MouseEvent) => void;
  onMouseDrag?: (ev: paper.MouseEvent) => void;
  onMouseMove?: (ev: paper.MouseEvent) => void;
  props: ToolProps;
  cursor: string;
}): paper.Tool {
  let isPanning = false;
  let isPanningTouchpad = false;
  let prevDelta: paper.Point = undefined;

  const tool = new paper.Tool();

  const doPanning = (ev: paper.MouseEvent) => {
    /**
     * Perform simple low-pass filtering as the delta
     * points fluctuate around origin when dragging
     */
    const delta = ev.delta;

    if (prevDelta) {
      const newDelta = prevDelta.add(
        delta.subtract(prevDelta).multiply(FILTER_ALPHA)
      );
      prevDelta = newDelta;
      props.zoom.moveCenter(newDelta.multiply(-1));
    } else {
      prevDelta = delta;
    }
  };

  tool.onKeyDown = (event: paper.KeyEvent) => {
    if (!mousePoint) {
      return;
    }
    switch (event.key) {
      case ZOOM_IN_KEY:
        props.zoom.in(mousePoint);
        props.setCursor?.('zoom-in');
        break;
      case ZOOM_OUT_KEY:
        props.zoom.out(mousePoint);
        props.setCursor?.('zoom-out');
        break;
      case PAN_TOGGLE_KEY:
        props.setCursor?.('grab');
        isPanning = true;
        event.preventDefault();
        break;
      case PAN_TOGGLE_KEY_TOUCHPAD:
        props.setCursor?.('grab');
        isPanningTouchpad = true;
        event.preventDefault();
        break;
    }
    onKeyDown?.(event);
  };

  tool.onKeyUp = (event: paper.KeyEvent) => {
    switch (event.key) {
      case PAN_TOGGLE_KEY:
        // Restore cursor
        props.setCursor?.(cursor);
        isPanning = false;
        break;
      case PAN_TOGGLE_KEY_TOUCHPAD:
        // Restore cursor
        props.setCursor?.(cursor);
        isPanningTouchpad = false;
        break;
    }
    props.onKeyUp?.(event);
    onKeyUp?.(event);
  };

  tool.onMouseDown = (ev: PaperMouseEvent) => {
    switch (ev.event.button) {
      case 1:
        props.setCursor?.('grab');
        isPanning = true;
    }
    if (isPanning || isPanningTouchpad) {
      prevDelta = null;
    } else {
      onMouseDown?.(ev);
    }
  };

  tool.onMouseUp = (ev: PaperMouseEvent) => {
    switch (ev.event.button) {
      case 1:
        props.setCursor?.(cursor);
        isPanning = false;
    }
    onMouseUp?.(ev);
  };

  tool.onMouseDrag = (ev: paper.MouseEvent) => {
    if (isPanning) {
      doPanning(ev);
    } else if (isPanningTouchpad) {
      return;
    } else {
      onMouseDrag?.(ev);
    }
  };

  tool.onMouseMove = (ev: paper.MouseEvent) => {
    mousePoint = ev.point;
    if (isPanning) {
      props.setCursor?.('grab');
    } else if (isPanningTouchpad) {
      props.setCursor?.('grab');
      doPanning(ev);
    } else {
      onMouseMove?.(ev);
    }
  };

  return tool;
}

export class SelectObjectTool implements DrawerMouseTool {
  private readonly props: ToolProps;
  private paperTool: paper.Tool;
  private isDragging: boolean;
  private selectedNode: paper.Item | null;

  constructor(props: ToolProps) {
    this.props = props;
    this.paperTool = this.setupPaperTool();
    this.isDragging = false;
  }

  private get cursor() {
    return 'pointer';
  }

  deactivate() {
    this.paperTool.remove();
  }

  private setupPaperTool() {
    const tool = createPaperTool({
      onMouseUp: ev => this.onMouseUp(ev),
      onMouseDown: ev => this.onMouseDown(ev),
      onMouseMove: ev => this.onMouseMove(ev),
      onMouseDrag: ev => this.onMouseDrag(ev),
      onKeyUp: ev => this.onKeyUp(ev),
      props: this.props,
      cursor: this.cursor
    });
    tool.minDistance = 1;
    return tool;
  }

  private onMouseMove(event: paper.MouseEvent) {
    const selected = this.props.objects.getSelectedAnnotationPathContainer();

    if (event.modifiers.shift) {
      if (selected instanceof PolygonPathContainer) {
        selected.visualiseNewNode(event.point);
      }
    }
    this.updateCursor();
  }

  private updateCursor() {
    this.props.setCursor?.(this.cursor);
  }

  private updateSelectedNode(hitItem: paper.Item | null) {
    const selected = this.props.objects.getSelectedAnnotationPathContainer();
    if (!selected || !(selected instanceof PolygonPathContainer)) {
      this.selectedNode = null;
      return;
    }
    if (this.selectedNode) {
      selected.removeNodeHighlighting();
    }
    if (!hitItem || !hitItem.name) {
      this.selectedNode = null;
      selected.removeNodeHighlighting();
      return;
    }
    if (hitItem.name && hitItem.name.match('Polygon node*')) {
      this.selectedNode = hitItem;
      selected.highlightNode(hitItem.index);
    } else {
      this.selectedNode = null;
      selected.removeNodeHighlighting();
    }
  }

  private deleteSelectedNode() {
    const selected = this.props.objects.getSelectedAnnotationPathContainer();
    if (!this.selectedNode || !selected) {
      return;
    } else {
      selected.path.removeSegment(this.selectedNode.index);
      this.selectedNode = null;
      this.props.objects.updateAnnotationGeometryInStore(
        this.props.objects.getSelectedAnnotationPathContainer()
      );
      this.props.objects.refreshSelection();
    }
  }

  private onMouseDown(event: PaperMouseEvent) {
    this.isDragging = false;

    const point = event.point;
    const { hitResult, objects: hitObjects } = this.props.hitTest(
      point,
      hitOptions
    );

    if (!hitResult) {
      return;
    }

    console.debug(`Hit objects`, hitObjects);
    console.debug(`Select tool hit result`, hitResult);

    // If <SHIFT> -key down, keep current select object.
    if (event.modifiers.shift) {
      return;
    }

    this.updateSelectedNode(hitResult.item);

    if (hitObjects.length === 0) {
      this.props.selectAnnotationId(null);
      return;
    }

    // If clicked on single annotation object or non-shared area
    if (hitObjects.length == 1) {
      this.props.selectAnnotationId(hitObjects[0].labelId);
      return;
    }

    // When mouse click on overlapped or shared area
    // When no selected annotation
    if (!this.props.selectedAnnotationId) {
      this.props.selectAnnotationId(hitObjects[0].labelId);
      return;
    }
    const selectedId = hitObjects.find(
      hitObject => hitObject.labelId === unref(this.props.selectedAnnotationId)
    );

    this.props.selectAnnotationId(
      selectedId === undefined ? hitObjects[0].labelId : selectedId.labelId
    );
  }

  private onMouseDrag(_: paper.MouseEvent) {
    const selected = this.props.objects.getSelectedAnnotationPathContainer();
    if (!selected) {
      return;
    }

    this.isDragging = true;
    this.props.objects.clearLabelTexts();
  }

  private async onMouseUp(event: paper.MouseEvent) {
    const selected = this.props.objects.getSelectedAnnotationPathContainer();

    if (!selected) {
      return;
    }

    if (
      event.modifiers.shift &&
      !this.isDragging &&
      selected instanceof PolygonPathContainer
    ) {
      selected.addNewNode(event.point);
    }

    if (!this.isDragging && !event.modifiers.shift) {
      return;
    }

    this.updateSelectedNode(null);

    this.isDragging = false;

    this.props.objects.updateAnnotationGeometryInStore(
      this.props.objects.getSelectedAnnotationPathContainer()
    );
    // Re-draw the selected item
    this.props.objects.refreshSelection();
  }

  private async onKeyUp(event: paper.KeyEvent) {
    const selected = this.props.objects.getSelectedAnnotationPathContainer();
    switch (event.key) {
      case 'shift':
        if (selected instanceof PolygonPathContainer) {
          selected.removeNewNodeVisualisation();
        }
        break;
      case 'backspace':
        this.deleteSelectedNode();
        break;
    }
  }
}

const MIN_DRAG_DISTANCE_POINTS = 1;

export class DrawRectangleTool implements DrawerMouseTool {
  private props: ToolProps;
  private paperTool: paper.Tool;
  private rectangle?: paper.Shape.Rectangle;
  private isDragging = false;
  private mouseDown?: paper.Point;
  private refine: RefineBox;
  private isProcessing = false;

  constructor(props: ToolProps, refine: RefineBox) {
    this.props = props;
    this.paperTool = this.setupPaperTool();
    this.refine = refine;
  }

  private get cursor() {
    return this.isProcessing ? 'wait' : 'crosshair';
  }

  deactivate() {
    this.paperTool.remove();
  }

  private setupPaperTool() {
    const tool = createPaperTool({
      onMouseUp: ev => this.onMouseUp(ev),
      onMouseDown: ev => this.onMouseDown(ev),
      onMouseMove: ev => this.onMouseMove(ev),
      onMouseDrag: ev => this.onMouseDrag(ev),
      props: this.props,
      cursor: this.cursor
    });
    tool.minDistance = MIN_DRAG_DISTANCE_POINTS;
    return tool;
  }

  private onMouseDown(event: paper.MouseEvent) {
    if (this.isProcessing) {
      this.mouseDown = null;
      this.isDragging = false;
      return;
    }

    if (this.props.imageBounds.contains(event.point)) {
      this.mouseDown = event.point;
    }
    this.isDragging = false;
  }

  private onMouseMove(_: paper.MouseEvent) {
    this.updateCursor();
  }

  private updateCursor() {
    this.props.setCursor?.(this.cursor);
  }

  private updateRectangle(
    item: paper.Shape.Rectangle,
    start: paper.Point,
    last: paper.Point
  ) {
    const xs = [start.x, last.x];
    const ys = [start.y, last.y];
    const minX = Math.min(...xs);
    const maxX = Math.max(...xs);
    const minY = Math.min(...ys);
    const maxY = Math.max(...ys);
    const width = maxX - minX;
    const height = maxY - minY;
    const pos = new paper.Point(minX + 0.5 * width, minY + 0.5 * height);
    const size = new paper.Size(width, height);
    item.position = pos;
    item.size = size;
  }

  private onMouseDrag(event: paper.MouseEvent) {
    if (!this.mouseDown) {
      return;
    }

    this.isDragging = true;

    const bounds = this.props.imageBounds;
    const adjustedPt = paper.Point.min(
      bounds.bottomRight,
      paper.Point.max(bounds.topLeft, event.point)
    );

    if (this.rectangle) {
      this.updateRectangle(this.rectangle, this.mouseDown, adjustedPt);
    } else {
      this.rectangle = this.drawRectangle(this.mouseDown, adjustedPt, 0.05);
    }
  }

  private drawRectangle(
    from: paper.Point,
    to: paper.Point,
    opacity = 0.1
  ): paper.Shape.Rectangle {
    const rectangle = new paper.Shape.Rectangle(from, to);
    const stroke = this.props.activeClassColor();
    const transparent = new paper.Color(
      stroke.red,
      stroke.green,
      stroke.blue,
      opacity
    );
    rectangle.fillColor = transparent;
    rectangle.strokeColor = stroke;
    return rectangle;
  }

  private async onMouseUp(event: paper.MouseEvent) {
    if (this.rectangle) {
      this.rectangle.remove();
      this.rectangle = null;
    }

    if (!this.mouseDown || !this.isDragging) {
      this.mouseDown = null;
      this.isDragging = false;
      return;
    }

    try {
      console.log(
        `Saving new rectangle, from: ${this.mouseDown}, to ${event.point}`
      );

      const bounds = this.props.imageBounds;
      const adjustedPt = paper.Point.min(
        bounds.bottomRight,
        paper.Point.max(bounds.topLeft, event.point)
      );

      const shape = this.drawRectangle(this.mouseDown, adjustedPt);
      const relativeBox = scaleToRelativeBox(
        shape.bounds,
        this.props.imageBounds
      );
      this.isProcessing = true;
      this.updateCursor();
      await sleep(0.1); // DOM update

      let refinedRelativeBox: paper.Rectangle;
      try {
        const refineResult = await this.refine(
          relativeBox,
          this.props.imageElement
        );
        refinedRelativeBox = refineResult.box;
      } finally {
        this.isProcessing = false;
        this.updateCursor();
        shape.remove();
      }

      const refineImageBox = scaleRelativeBoxToImage(
        refinedRelativeBox,
        this.props.imageBounds
      );

      const refinedShape = this.drawRectangle(
        refineImageBox.topLeft,
        refineImageBox.bottomRight
      );
      const container = BoundingBoxPathContainer.createFromPath(
        refinedShape.toPath(true)
      );
      refinedShape.remove();
      this.props.createAnnotation({ container });
    } finally {
      this.mouseDown = null;
      this.isDragging = false;
    }
  }
}

export const selectObject: ToolUIConnector = {
  build: (props: ToolProps) => new SelectObjectTool(props),
  name: 'Select',
  shortcut: 's',
  icon: 'mdi-cursor-pointer',
  helptext: `Select object by clicking it. Click a class label to edit the
  class label of the selected object. Drag the selected object to move it.
  Drag from the corner points to edit the object shape.
  Click a corner point and press <BACKSPACE> to remove it.
`
};

export const drawRectangle: ToolUIConnector = {
  build: (props: ToolProps) => new DrawRectangleTool(props, refineBoxIdentity),
  name: 'Box',
  shortcut: 'b',
  icon: 'mdi-checkbox-blank-outline',
  helptext: 'Draw a bounding box by clicking and dragging.'
};

export const drawRectangleWithSnap: ToolUIConnector = {
  build: (props: ToolProps) => new DrawRectangleTool(props, refineBox),
  name: 'Autofit',
  shortcut: 'n',
  icon: 'mdi-checkbox-intermediate',
  helptext: `Draw a bounding box by clicking and dragging around an object.
  Wait for the algorithm to reduce the size of the box to tightly fit around the object.
  This works best for objects clearly separated from their backgrounds.
 `
};

export const clickTool: ToolUIConnector = {
  build: (props: ToolProps) => {
    return new ClickTool({ props });
  },
  name: 'Click input',
  shortcut: 'c',
  icon: 'mdi-cursor-default-click-outline',
  helptext: `Click the outer bounds of the object to create a bounding box.
    Left mouse button adds clicks.
    Right button saves accumulated clicks and starts anew.
    <SHIFT>-right starts anew without saving.`
};

export const clickToolWithSnap: ToolUIConnector = {
  build: (props: ToolProps) => {
    return new ClickTool({ props, refineFunction: refineClicks });
  },
  name: 'Click snap',
  shortcut: 'x',
  icon: 'mdi-cursor-default-click',
  helptext: `Same as Click input, but uses an algorithm to improve the box.
    Left mouse button adds clicks. <SHIFT>-left adds negative clicks.
    Right button saves accumulated clicks and starts anew.
    <SHIFT>-right starts anew without saving.
`
};

export const polygonTool: ToolUIConnector = {
  build: (props: ToolProps) => {
    return new PolygonTool({ props });
  },
  name: 'Polygon',
  shortcut: 'o',
  icon: 'mdi-pentagon-outline',
  helptext: `Create polygon shapes by mouse clicks.
  Left mouse button adds clicks.
  Right button finishes the path by joining the first and last points.
  <SHIFT>-right starts anew without saving.
  <BACKSPACE> removes the last click.
  `
};

export const skeletonTool: ToolUIConnector = {
  build: (props: ToolProps) => makeSkeletonTool({ props }),
  name: 'Skeleton',
  shortcut: 'k',
  icon: 'mdi-human',
  helptext: `Create a skeleton by dragging left mouse button.

  To edit the skeleton, first select the object with Select tool and then drag keypoints.`
};

export const ANNOTATION_TOOLS: ToolUIConnector[] = [
  selectObject,
  drawRectangle,
  drawRectangleWithSnap,
  clickTool,
  clickToolWithSnap,
  polygonTool,
  skeletonTool
];

export function isSelectTool(tool: DrawerMouseTool): boolean {
  return tool instanceof SelectObjectTool;
}
