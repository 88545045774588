















































import _isEmpty from 'lodash/isEmpty';
import Vue, { PropType } from 'vue';
import { ImageAnnotation } from '../types';
import Tooltip from '@/components/molecules/Tooltip.vue';

type SubmitFunction = (annotation: ImageAnnotation) => void;

export default Vue.extend({
  props: {
    annotation: {
      required: true,
      type: Object as PropType<ImageAnnotation>
    },
    onSubmit: {
      required: true,
      type: Function as PropType<SubmitFunction>
    }
  },
  components: {
    Tooltip
  },
  computed: {
    description(): string | null {
      return this.annotation.context
        ? this.annotation.context.description
        : null;
    },
    descriptionChanged(): boolean {
      return this.description !== this.inputDescription;
    },
    hasContext(): boolean {
      return !!this.annotation.context && !_isEmpty(this.annotation.context);
    },
    inputValid(): boolean {
      return this.descriptionChanged;
    }
  },
  data: function() {
    return {
      showDialog: false as boolean,
      inputDescription: null as string | null
    };
  },
  methods: {
    submit(): void {
      const { context, ...annotation } = this.annotation;
      console.debug(
        `Submitting change in metadata for annotation ID ${this.annotation._id}`
      );
      this.onSubmit({
        ...annotation,
        ...(this.inputDescription
          ? { context: { description: this.inputDescription } }
          : {})
      });
      this.onClose();
    },
    onClose() {
      this.showDialog = false;
    }
  },
  watch: {
    showDialog: function() {
      if (!this.showDialog) {
        return;
      }
      this.inputDescription = this.description;
    }
  }
});
