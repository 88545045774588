












import paper from 'paper';
import { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import { whenever } from '@vueuse/core';
import {
  DrawerMouseTool,
  ANNOTATION_TOOLS
} from '@/components/ImageAnnotator/tools';
import ImageActionsToolbar from '@/components/ImageAnnotator/ImageActionsToolbar.vue';
import LoadingModal from '@/components/LoadingModal.vue';
import MouseWheel from '@/components/ImageAnnotator/MouseWheel.vue';
import { ImageAnnotation } from '@/components/ImageAnnotator/types';
import { useOpenCV } from '@/components/ImageAnnotator/functions';
import {
  useAttachEventHandlers,
  useBackgroundImage,
  useCreateNewAnnotation,
  useHitTest,
  useImageFx,
  useMask,
  useObjects,
  usePaperJS,
  useUpdateObjectsOnViewUpdate,
  useZoom,
  useUpdateLabelTextsOnViewUpdate
} from '@/components/ImageAnnotator/drawer';

import {
  DEFAULT_COLOR,
  ImageAnnotatorParameters,
  SkeletonGraphDefinition
} from '@/components/ImageAnnotator/config';
import { useVisualElementScaling } from '@/components/ImageAnnotator/scaling';
import { LabelShortcutHandler } from './utils';

export default defineComponent({
  name: 'ImageWorkingArea',
  components: {
    LoadingModal,
    ImageActionsToolbar,
    MouseWheel
  },
  props: {
    annotations: {
      required: true,
      type: Array as PropType<Array<ImageAnnotation>>
    },
    config: {
      required: true,
      type: Object as PropType<ImageAnnotatorParameters>
    },
    imageSrc: {
      required: true,
      type: String as PropType<string>
    },
    selectedAnnotationId: {
      required: false,
      type: String
    },
    selectedToolIndex: {
      required: false,
      type: Number
    },
    selectedLabel: {
      required: true,
      type: String
    },
    selectedSkeletonGraph: {
      required: false,
      type: Object as PropType<SkeletonGraphDefinition>
    },
    labelColors: {
      required: true,
      type: Object as PropType<Record<string, paper.Color>>
    },
    labelShortcutHandler: {
      required: true,
      type: Object as PropType<LabelShortcutHandler>
    }
  },
  setup(props, { emit, refs }) {
    const {
      annotations,
      selectedLabel,
      selectedSkeletonGraph,
      selectedToolIndex,
      selectedAnnotationId,
      labelColors,
      labelShortcutHandler,
      imageSrc,
      config
    } = toRefs(props);

    const canvas = ref(null) as Ref<HTMLCanvasElement>;

    const addAnnotation = (annotation: ImageAnnotation) => {
      emit('addAnnotation', annotation);
    };

    const updateAnnotation = (id: string, newValue: ImageAnnotation) => {
      emit('updateAnnotation', id, newValue);
    };

    const selectAnnotationId = (id: string | null) => {
      emit('update:selectedAnnotationId', id);
    };

    const { scope, imageLayer, drawLayer, maskLayer, purge, view } = usePaperJS(
      {
        canvas
      }
    );

    const { raster, bounds: imageBounds, imageElement } = useBackgroundImage({
      imageSrc,
      scope,
      layer: imageLayer
    });

    const zoom = useZoom({
      bounds: imageBounds,
      view
    });

    const { imageFx } = useImageFx({ raster });

    const { sizes } = useVisualElementScaling({
      registerViewUpdateListener: zoom.registerViewUpdateListener
    });

    const objects = useObjects({
      annotations,
      labelColors,
      selectedAnnotationId,
      imageBounds,
      updateAnnotation,
      raster,
      layer: drawLayer,
      view,
      sizes
    });

    useUpdateObjectsOnViewUpdate({
      containers: objects.containers,
      sizes,
      view
    });

    useUpdateLabelTextsOnViewUpdate({
      labelTexts: objects.labelTexts,
      sizes,
      view
    });

    const mask = useMask({
      scope,
      layer: maskLayer
    });

    const { hitTest } = useHitTest({ scope, hitTestObjects: objects.hitTest });

    const { createAnnotationFromPathContainer } = useCreateNewAnnotation({
      imageBounds,
      selectedLabel,
      addAnnotation,
      selectedSkeletonGraph
    });

    useAttachEventHandlers({
      bounds: imageBounds,
      containers: objects.containers,
      selectedAnnotationId,
      selectedToolIndex
    });

    const activeTool = ref(null) as Ref<DrawerMouseTool>;
    const cursor = ref(null) as Ref<string>;

    function setCursor(cursor_: string) {
      cursor.value = cursor_;
    }

    const { loadingOpenCV, openCVLoaded } = useOpenCV();

    function handleMouseWheel(event: WheelEvent) {
      const mousePosition = new paper.Point(event.offsetX, event.offsetY);
      const viewPosition = paper.view.viewToProject(mousePosition);

      if (event.deltaY > 0) {
        zoom.out(viewPosition);
      } else {
        zoom.in(viewPosition);
      }
    }

    function onKeyUp(event: paper.KeyEvent) {
      const char = event.character;

      switch (event.key) {
        // Next image
        case 'd':
          emit('onNextImage');
          return;
        // Previous image
        case 'a':
          emit('onPreviousImage');
          return;
        // Save
        case 'z':
          emit('save');
          return;
      }

      // Tool shortcuts
      ANNOTATION_TOOLS.forEach((annotationTool, index) => {
        if (annotationTool.shortcut == char) {
          console.log(`Switching to tool`, annotationTool);
          emit('update:selectedToolIndex', index);
          return;
        }
      });

      labelShortcutHandler.value.handle(event);
    }

    async function activateToolFromToolFactory() {
      if (activeTool.value) {
        activeTool.value.deactivate();
        cursor.value = 'auto';
      }
      if (selectedToolIndex.value == null) {
        console.warn(`No selected tool, skipping activating`);
        return;
      }

      console.log(`Activating tool`, ANNOTATION_TOOLS[selectedToolIndex.value]);

      function activeClassColor(): paper.Color {
        const label = selectedLabel.value;

        return (
          (label && labelColors.value[label]) || new paper.Color(DEFAULT_COLOR)
        );
      }

      const toolProps = {
        objects,
        zoom,
        mask,
        sizes,
        selectedAnnotationId,
        selectAnnotationId,
        hitTest,
        setCursor,
        onKeyUp: (event: paper.KeyEvent) => onKeyUp(event),
        activeClassColor,
        imageBounds: imageBounds.value,
        imageElement: imageElement.value,
        createAnnotation: createAnnotationFromPathContainer,
        skeletonGraph: selectedSkeletonGraph
      };

      activeTool.value = ANNOTATION_TOOLS[selectedToolIndex.value].build(
        toolProps
      );
    }

    function contextMenuHandler(e) {
      if (e.button == 2) {
        e.preventDefault();
        return true;
      }
    }

    async function initialize(): Promise<void> {
      console.log(`Purging existing drawer`);
      purge();

      canvas.value = refs.myCanvas as HTMLCanvasElement;
      canvas.value.addEventListener('contextmenu', contextMenuHandler);
    }

    whenever(raster, activateToolFromToolFactory);

    const loading = computed(() => loadingOpenCV.value);

    const canvasStyle = computed(() => ({ cursor: cursor.value }));

    function fitViewToImage() {
      zoom.fitViewToImage();
    }

    onBeforeUnmount(function() {
      canvas.value?.removeEventListener('contextmenu', contextMenuHandler);
    });

    onMounted(initialize);

    onBeforeUnmount(function() {
      activeTool.value?.deactivate();

      console.log(`Destroying drawer`);
      purge();
    });

    watch(imageSrc, initialize);

    watch(selectedToolIndex, function(newValue) {
      console.debug(`Activating new tool ${ANNOTATION_TOOLS[newValue].name}`);
      activateToolFromToolFactory();
    });

    return {
      canvas,
      activeTool,
      cursor,
      loadingOpenCV,
      loading,
      openCVLoaded,
      handleMouseWheel,
      canvasStyle,
      fitViewToImage,
      imageFx,
      containers: objects.containers,
      sizes
    };
  }
});
