import paper from 'paper';
import { PolygonPathContainer } from '@/components/ImageAnnotator/annotation-container';
import {
  DrawerMouseTool,
  createPaperTool,
  ToolProps
} from '@/components/ImageAnnotator/tools';

const MIN_DRAG_DISTANCE_POINTS = 1;
const CLICK_SCREEN_RADIUS = 3;

const MOUSEBUTTON_LEFT = 0;
const MOUSEBUTTON_RIGHT = 2;

export class PolygonTool implements DrawerMouseTool {
  private readonly props: ToolProps;
  private paperTool: paper.Tool;
  private temporaryPath?: paper.Path;
  private temporaryEdge?: paper.Path;
  private clickPoints = [] as paper.Point[];
  private screenPoints = [] as paper.Shape[];

  constructor({ props }: { props: ToolProps }) {
    this.props = props;
    this.paperTool = this.setupPaperTool();
  }

  private get cursor() {
    return 'crosshair';
  }

  deactivate() {
    this.resetClicks();
    this.paperTool.remove();
  }

  private setupPaperTool() {
    const tool = createPaperTool({
      onMouseUp: ev => this.onMouseUp(ev),
      onMouseDown: ev => this.onMouseDown(ev),
      onMouseMove: ev => this.onMouseMove(ev),
      onKeyUp: ev => this.onKeyUp(ev),
      props: this.props,
      cursor: this.cursor
    });
    tool.minDistance = MIN_DRAG_DISTANCE_POINTS;
    return tool;
  }

  private onMouseDown(event: paper.MouseEvent) {
    if (this.props.imageBounds.contains(event.point)) {
      // eslint-disable-next-line
      if ((event as any).event.button === MOUSEBUTTON_LEFT) {
        this.addClick(event.point);
      }
    }
  }

  private addClick(point: paper.Point) {
    this.clickPoints.push(point);
    const c = new paper.Shape.Circle(point, CLICK_SCREEN_RADIUS);
    c.strokeColor = new paper.Color(0);
    c.fillColor = new paper.Color(1);
    this.screenPoints.push(c);

    this.temporaryEdge?.remove();
    this.temporaryEdge = null;

    this.refreshScreenElements();
  }

  private removePreviousClick() {
    if (this.clickPoints.length > 0) {
      this.clickPoints.pop();
      this.screenPoints.pop().remove();
      this.temporaryEdge?.remove();
      this.temporaryEdge = null;
      this.refreshScreenElements();
    }
  }

  private resetClicks() {
    this.temporaryPath?.remove();
    this.temporaryPath = null;

    this.temporaryEdge?.remove();
    this.temporaryEdge = null;

    this.screenPoints.forEach(c => c.remove());
    this.screenPoints = [];
    this.clickPoints = [];
  }

  private async extractAnnotationFromClicks() {
    if (this.clickPoints.length === 0) {
      return;
    }

    this.refreshScreenElements();

    if (!this.temporaryPath) {
      return;
    }

    const stroke = this.props.activeClassColor();
    const transparent = new paper.Color(
      stroke.red,
      stroke.green,
      stroke.blue,
      0.1
    );
    this.temporaryPath.fillColor = transparent;
    this.temporaryPath.strokeColor = stroke;

    const pathContainer = PolygonPathContainer.createFromPath(
      this.temporaryPath.clone()
    );
    this.props.createAnnotation({ container: pathContainer });
  }

  private onMouseMove(event: paper.MouseEvent) {
    if (
      this.clickPoints.length > 0 &&
      this.props.imageBounds.contains(event.point)
    ) {
      if (this.temporaryEdge) {
        this.temporaryEdge.segments.slice(-1)[0].point = event.point;
      } else {
        this.temporaryEdge = new paper.Path.Line(
          this.clickPoints.slice(-1)[0],
          event.point
        );
        const stroke = this.props.activeClassColor();
        this.temporaryEdge.strokeColor = stroke;
      }
    }
    this.updateCursor();
  }

  private updateCursor() {
    this.props.setCursor?.(this.cursor);
  }

  private refreshScreenElements() {
    this.temporaryPath?.remove();
    this.temporaryPath = new paper.Path(this.clickPoints);
    const stroke = this.props.activeClassColor();
    this.temporaryPath.strokeColor = stroke;
  }

  private onMouseUp(event: paper.MouseEvent) {
    // eslint-disable-next-line
    if ((event as any).event.button === MOUSEBUTTON_RIGHT) {
      if (!event.modifiers.shift) {
        this.extractAnnotationFromClicks();
      }
      this.resetClicks();
    }
  }

  private onKeyUp(event: paper.KeyEvent) {
    switch (event.key) {
      case 'backspace':
        this.removePreviousClick();
    }
  }
}
