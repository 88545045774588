/** Scaling of visual elements like circles and label texts */

import { computed, Ref, ref } from '@vue/composition-api';
import _clamp from 'lodash/clamp';

export interface VisualElementSizes {
  fontSize: number;
  circleRadius: number;
  circleStrokeWidth: number;
}

export const baseSizes: VisualElementSizes = {
  circleRadius: 4,
  fontSize: 14,
  circleStrokeWidth: 8 / 5.0
};

export const sizeThresholds = {
  circleRadius: 20,
  fontSize: 50
};

/**
 * Desired scale and size of visual elements such
 * as fontsize and circle radius. Scale is updated
 * when the view updates.
 */
export function useVisualElementScaling({
  registerViewUpdateListener
}: {
  registerViewUpdateListener: (
    fn: ({ view }: { view: paper.View }) => void
  ) => void;
}): { scale: Ref<number>; sizes: Ref<VisualElementSizes> } {
  // Overall scale, used to multiply baseSizes
  const scale = ref(1);

  function update({ view }: { view: paper.View }) {
    const viewSize = Math.min(view.size.height, view.size.width);
    scale.value = viewSize / 1000.0;
  }

  const sizes = computed(() => {
    const circleRadius = Math.min(
      Math.ceil(baseSizes.circleRadius * scale.value),
      sizeThresholds.fontSize
    );
    const fontSize = Math.min(
      Math.ceil(baseSizes.fontSize * scale.value),
      sizeThresholds.fontSize
    );

    return {
      fontSize,
      circleRadius,
      circleStrokeWidth: circleRadius / 5.0
    };
  });

  registerViewUpdateListener(update);

  return { scale, sizes };
}

export type VisualElementScaling = ReturnType<typeof useVisualElementScaling>;
