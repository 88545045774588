import paper from 'paper';
import _indexOf from 'lodash/indexOf';
import _range from 'lodash/range';
import {
  Box,
  ImageAnnotationGeometry,
  PaperMouseEvent,
  Polygon,
  Skeleton,
  SkeletonNode
} from '@/components/ImageAnnotator/types';
import { SkeletonHelper } from '@/components/ImageAnnotator/skeleton/skeleton-helper';
import { VisualElementSizes } from '@/components/ImageAnnotator/scaling';
import { SkeletonGraphDefinition } from '@/components/ImageAnnotator/config';
import { isPanning } from '@/components/ImageAnnotator/utils';

export type AnnotationContainerKind = 'Box' | 'Polygon' | 'Skeleton';

/**
 * Wrapper for annotation object drawn on image.
 * */
export interface AnnotationContainer {
  readonly kind: AnnotationContainerKind;
  readonly labelId?: string;
  readonly path?: paper.Path;
  readonly bounds: paper.Rectangle;
  remove(): void;
  moveSegment?(
    segment: paper.Segment,
    delta: paper.Point,
    bounds: paper.Rectangle
  ): void;
  move?(delta: paper.Point, bounds: paper.Rectangle): void;
  hitTest(point: paper.Point, options): paper.HitResult | null;
  // Event handlers
  attachEventHandlers?({ bounds }: { bounds: paper.Rectangle }): void;
  detachEventHandlers?(): void;
  // Selections
  readonly selected: boolean;
  select(): void;
  deselect(): void;
  onVisualElementScaleUpdate?({
    view,
    sizes
  }: {
    view: paper.View;
    sizes: VisualElementSizes;
  }): void;
}

export class SkeletonContainer implements AnnotationContainer {
  public readonly labelId?: string;
  readonly kind = 'Skeleton';
  private readonly helper: SkeletonHelper;

  constructor({
    labelId,
    helper
  }: {
    labelId?: string;
    helper: SkeletonHelper;
  }) {
    this.labelId = labelId;
    this.helper = helper;
  }

  public get nodes(): SkeletonNode[] {
    return this.helper.nodes;
  }

  public get graph(): SkeletonGraphDefinition {
    return this.helper.graph;
  }

  remove(): void {
    this.helper.remove();
  }

  get bounds(): paper.Rectangle {
    return this.helper.bounds;
  }

  get selected(): boolean {
    return this.helper.selected;
  }

  select() {
    this.helper.select();
  }

  deselect() {
    this.helper.deselect();
  }

  attachEventHandlers({ bounds }: { bounds: paper.Rectangle }): void {
    this.helper.eventHandler.attach({ bounds });
    this.helper.bringToFront();
  }

  detachEventHandlers(): void {
    this.helper.eventHandler.detach();
  }

  hitTest(point: paper.Point, options) {
    return this.helper.hitTest(point, options);
  }

  onVisualElementScaleUpdate({
    sizes,
    view
  }: {
    sizes: VisualElementSizes;
    view: paper.View;
  }): void {
    this.helper.onVisualElementScaleUpdate({ sizes });
  }

  static fromSkeletonHelper(skeleton: SkeletonHelper): SkeletonContainer {
    const helper = skeleton;

    return new SkeletonContainer({ helper });
  }

  static tryToCreateFromGeometry({
    labelId,
    geometry,
    sizes
  }: {
    labelId: string;
    geometry: ImageAnnotationGeometry;
    sizes: VisualElementSizes;
  }): SkeletonContainer | null {
    if (!(geometry instanceof Skeleton)) {
      return null;
    }
    const helper = SkeletonHelper.fromNodes({
      nodes: geometry.nodes,
      graph: geometry.graph,
      sizes
    });
    return new SkeletonContainer({ labelId, helper });
  }
}

const NODE_COLOR = '#FCFCFC';
const STROKE_COLOR = '#2c6dcb';

/** Helper class for managing circles drawn on polygon points */
class PolygonNodes {
  private readonly path: paper.Path;
  private circles: paper.Group = new paper.Group();
  private selected = false;
  // Size of circles when not selected
  private static BASE_CIRCLE_RADIUS = 2;
  private static BASE_CIRCLE_STROKE_WIDTH = 0;

  constructor({ path }: { path: paper.Path }) {
    this.path = path;
  }

  public remove() {
    this.circles?.remove();
    this.circles = new paper.Group();
  }

  public bringToFront(): void {
    this.circles.bringToFront();
  }

  public moveNode(
    nodeIndex: number,
    delta: paper.Point,
    bounds: paper.Rectangle
  ) {
    const circle = this.circles.children[nodeIndex];
    const p = circle.position;
    // Ensure circle remains within bounds
    const delta_ = delta.clone();
    delta_.x = Math.max(-p.x, Math.min(bounds.right - p.x, delta.x));
    delta_.y = Math.max(-p.y, Math.min(bounds.bottom - p.y, delta.y));

    circle.position.x += delta_.x;
    circle.position.y += delta_.y;
  }

  /** Render circles for polygon points */
  public show() {
    this.remove();
    const pathPoints = this.path.segments.map(p => p.point);

    let i = 0;
    for (const p of pathPoints) {
      const circle = new paper.Shape.Circle({
        center: new paper.Point(p.x, p.y),
        radius: PolygonNodes.BASE_CIRCLE_RADIUS
      });

      circle.name = `Polygon node ${i}`;
      circle.fillColor = new paper.Color(NODE_COLOR);
      circle.strokeColor = new paper.Color(STROKE_COLOR);
      circle.strokeWidth = PolygonNodes.BASE_CIRCLE_STROKE_WIDTH;
      this.circles.addChild(circle);
      i++;
    }
  }

  public scaleToView(sizes: VisualElementSizes) {
    if (this.selected) {
      for (const c of this.circles.children) {
        (c as paper.Shape.Circle).radius = sizes.circleRadius;
        (c as paper.Shape.Circle).strokeWidth = sizes.circleStrokeWidth;
      }
    }
  }

  public select(sizes: VisualElementSizes) {
    this.selected = true;
    for (const c of this.circles.children) {
      (c as paper.Shape.Circle).radius = sizes.circleRadius;
      (c as paper.Shape.Circle).strokeWidth = sizes.circleStrokeWidth;
    }
  }

  public deselect() {
    this.selected = false;
    for (const c of this.circles.children) {
      (c as paper.Shape.Circle).radius = PolygonNodes.BASE_CIRCLE_RADIUS;
      (c as paper.Shape.Circle).strokeWidth =
        PolygonNodes.BASE_CIRCLE_STROKE_WIDTH;
    }
  }

  public highlight(index: number) {
    const circle = this.circles.children[index];
    (circle as paper.Shape.Circle).radius = PolygonNodes.BASE_CIRCLE_RADIUS * 3;
    (circle as paper.Shape.Circle).strokeWidth =
      PolygonNodes.BASE_CIRCLE_STROKE_WIDTH * 3;
  }

  public removeHighlighting() {
    for (const c of this.circles.children) {
      (c as paper.Shape.Circle).radius =
        PolygonNodes.BASE_CIRCLE_RADIUS;
      (c as paper.Shape.Circle).strokeWidth =
        PolygonNodes.BASE_CIRCLE_STROKE_WIDTH;
    }
  }

  get children(): paper.Shape.Circle[] {
    return this.circles.children as paper.Shape.Circle[];
  }

  public hitTest(point: paper.Point, options: any) {
    return this.circles.hitTest(point, options);
  }
}

/** Helper class for managing event handlers on polygon points */
class PolygonEventHandler {
  private readonly polygon: PolygonPathContainer;

  constructor({ polygon }: { polygon: PolygonPathContainer }) {
    this.polygon = polygon;
  }

  public attach({ bounds }: { bounds: paper.Rectangle }) {
    console.debug(`Attaching event handlers to polygon points`);
    for (const item of this.polygon.children) {
      item.onMouseDrag = (ev: PaperMouseEvent) => {
        if (isPanning(ev)) {
          return;
        }
        const index = _indexOf(this.polygon.children, item);
        this.polygon.moveNode(index, ev.delta, bounds);
      };
    }
    const path = this.polygon.path;
    path.onMouseDrag = (ev: PaperMouseEvent) => {
      if (isPanning(ev)) {
        return;
      }
      this.polygon.move(ev.delta, bounds);
    };
  }

  public detach() {
    console.debug(`Detaching event handlers from polygon`);
    for (const item of this.polygon.children) {
      item.onMouseUp = null;
      item.onMouseDrag = null;
      item.onMouseDown = null;
    }
    this.polygon.path.onMouseDrag = null;
  }
}

export class PolygonPathContainer {
  public readonly labelId?: string;
  readonly kind = 'Polygon';
  public readonly path: paper.Path;
  public selected: boolean;
  public readonly nodes: PolygonNodes;
  private readonly eventHandler: PolygonEventHandler;
  private sizes?: VisualElementSizes;
  private temporaryPath?: paper.Path;

  constructor({
    labelId,
    path,
    sizes
  }: {
    labelId?: string;
    path: paper.Path;
    sizes?: VisualElementSizes;
  }) {
    this.path = path;
    this.labelId = labelId;
    this.nodes = new PolygonNodes({ path });
    this.eventHandler = new PolygonEventHandler({ polygon: this });
    this.nodes.show();
    this.sizes = sizes;
  }

  attachEventHandlers({ bounds }: { bounds: paper.Rectangle }): void {
    this.eventHandler.attach({ bounds });
    this.path.bringToFront();
    this.nodes.bringToFront();
  }

  detachEventHandlers(): void {
    this.eventHandler.detach();
  }

  public moveNode(
    nodeIndex: number,
    delta: paper.Point,
    bounds: paper.Rectangle
  ) {
    const segment = this.path.segments[nodeIndex];
    const p = segment.point;
    // Ensure point remains within image bounds
    const delta_ = delta.clone();
    delta_.x = Math.max(-p.x, Math.min(bounds.right - p.x, delta.x));
    delta_.y = Math.max(-p.y, Math.min(bounds.bottom - p.y, delta.y));
    p.x += delta_.x;
    p.y += delta_.y;

    this.nodes.moveNode(nodeIndex, delta, bounds);
  }

  public move(delta: paper.Point, bounds: paper.Rectangle) {
    // Ensure path bounds remain within image bounds
    const pathBounds = this.path.bounds;
    const delta_ = delta.clone();
    delta_.x = Math.max(
      -(pathBounds.left - bounds.left),
      Math.min(bounds.right - pathBounds.right, delta.x)
    );
    delta_.y = Math.max(
      -(pathBounds.top - bounds.top),
      Math.min(bounds.bottom - pathBounds.bottom, delta.y)
    );

    for (const nodeIndex of _range(this.path.segments.length)) {
      this.moveNode(nodeIndex, delta_, bounds);
    }
  }

  get bounds() {
    return this.path.bounds;
  }

  remove(): void {
    this.eventHandler.detach();
    this.nodes.remove();
    this.path.remove();
  }

  select() {
    this.nodes.select(this.sizes);
    this.selected = true;
  }

  deselect() {
    this.nodes.deselect();
    this.selected = false;
  }

  public highlightNode(index: number) {
    this.nodes.highlight(index);
  }

  public removeNodeHighlighting() {
    this.nodes.removeHighlighting();
  }

  get children(): paper.Shape.Circle[] {
    return this.nodes.children;
  }

  hitTest(point: paper.Point, options: any) {
    return (
      this.nodes.hitTest(point, options) || this.path.hitTest(point, options)
    );
  }

  onVisualElementScaleUpdate({ sizes }: { sizes: VisualElementSizes }) {
    this.sizes = sizes;
    this.nodes.scaleToView(sizes);
  }

  static createFromPath(path: paper.Path): PolygonPathContainer {
    path.closed = true;
    return new PolygonPathContainer({ path });
  }

  static tryToCreateFromGeometry({
    labelId,
    geometry,
    sizes
  }: {
    labelId: string;
    geometry: ImageAnnotationGeometry;
    sizes: VisualElementSizes;
  }): PolygonPathContainer | null {
    if (!(geometry instanceof Polygon)) {
      return null;
    }
    const path = new paper.Path();
    path.segments = geometry.points.map(point => new paper.Segment(point));
    path.closed = true;
    return new PolygonPathContainer({ labelId, path, sizes });
  }

  public visualiseNewNode(point: paper.Point): void {
    this.temporaryPath?.remove();
    const nearestPoint = this.path.getNearestPoint(point);

    const hitResult = this.hitTest(nearestPoint, null);

    if (!hitResult.location) {
      return;
    }

    const index = hitResult.location.curve.index;
    const segments = this.path.segments;

    const newSegments = [
      new paper.Point(segments[index].point),
      new paper.Point(point),
      new paper.Point(segments[index].next.point)
    ];
    this.temporaryPath = new paper.Path(newSegments);
    const stroke = new paper.Color(STROKE_COLOR);
    this.temporaryPath.strokeColor = stroke;
    this.temporaryPath.fillColor = new paper.Color(
      stroke.red,
      stroke.green,
      stroke.blue,
      0.1
    );
  }

  public removeNewNodeVisualisation(): void {
    this.temporaryPath?.remove();
  }

  public addNewNode(point: paper.Point): void {
    const nearestPoint = this.path.getNearestPoint(point);
    const hitResult = this.hitTest(nearestPoint, { stroke: true });

    if (hitResult.location) {
      const index = hitResult.location.curve.next.index;
      this.path.insert(index, point);
    }
  }
}

class BoundingBoxNodes {
  private readonly path: paper.Path;
  private circles: paper.Group = new paper.Group();
  private selected = false;
  // Size of circles when not selected
  private static BASE_CIRCLE_RADIUS = 2;
  private static BASE_CIRCLE_STROKE_WIDTH = 0;

  constructor({ path }: { path: paper.Path }) {
    this.path = path;
  }

  public remove() {
    this.circles.remove();
    this.circles = new paper.Group();
  }

  public bringToFront(): void {
    this.circles.bringToFront();
  }

  public updateNodePositions() {
    this.path.segments.forEach(segment => {
      this.circles.children[segment.index].position.x = segment.point.x;
      this.circles.children[segment.index].position.y = segment.point.y;
    });
  }

  /** Render circles for bounding box points */
  public show() {
    this.remove();
    const pathPoints = this.path.segments.map(p => p.point);

    let i = 0;
    for (const p of pathPoints) {
      const circle = new paper.Shape.Circle({
        center: new paper.Point(p.x, p.y),
        radius: BoundingBoxNodes.BASE_CIRCLE_RADIUS
      });

      circle.name = `Bounding Box node ${i}`;
      circle.fillColor = new paper.Color(NODE_COLOR);
      circle.strokeColor = new paper.Color(STROKE_COLOR);
      circle.strokeWidth = BoundingBoxNodes.BASE_CIRCLE_STROKE_WIDTH;
      this.circles.addChild(circle);
      i++;
    }
  }

  public scaleToView(sizes: VisualElementSizes) {
    if (this.selected) {
      for (const c of this.circles.children) {
        (c as paper.Shape.Circle).radius = sizes.circleRadius;
        (c as paper.Shape.Circle).strokeWidth = sizes.circleStrokeWidth;
      }
    }
  }

  public select(sizes: VisualElementSizes) {
    this.selected = true;
    for (const c of this.circles.children) {
      (c as paper.Shape.Circle).radius = sizes.circleRadius;
      (c as paper.Shape.Circle).strokeWidth = sizes.circleStrokeWidth;
    }
  }

  public deselect() {
    this.selected = false;
    for (const c of this.circles.children) {
      (c as paper.Shape.Circle).radius = BoundingBoxNodes.BASE_CIRCLE_RADIUS;
      (c as paper.Shape.Circle).strokeWidth =
        BoundingBoxNodes.BASE_CIRCLE_STROKE_WIDTH;
    }
  }

  get children(): paper.Shape.Circle[] {
    return this.circles.children as paper.Shape.Circle[];
  }

  public hitTest(point: paper.Point, options: any) {
    return this.circles.hitTest(point, options);
  }
}

class BoundingBoxEventHandler {
  private readonly boundingBox: BoundingBoxPathContainer;

  constructor({ boundingBox }: { boundingBox: BoundingBoxPathContainer }) {
    this.boundingBox = boundingBox;
  }

  public attach({ bounds }: { bounds: paper.Rectangle }) {
    console.debug(`Attaching event handlers bounding box path`);
    this.boundingBox.path.onMouseDrag = (ev: PaperMouseEvent) => {
      if (isPanning(ev)) {
        return;
      }
      this.boundingBox.move(ev.delta, bounds);
    };

    console.debug(`Attaching event handlers bounding box points`);
    for (const item of this.boundingBox.children) {
      item.onMouseDrag = (ev: PaperMouseEvent) => {
        if (isPanning(ev)) {
          return;
        }
        const index = _indexOf(this.boundingBox.children, item);
        this.boundingBox.moveNode(index, ev.delta, bounds);
      };
    }
  }

  public detach() {
    console.debug(`Detaching event handlers from bounding box`);
    for (const item of this.boundingBox.children) {
      item.onMouseDrag = null;
    }

    console.debug(`Detaching event handlers from bounding box path`);
    this.boundingBox.path.onMouseDrag = null;
  }
}

export class BoundingBoxPathContainer implements AnnotationContainer {
  public readonly labelId?: string;
  readonly kind = 'Box';
  public readonly path: paper.Path;
  public selected: boolean;
  public readonly nodes: BoundingBoxNodes;
  private readonly eventHandler: BoundingBoxEventHandler;
  private sizes?: VisualElementSizes;

  constructor({
    labelId,
    path,
    sizes
  }: {
    labelId?: string;
    path: paper.Path;
    sizes?: VisualElementSizes;
  }) {
    this.path = path;
    this.labelId = labelId;
    this.nodes = new BoundingBoxNodes({ path });
    this.eventHandler = new BoundingBoxEventHandler({ boundingBox: this });
    this.nodes.show();
    this.sizes = sizes;
  }

  attachEventHandlers({ bounds }: { bounds: paper.Rectangle }): void {
    this.eventHandler.attach({ bounds });
    this.path.bringToFront();
    this.nodes.bringToFront();
  }

  detachEventHandlers(): void {
    this.eventHandler.detach();
  }

  move(delta: paper.Point, bounds: paper.Rectangle): void {
    const segments = this.path.segments;
    segments.forEach(segment => {
      const p = segment.point;
      delta.x = Math.max(-p.x, Math.min(bounds.right - p.x, delta.x));
      delta.y = Math.max(-p.y, Math.min(bounds.bottom - p.y, delta.y));
    });
    segments.forEach(segment => {
      segment.point.x += delta.x;
      segment.point.y += delta.y;
    });
    this.nodes.updateNodePositions();
  }

  public moveNode(
    nodeIndex: number,
    delta: paper.Point,
    bounds: paper.Rectangle
  ) {
    const segment = this.path.segments[nodeIndex];
    const segmentIndex = _indexOf(this.path.segments, segment);

    if (segmentIndex < 0) {
      console.log(`Trying to move a segment not in rectangle path`);
      return;
    }

    const p = segment.point;
    delta.x = Math.max(-p.x, Math.min(bounds.right - p.x, delta.x));
    delta.y = Math.max(-p.y, Math.min(bounds.bottom - p.y, delta.y));

    let movingIdx: { x: number[]; y: number[] } = { x: [], y: [] };

    switch (segmentIndex) {
      case 0:
        movingIdx = { x: [0, 6], y: [0, 2] };
        break;
      case 1:
        movingIdx = { x: [], y: [0, 2] };
        break;
      case 2:
        movingIdx = { x: [2, 4], y: [0, 2] };
        break;
      case 3:
        movingIdx = { x: [2, 4], y: [] };
        break;
      case 4:
        movingIdx = { x: [2, 4], y: [4, 6] };
        break;
      case 5:
        movingIdx = { x: [], y: [4, 6] };
        break;
      case 6:
        movingIdx = { x: [0, 6], y: [4, 6] };
        break;
      case 7:
        movingIdx = { x: [0, 6], y: [] };
        break;
    }

    movingIdx.x.forEach(idx => {
      this.path.segments[idx].point.x += delta.x;
    });

    movingIdx.y.forEach(idx => {
      this.path.segments[idx].point.y += delta.y;
    });

    this.nodes.updateNodePositions();
    this.refreshMidpointProperty();
  }

  private refreshMidpointProperty() {
    // enforces the property that the coordinates of
    // odd-indexed segments (midpoints of bounding box edges)
    // are averages of the neighbouring even indexed segments
    // (=the bounding box corners)

    // assumes that the wrapped path contains exactly eight segments
    // if not, error message is printed to log

    if (this.path.segments.length != 8) {
      throw Error(
        'Error in refreshMidpointProperty: path must contain 8 segments'
      );
    }

    for (let i = 0; i < 4; i++) {
      const ii = 2 * i + 1;
      const pPrev = this.path.segments[ii - 1].point;
      const pNext = this.path.segments[(ii + 1) % 8].point;
      this.path.segments[ii].point.x = (pPrev.x + pNext.x) / 2;
      this.path.segments[ii].point.y = (pPrev.y + pNext.y) / 2;
    }
  }

  get bounds() {
    return this.path.bounds;
  }

  remove(): void {
    this.eventHandler.detach();
    this.nodes.remove();
    this.path.remove();
  }

  select() {
    this.nodes.select(this.sizes);
    this.selected = true;
  }

  deselect() {
    this.nodes.deselect();
    this.selected = false;
  }

  get children(): paper.Shape.Circle[] {
    return this.nodes.children;
  }

  hitTest(point: paper.Point, options: any) {
    return (
      this.nodes.hitTest(point, options) || this.path.hitTest(point, options)
    );
  }

  onVisualElementScaleUpdate({ sizes }: { sizes: VisualElementSizes }) {
    this.sizes = sizes;
    this.nodes.scaleToView(sizes);
  }

  static createFromPath(path: paper.Path): BoundingBoxPathContainer {
    path.closed = true;
    return new BoundingBoxPathContainer({ path });
  }

  static tryToCreateFromGeometry({
    labelId,
    geometry,
    sizes
  }: {
    labelId: string;
    geometry: ImageAnnotationGeometry;
    sizes: VisualElementSizes;
  }): BoundingBoxPathContainer | null {
    if (!(geometry instanceof Box)) {
      return null;
    }
    const path = new paper.Path();
    const rectangleSegmentPoints = [
      geometry.rectangle.topLeft,
      geometry.rectangle.topCenter,
      geometry.rectangle.topRight,
      geometry.rectangle.rightCenter,
      geometry.rectangle.bottomRight,
      geometry.rectangle.bottomCenter,
      geometry.rectangle.bottomLeft,
      geometry.rectangle.leftCenter
    ] as paper.Point[];

    path.segments = rectangleSegmentPoints.map(
      point => new paper.Segment(point)
    );
    path.closed = true;
    return new BoundingBoxPathContainer({ labelId, path, sizes });
  }
}

export type AnnotationContainerUnion =
  | PolygonPathContainer
  | SkeletonContainer
  | BoundingBoxPathContainer;

/**
 * Initialize AnnotationPathContainer from geometry, in PIXEL COORDINATES.
 *
 * This MUST be implemented for every new path container type.
 */
export function annotationPathContainerFromGeometry({
  labelId,
  geometry,
  sizes
}: {
  labelId: string;
  geometry: ImageAnnotationGeometry;
  sizes: VisualElementSizes;
}): AnnotationContainer {
  if (geometry.kind == 'Box') {
    return BoundingBoxPathContainer.tryToCreateFromGeometry({
      labelId,
      geometry,
      sizes
    });
  } else if (geometry.kind == 'Polygon') {
    return PolygonPathContainer.tryToCreateFromGeometry({
      labelId,
      geometry,
      sizes
    });
  } else if (geometry.kind == 'Skeleton') {
    return SkeletonContainer.tryToCreateFromGeometry({
      labelId,
      geometry,
      sizes
    });
  } else {
    throw new Error(
      `Cannot initialize annotation container from unknown geometry`
    );
  }
}

/**
 * Make ImageAnnotationGeometry in **pixel coordinates**.
 * The output is NOT in relative coordinates.
 */
export function pixelGeometryFromAnnotationPathContainer(
  container: AnnotationContainer
): ImageAnnotationGeometry {
  if (container instanceof BoundingBoxPathContainer) {
    return Box.fromPaperRectangle(container.path.bounds);
  } else if (container instanceof PolygonPathContainer) {
    return new Polygon(container.path.segments.map(segment => segment.point));
  } else if (container instanceof SkeletonContainer) {
    return new Skeleton({
      nodes: container.nodes,
      graph: container.graph
    });
  }

  throw new Error(
    `Unknown type of annotation path container: ${container.constructor.name}`
  );
}
