


































import Vue, { PropType } from 'vue';
import { AnnotationGuideline } from '@/types';

export default Vue.extend({
  name: 'ImageTaskInfoCard',
  components: {},
  props: {
    assetAmount: { type: Number, required: true },
    annotationAmount: { type: Number, required: false },
    guideline: {
      required: false,
      type: Object as PropType<AnnotationGuideline>
    }
  },
  computed: {
    progressPercentage(): number {
      return (this.annotationAmount * 100.0) / this.assetAmount;
    },
    progressText(): string {
      const SIGNIFICANT_DIGIT_COUNT = 1;
      const rounded = this.progressPercentage.toFixed(SIGNIFICANT_DIGIT_COUNT);

      // the regexp trims the trailing zeros after the decimal point
      // as well as the point itself if no digits would remain in the fractional part
      const shortened = rounded.replace(/(?:\.0+|(\.\d+?)0+)$/, '$1');
      return `${shortened}%`;
    }
  }
});
