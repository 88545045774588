import { render, staticRenderFns } from "./ImageAnnotationsList.vue?vue&type=template&id=7e8fbbf6&"
import script from "./ImageAnnotationsList.vue?vue&type=script&lang=ts&"
export * from "./ImageAnnotationsList.vue?vue&type=script&lang=ts&"
import style0 from "./ImageAnnotationsList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VContainer,VDivider,VForm,VIcon,VList,VSpacer,VSubheader,VTextField})
