




















































































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  Ref,
  ref,
  toRefs,
  watch,
  PropType
} from '@vue/composition-api';
import { whenever } from '@vueuse/core';
import 'splitpanes/dist/splitpanes.css';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';

import { AnnotationGuideline, Asset } from '@/types';
import WorkingArea from '@/components/ImageAnnotator/ImageWorkingArea.vue';
import NavigationPanel from '@/components/ImageAnnotator/NavigationPanel.vue';
import ImageAnnotationsList from '@/components/ImageAnnotator/ImageAnnotationsList.vue';
import LabelsToolbar from '@/components/ImageAnnotator/LabelsToolbar.vue';
import AnnotationToolbarV2 from '@/components/ImageAnnotator/AnnotationToolbarV2.vue';
import TaskInfoComponent from '@/components/ImageAnnotator/ImageTaskInfoCard.vue';
import ImageAnnotationViewportControl from '@/components/ImageAnnotator/ImageAnnotationViewportControl.vue';
import {
  ImageAnnotation,
  AnnotationStatus,
  SaveImageAnnotations,
  ImageAnnotationGroup,
  SaveState
} from '@/components/ImageAnnotator/types';
import {
  getLabelName,
  deepCopyLabels,
  getLabelNamesFromConfig,
  getLabelColorsFromConfig,
  useLabelShortcutHandler
} from '@/components/ImageAnnotator/utils';
import { UserSelections } from '@/components/ImageAnnotator/types';
import { createAnnotationStore } from '@/components/ImageAnnotator/image-annotator-store';
import { SaveStatusStrings } from '@/components/functions';
import SaveStatusPanel from '@/components/ImageAnnotator/SaveStatusPanel.vue';
import { ImageAnnotatorParameters } from '@/components/ImageAnnotator/config';
import { GenericTaskObject } from '@/layers';

const DEBOUNCE_WAIT_MS = 10000;

export default defineComponent({
  name: 'ImageAnnotationView',
  components: {
    TaskInfoComponent,
    WorkingArea,
    LabelsToolbar,
    NavigationPanel,
    AnnotationToolbarV2,
    ImageAnnotationsList,
    SaveStatusPanel,
    ImageAnnotationViewportControl
  },
  props: {
    asset: {
      required: true,
      type: Object as PropType<Asset>
    },
    onClickNext: {
      required: true,
      type: Function
    },
    onClickPrevious: {
      required: true,
      type: Function
    },
    imageSrc: {
      required: true,
      type: String as PropType<string>
    },
    imageAnnotationsFromServer: {
      type: Array as PropType<Array<ImageAnnotation>>,
      required: true
    },
    labelsInMemory: {
      type: Array as PropType<Array<ImageAnnotation>>,
      required: true
    },
    saveAnnotations: {
      type: Function as PropType<SaveImageAnnotations>,
      required: true
    },
    saveState: {
      type: Object as PropType<SaveState>,
      required: true
    },
    config: {
      required: true,
      type: Object as PropType<ImageAnnotatorParameters>
    },
    status: {
      type: Object as PropType<AnnotationStatus>,
      required: true
    },
    guideline: {
      required: false,
      type: Object as PropType<AnnotationGuideline>
    },
    userSelections: {
      required: true,
      type: Object as PropType<UserSelections>
    },
    saveStatus: {
      required: true,
      type: String as PropType<SaveStatusStrings>
    },
    groupsInMemory: {
      required: true,
      type: Array as PropType<ImageAnnotationGroup[]>
    },
    groupsFromServer: {
      required: true,
      type: Array as PropType<ImageAnnotationGroup[]>
    },
    task: {
      required: true,
      type: Object as PropType<GenericTaskObject>
    }
  },
  setup(props, { emit }) {
    const {
      asset,
      imageAnnotationsFromServer,
      labelsInMemory,
      saveAnnotations,
      config,
      // Type inference doesn't work for this but the type is "unknown"
      // because all properties in the interface are optional (?)
      userSelections: userSelections_,
      groupsInMemory,
      groupsFromServer
    } = toRefs(props);

    const saveState = props.saveState;

    const userSelections = userSelections_ as Ref<UserSelections>;

    const groups = computed(() => groupsInMemory.value);

    const annotationStore = createAnnotationStore({
      initialAnnotations: labelsInMemory.value,
      uiParameters: config.value
    });

    const annotations = computed(() => annotationStore.annotations.value);

    // TODO Use tool names instead of index
    const selectedToolIndex = computed(
      () => userSelections.value.toolIndex || 0
    );

    // TODO Better check if Select tool is selected
    const isSelectToolSelected = computed(() => selectedToolIndex.value === 0);

    const availableLabels = getLabelNamesFromConfig(config.value.labels);
    const labelColors = getLabelColorsFromConfig(config.value.labels);

    const selectedAnnotationId = ref(null) as Ref<string>;

    function updateSelectedAnnotationId(id: string | null) {
      selectedAnnotationId.value = id;
    }

    const selectedLabel = computed(
      () =>
        userSelections.value.label ||
        (config.value.labels.length > 0
          ? getLabelName(config.value.labels[0])
          : null)
    );

    const selectedSkeletonGraph = computed(
      () =>
        userSelections.value.skeleton ||
        (config.value.skeletons.length > 0 ? config.value.skeletons[0] : null)
    );

    function deleteAnnotation(id: string) {
      if (selectedAnnotationId.value === id) {
        selectedAnnotationId.value = null;
      }
      annotationStore.deleteAnnotation(id);
    }

    function updateSelectedToolIndex(newValue: number) {
      emit('update:userSelections', {
        ...userSelections.value,
        toolIndex: newValue
      });
    }

    function updateSelectedLabel(label: string) {
      if (isSelectToolSelected.value && selectedAnnotationId.value) {
        annotationStore.updateLabelOfAnnotation(
          selectedAnnotationId.value,
          label
        );
      }
      emit('update:userSelections', {
        ...userSelections.value,
        label
      });
    }

    const labelShortcutHandler = useLabelShortcutHandler({
      labels: config.value.labels,
      updateSelectedLabel
    });

    const showSaved = ref(false);
    const showErrorSaving = ref(false);

    const { saving, error: errorSaving, success: successSaving } = toRefs(
      saveState
    );

    whenever(errorSaving, () => {
      showErrorSaving.value = true;
    });
    whenever(successSaving, () => {
      showSaved.value = true;
    });

    const hasUnsavedAnnotations = computed(() => {
      if (imageAnnotationsFromServer.value === null) {
        return false;
      }

      return (
        !annotationStore.annotationArraysMatch(
          imageAnnotationsFromServer.value
        ) || !_isEqual(groupsInMemory.value, groupsFromServer.value)
      );
    });

    async function saveImmediate(
      assetId: string,
      annotations: ImageAnnotation[],
      groups: ImageAnnotationGroup[]
    ) {
      // State management for saving (like success or error) is tracked in saveState prop
      saveAnnotations.value(assetId, annotations, groups);
    }

    const saveDebounced = _debounce(
      function(
        assetId: string,
        annotations: ImageAnnotation[],
        groups: ImageAnnotationGroup[]
      ) {
        saveImmediate(assetId, annotations, groups);
      },
      DEBOUNCE_WAIT_MS,
      { leading: false, trailing: true }
    );

    function initSave(
      assetId: string,
      annotations: ImageAnnotation[],
      groups: ImageAnnotationGroup[]
    ) {
      saveDebounced(assetId, annotations, groups);
    }

    async function saveImmediateWithCurrentAnnotations() {
      saveDebounced.cancel();
      saveImmediate(asset.value.id, labelsInMemory.value, groupsInMemory.value);
    }

    onBeforeUnmount(() => {
      saveDebounced.cancel();
    });

    watch(asset, () => {
      // TODO This should never happen! We expect that the component
      // is destroyed whenever user navigates to a new asset
      // Cancel any pending debounced saves
      saveDebounced.cancel();
      console.log(`Asset changed, updating annotations in store`);
    });

    watch(
      annotations,
      function() {
        /**
         * This is a bit hacky way to keep the "in-memory labels" of the
         * parent component up-to-date with the imageAnnotatorStore.
         */
        console.debug(
          `Handling change in local annotations, currently has ${annotations.value.length} annotations`
        );
        if (annotationStore.annotationArraysMatch(labelsInMemory.value)) {
          console.log(`Labels up to date, skipping changes`);
          return;
        }
        const newLabels = deepCopyLabels(
          annotationStore.annotations.value,
          config.value
        );
        console.debug(`Emitting to update labels`);
        emit('update:inMemoryLabels', newLabels);
        if (hasUnsavedAnnotations.value) {
          console.debug(`Initialized save`);
          initSave(asset.value.id, newLabels, groupsInMemory.value);
        }
      },
      { deep: true }
    );

    return {
      annotationStore,
      annotations,
      showSaved,
      showErrorSaving,
      errorSaving,
      saving,
      hasUnsavedAnnotations,
      saveImmediateWithCurrentAnnotations,
      selectedToolIndex,
      updateSelectedToolIndex,
      availableLabels,
      labelColors,
      labelShortcutHandler,
      selectedLabel,
      selectedSkeletonGraph,
      updateSelectedLabel,
      selectedAnnotationId,
      updateSelectedAnnotationId,
      deleteAnnotation,
      groups
    };
  }
});
