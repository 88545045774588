import { ImageAnnotation, ImageAnnotationGroup } from './types';

export const isAnnotationInGroup = (
  annotation: ImageAnnotation,
  group: ImageAnnotationGroup
) => {
  return group.members.map(member => member._id).includes(annotation._id);
};

export const addAnnotationToGroup = (
  annotation: ImageAnnotation,
  group: ImageAnnotationGroup
) => {
  if (isAnnotationInGroup(annotation, group)) {
    return group;
  }
  const newMembers = [...group.members, { _id: annotation._id }];
  return { ...group, members: newMembers };
};

export const deleteAnnotationFromGroup = (
  annotation: ImageAnnotation,
  group: ImageAnnotationGroup
) => {
  if (!isAnnotationInGroup(annotation, group)) {
    return group;
  }
  const newMembers = group.members.filter(
    member => member._id !== annotation._id
  );
  return { ...group, members: newMembers };
};

export function updateSelectedGroupsForAnnotation({
  annotation,
  selectedGroups,
  groups
}: {
  annotation: ImageAnnotation;
  selectedGroups: ImageAnnotationGroup[];
  groups: ImageAnnotationGroup[];
}) {
  /** Update selected groups for annotation.
   * Remove from extra groups, add to missing groups
   */

  const newGroups = groups.map(group => {
    const isSelected = selectedGroups
      .map(group => group._id)
      .includes(group._id);
    if (isSelected) {
      return addAnnotationToGroup(annotation, group);
    } else {
      return deleteAnnotationFromGroup(annotation, group);
    }
  });

  return newGroups;
}
