var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex justify-center pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"dense":"","bottom":"","absolute":"","height":"30px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 pa-0",attrs:{"small":"","icon":""},on:{"click":_vm.fitToView}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-fit-to-page")])],1)]}}])},[_c('span',[_vm._v("Fit to page")])]),_c('v-menu',{attrs:{"rounded":"","offset-y":"","top":"","close-on-content-click":false,"nudge-right":-100,"nudge-top":20,"disabled":_vm.fxDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"small":"","disabled":_vm.fxDisabled}},[_vm._v(" mdi-brightness-5 ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.fxDisabled ? _vm.fxDisabledMessage : 'Brightness'))])])]}}])},[_c('div',{staticClass:"slider px-2 py-2"},[_c('v-slider',{attrs:{"hint":"Brightness","persistent-hint":"","max":"100","min":"-100","step":"1","disabled":_vm.fxDisabled},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.brightness = 0}}},[_vm._v("mdi-restore")])]},proxy:true}]),model:{value:(_vm.brightness),callback:function ($$v) {_vm.brightness=$$v},expression:"brightness"}})],1)]),_c('v-menu',{attrs:{"rounded":"","offset-y":"","top":"","close-on-content-click":false,"nudge-right":-100,"nudge-top":20,"disabled":_vm.fxDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"small":"","disabled":_vm.fxDisabled}},[_vm._v(" mdi-contrast-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.fxDisabled ? _vm.fxDisabledMessage : 'Contrast'))])])]}}])},[_c('div',{staticClass:"slider px-2 py-2"},[_c('v-slider',{attrs:{"hint":"Contrast","persistent-hint":"","max":"100","min":"-100","step":"1"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.contrast = 0}}},[_vm._v("mdi-restore")])]},proxy:true}]),model:{value:(_vm.contrast),callback:function ($$v) {_vm.contrast=$$v},expression:"contrast"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }