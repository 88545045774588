import {
  computed,
  reactive,
  ref,
  Ref,
  toRefs,
  watch
} from '@vue/composition-api';
import { until } from '@vueuse/core';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _reduce from 'lodash/reduce';
import { AnnotatedAsset, Asset, RouteUpdateListener } from '@/types';
import {
  imageAnnotationFromJSON,
  imageAnnotationToJSON,
  imageAnnotationGroupFromJSON,
  imageAnnotationGroupToJSON
} from '@/components/ImageAnnotator/transport';
import {
  ImageAnnotation,
  ImageAnnotationGroup,
  SaveState
} from '@/components/ImageAnnotator/types';
import { ImageAnnotatorParameters } from '@/components/ImageAnnotator/config';
import { loadScript } from '@/utils';
import { initOpenCV } from '@/components/ImageAnnotator/algorithms/util';
import { AnnotateAssetFunc } from '@/components/annotations/types';

export function parseLabels(
  labels: any[],
  config: ImageAnnotatorParameters
): { valid: ImageAnnotation[]; invalid: any[] } {
  function reducer(
    acc: { valid: ImageAnnotation[]; invalid: any[] },
    val: any
  ) {
    try {
      const parsed = imageAnnotationFromJSON(val, config);
      return {
        valid: [...acc.valid, parsed],
        invalid: acc.invalid
      };
    } catch (err) {
      console.warn(`Found invalid label`, val);
      return { valid: acc.valid, invalid: [...acc.invalid, val] };
    }
  }

  const initialValue = { valid: [] as ImageAnnotation[], invalid: [] as any[] };

  return _reduce(labels, reducer, initialValue);
}

export const useImageAnnotationLabels = ({
  assetAnnotationFromServer,
  uiParameters
}: {
  assetAnnotationFromServer: Ref<AnnotatedAsset | null>;
  uiParameters: Ref<ImageAnnotatorParameters>;
}) => {
  const labelsFromServer = computed(() => {
    const uiParameters_ = uiParameters.value;
    const assetAnnotation = assetAnnotationFromServer.value;

    if (!assetAnnotation || !uiParameters) {
      return [];
    }

    const { valid } = parseLabels(assetAnnotation.labels, uiParameters_);

    return valid;
  });

  return { labelsFromServer };
};

export function parseGroups(groups: any[]): ImageAnnotationGroup[] {
  return groups
    .map(group => {
      try {
        return imageAnnotationGroupFromJSON(group);
      } catch (err) {
        console.warn(`Ignoring invalid group`, group);
        return undefined;
      }
    })
    .filter(group => !!group);
}

export const useImageAnnotationGroups = ({
  assetAnnotationFromServer
}: {
  assetAnnotationFromServer: Ref<AnnotatedAsset | null>;
}) => {
  const groupsFromServer = computed(() => {
    const assetAnnotation = assetAnnotationFromServer.value;

    if (!assetAnnotation) {
      return [];
    }

    const groups = parseGroups(assetAnnotationFromServer.value.groups || []);

    return groups;
  });

  return { groupsFromServer };
};

export const useInMemoryLabels = ({
  assetId,
  assetAnnotationFromServer,
  assetPredictionFromServer,
  assetPredictionLoadedForAssetId,
  assetAnnotationLoadedForAssetId,
  uiParameters
}: {
  assetId: Ref<string | null>;
  assetAnnotationFromServer: Ref<AnnotatedAsset | null>;
  assetPredictionFromServer: Ref<AnnotatedAsset | null>;
  assetPredictionLoadedForAssetId: Ref<string | null>;
  assetAnnotationLoadedForAssetId: Ref<string | null>;
  uiParameters: Ref<ImageAnnotatorParameters>;
}) => {
  const inMemoryLabels = ref([]) as Ref<ImageAnnotation[] | null>;
  const invalidLabels = ref([]) as Ref<ImageAnnotation[] | null>;
  const loadedForAssetId = ref(null as string | null);

  function mutateFromServer() {
    const initializationAnnotation =
      assetAnnotationFromServer.value || assetPredictionFromServer.value;

    const { valid, invalid } = parseLabels(
      initializationAnnotation?.labels || [],
      uiParameters.value
    );

    inMemoryLabels.value = valid;
    invalidLabels.value = invalid;
  }

  function setInMemoryLabels(labels: ImageAnnotation[]) {
    inMemoryLabels.value = labels;
  }

  watch(
    assetId,
    async function() {
      loadedForAssetId.value = null;
      inMemoryLabels.value = null;
      invalidLabels.value = null;
      // Asset ID changed, need to refresh from server once those are loaded
      await until(assetAnnotationLoadedForAssetId).toBe(assetId.value);
      await until(assetPredictionLoadedForAssetId).toBe(assetId.value);
      await until(uiParameters).toBeTruthy();

      mutateFromServer();
      loadedForAssetId.value = assetId.value;
    },
    { immediate: true }
  );

  return { inMemoryLabels, loadedForAssetId, setInMemoryLabels, invalidLabels };
};

export const useInMemoryGroups = ({
  assetId,
  assetAnnotationFromServer,
  assetAnnotationLoadedForAssetId,
  assetPredictionFromServer,
  assetPredictionLoadedForAssetId
}: {
  assetId: Ref<string | null>;
  assetAnnotationFromServer: Ref<AnnotatedAsset | null>;
  assetAnnotationLoadedForAssetId: Ref<string | null>;
  assetPredictionFromServer: Ref<AnnotatedAsset | null>;
  assetPredictionLoadedForAssetId: Ref<string | null>;
}) => {
  const inMemoryGroups = ref([]) as Ref<ImageAnnotationGroup[] | null>;
  const loadedForAssetId = ref(null as string | null);

  function mutateFromServer() {
    const initializationAnnotation =
      assetAnnotationFromServer.value || assetPredictionFromServer.value;

    const newGroups = parseGroups(initializationAnnotation?.groups || []);

    inMemoryGroups.value = newGroups;
  }

  function setInMemoryGroups(groups: ImageAnnotationGroup[]) {
    inMemoryGroups.value = groups;
  }

  watch(
    assetId,
    async function() {
      loadedForAssetId.value = null;
      inMemoryGroups.value = null;
      // Asset ID changed, need to refresh from server once those are loaded
      await until(assetAnnotationLoadedForAssetId).toBe(assetId.value);
      await until(assetPredictionLoadedForAssetId).toBe(assetId.value);

      mutateFromServer();
      loadedForAssetId.value = assetId.value;
    },
    { immediate: true }
  );

  return { inMemoryGroups, loadedForAssetId, setInMemoryGroups };
};

export const useHasUnsavedLabels = ({
  labelsFromServer,
  labelsInMemory,
  groupsFromServer,
  groupsInMemory
}: {
  labelsFromServer: Ref<ImageAnnotation[]>;
  labelsInMemory: Ref<ImageAnnotation[]>;
  groupsFromServer: Ref<ImageAnnotationGroup[]>;
  groupsInMemory: Ref<ImageAnnotationGroup[]>;
}) => {
  return computed(() => {
    if (labelsFromServer.value == null || labelsInMemory.value == null) {
      return false;
    }

    const serverLabelsWithoutTraceIds = labelsFromServer.value.map(ann =>
      _omit(ann, 'trace_id')
    );

    const labelsInMemoryWithoutTraceIds = labelsInMemory.value.map(ann =>
      _omit(ann, 'trace_id')
    );

    return (
      !_isEqual(serverLabelsWithoutTraceIds, labelsInMemoryWithoutTraceIds) ||
      !_isEqual(groupsFromServer.value, groupsInMemory.value)
    );
  });
};

export const useSaving = ({
  asset,
  labelsFromServer,
  groupsFromServer,
  onAnnotateAsset,
  reloadAssetAnnotation
}: {
  asset: Ref<Asset>;
  labelsFromServer: Ref<ImageAnnotation[]>;
  groupsFromServer: Ref<ImageAnnotationGroup[]>;
  onAnnotateAsset: Ref<AnnotateAssetFunc>;
  reloadAssetAnnotation: () => Promise<void>;
}) => {
  const state = reactive({
    saving: false,
    error: null as Error | null,
    success: false
  });

  async function saveLabels(
    assetId: string,
    labels: ImageAnnotation[],
    groups: ImageAnnotationGroup[]
  ): Promise<void> {
    if (assetId !== asset.value.id) {
      throw Error(`Expected asset ID to be active to save it: ${assetId}`);
    }

    const previousLabels = labelsFromServer.value;
    const previousAnnotationsWithoutTraceIds = previousLabels.map(ann =>
      _omit(ann, 'trace_id')
    );

    const previousGroups = groupsFromServer.value;

    // Warn if nothing changed in the annotations
    if (
      _isEqual(labels, previousAnnotationsWithoutTraceIds) &&
      labels.length > 0 &&
      _isEqual(groups, previousGroups)
    ) {
      console.warn('Nothing changed, saving anyway');
    }

    console.log('Saving new labels', labels);

    const labelsAsJSONs = labels.map(label => imageAnnotationToJSON(label));
    const groupsAsJSONs = groups.map(group =>
      imageAnnotationGroupToJSON(group)
    );

    state.saving = true;
    state.error = null;
    state.success = false;
    try {
      await onAnnotateAsset.value({
        assetId,
        labels: labelsAsJSONs,
        groups: groupsAsJSONs
      });
      state.success = true;
      if (assetId === asset.value.id) {
        reloadAssetAnnotation();
      }
    } catch (err) {
      console.error(`Failed saving image annotations`, err);
      state.error = err;
    } finally {
      state.saving = false;
    }
  }

  return { ...toRefs(state), saveLabels };
};

export const useConfirmDialog = (
  save: () => Promise<void>,
  saveState: SaveState,
  hasUnsavedLabels: Ref<boolean>,
  registerRouteUpdateListener: Ref<(func: RouteUpdateListener) => void>
) => {
  const confirmDialogIsOpen = ref(false);

  const confirmDialogOnCancel = ref(() => {});
  const confirmDialogOnSave = ref(async () => {});
  const confirmDialogOnSkipSave = ref(() => {});

  const onRouteUpdate = async (): Promise<boolean> => {
    /** Check that user can save labels if there are unsaved labels */
    if (hasUnsavedLabels.value) {
      console.debug(`Route update: Has unsaved labels`);
      return new Promise(resolve => {
        confirmDialogOnCancel.value = () => {
          confirmDialogIsOpen.value = false;
          resolve(false);
        };
        confirmDialogOnSave.value = async () => {
          await save();
          if (saveState.success) {
            confirmDialogIsOpen.value = false;
            resolve(true);
          } else {
            resolve(false);
          }
        };
        confirmDialogOnSkipSave.value = () => {
          confirmDialogIsOpen.value = false;
          resolve(true);
        };
        confirmDialogIsOpen.value = true;
      });
    } else {
      console.debug(`Route update: no unsaved labels`);
      return true;
    }
  };

  registerRouteUpdateListener.value(onRouteUpdate);

  return {
    confirmDialogIsOpen,
    confirmDialogOnCancel,
    confirmDialogOnSave,
    confirmDialogOnSkipSave
  };
};

const OPENCV_URL = 'https://docs.opencv.org/3.4.0/opencv.js';

export const useOpenCV = () => {
  const openCVLoaded = ref(false);
  const loadingOpenCV = ref(false);
  async function initializeOpenCV(): Promise<void> {
    if (openCVLoaded.value) {
      return;
    }

    console.log(`Loading OpenCV from ${OPENCV_URL}`);

    loadingOpenCV.value = true;
    try {
      await loadScript(OPENCV_URL);
      openCVLoaded.value = true;
    } finally {
      loadingOpenCV.value = false;
    }

    initOpenCV((window as any).cv);
  }
  initializeOpenCV();
  return { openCVLoaded, loadingOpenCV };
};
