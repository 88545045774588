








































import { defineComponent, PropType, ref, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: {
      required: true,
      type: Boolean
    },
    onSave: {
      required: true,
      type: Function as PropType<() => Promise<void>>
    },
    onSkipSave: {
      required: true,
      type: Function as PropType<() => void>
    },
    onCancel: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  setup(props) {
    const { onSave } = toRefs(props);
    const loading = ref(false);
    async function onSaveClicked() {
      loading.value = true;
      try {
        await onSave.value();
      } finally {
        loading.value = false;
      }
    }

    return {
      onSaveClicked,
      loading
    };
  }
});
