import paper from 'paper';
import { bboxOfClicksUnitary } from '@/components/ImageAnnotator/algorithms/refine';
import { Point2D } from '@/components/ImageAnnotator/algorithms/refine-types';
import { sleep } from '@/components/ImageAnnotator/algorithms/util';
import {
  DrawerMouseTool,
  createPaperTool,
  ToolProps
} from '@/components/ImageAnnotator/tools';
import {
  RefineClicks,
  RefineClicksResult
} from '@/components/ImageAnnotator/types';
import { BoundingBoxPathContainer } from '@/components/ImageAnnotator/annotation-container';

const MIN_DRAG_DISTANCE_POINTS = 1;
const CLICK_SCREEN_RADIUS = 3;

const MOUSEBUTTON_LEFT = 0;
const MOUSEBUTTON_RIGHT = 2;

export class ClickTool implements DrawerMouseTool {
  private readonly props: ToolProps;
  private paperTool: paper.Tool;
  private rectangle?: paper.Shape.Rectangle;
  private isDragging = false;
  private mouseDown?: paper.Point;
  private isProcessing = false;
  private clickPoints = [] as paper.Point[];
  private screenPoints = [] as paper.Shape[];
  private clickPointsNegative = [] as paper.Point[];
  private screenPointsNegative = [] as paper.Shape[];
  private refineFunction?: RefineClicks;

  private refineResult?: RefineClicksResult;
  private refinedRectangleScreen?: paper.Shape.Rectangle;
  private refinedMaskScreen?: paper.Raster;

  constructor({
    props,
    refineFunction
  }: {
    props: ToolProps;
    refineFunction?: RefineClicks;
  }) {
    this.props = props;
    this.refineFunction = refineFunction;
    this.paperTool = this.setupPaperTool();
  }

  private get cursor() {
    return this.isProcessing ? 'wait' : 'crosshair';
  }

  deactivate() {
    this.resetClicks();
    this.paperTool.remove();
  }

  private setupPaperTool() {
    const tool = createPaperTool({
      onMouseUp: ev => this.onMouseUp(ev),
      onMouseDown: ev => this.onMouseDown(ev),
      onMouseMove: ev => this.onMouseMove(ev),
      onMouseDrag: ev => this.onMouseDrag(ev),
      props: this.props,
      cursor: this.cursor
    });
    tool.minDistance = MIN_DRAG_DISTANCE_POINTS;
    return tool;
  }

  private onMouseDown(event: paper.MouseEvent) {
    if (this.isProcessing) {
      this.mouseDown = null;
      this.isDragging = false;
      return;
    }

    if (this.props.imageBounds.contains(event.point)) {
      // eslint-disable-next-line
      if ((event as any).event.button === MOUSEBUTTON_LEFT) {
        this.mouseDown = event.point;
        this.addClick(event.point, event.modifiers.shift);
      }
    }
    this.isDragging = false;
  }

  private addClick(point: paper.Point, isNegative: boolean) {
    if (!isNegative) {
      this.clickPoints.push(point);
      const c = new paper.Shape.Circle(point, CLICK_SCREEN_RADIUS);
      c.strokeColor = new paper.Color(0);
      c.fillColor = new paper.Color(1);
      this.screenPoints.push(c);
    } else {
      //isNegative
      this.clickPointsNegative.push(point);
      const c = new paper.Shape.Circle(point, CLICK_SCREEN_RADIUS);
      c.strokeColor = new paper.Color(1, 0, 0);
      c.fillColor = new paper.Color(0.8);
      this.screenPointsNegative.push(c);
    }
    this.refreshScreenElements();
    this.updateRefinement();
  }

  private resetClicks() {
    if (this.rectangle) {
      this.rectangle.remove();
      this.rectangle = null;
    }

    this.screenPoints.forEach(c => c.remove());
    this.screenPoints = [];
    this.clickPoints = [];

    this.screenPointsNegative.forEach(c => c.remove());
    this.screenPointsNegative = [];
    this.clickPointsNegative = [];

    if (this.refinedRectangleScreen) {
      this.refinedRectangleScreen.remove();
      this.refinedRectangleScreen = null;
    }

    if (this.refinedMaskScreen) {
      this.refinedMaskScreen.remove();
      this.refinedMaskScreen = null;
    }

    this.refineResult = null;
  }

  private async extractAnnotationFromClicks() {
    // make sure that the bounding box has been updated to screen and then read the bounds
    // from there
    this.refreshScreenElements();

    const rectangle = this.refinedRectangleScreen || this.rectangle;
    if (!rectangle) {
      return;
    }

    const stroke = this.props.activeClassColor();
    const transparent = new paper.Color(
      stroke.red,
      stroke.green,
      stroke.blue,
      0.1
    );
    rectangle.fillColor = transparent;
    rectangle.strokeColor = stroke;

    const pathContainer = BoundingBoxPathContainer.createFromPath(
      rectangle.toPath(true)
    );
    this.props.createAnnotation({ container: pathContainer });
  }

  private onMouseMove(_: paper.MouseEvent) {
    this.updateCursor();
  }

  private updateCursor() {
    this.props.setCursor?.(this.cursor);
  }

  private refreshScreenElements() {
    if (this.clickPoints.length == 0) {
      if (this.rectangle) {
        this.rectangle.remove();
        this.rectangle = null;
      }
      return;
    }

    // visualise the refinement result if there is any
    if (this.refineResult && this.refineResult.box) {
      if (this.rectangle) {
        this.rectangle.remove();
        this.rectangle = null;
      }
      if (this.refinedRectangleScreen) {
        this.refinedRectangleScreen.remove();
      }
      const rectangle = new paper.Shape.Rectangle(this.refineResult.box);
      const opacity = 0;
      const stroke = this.props.activeClassColor();
      const transparent = new paper.Color(
        stroke.red,
        stroke.green,
        stroke.blue,
        opacity
      );
      rectangle.fillColor = transparent;
      rectangle.strokeColor = stroke;
      this.refinedRectangleScreen = rectangle;

      if (this.refinedMaskScreen) {
        this.refinedMaskScreen.remove();
      }

      this.refinedMaskScreen = null;
      if (this.refineResult.b64Mask.length > 0) {
        this.refinedMaskScreen = this.props.mask.show({
          maskBase64: this.refineResult.b64Mask,
          center: this.refineResult.box.center
        });
      }
    } else {
      // if no refinement results,
      // show the bounding box of positive clicks

      const clickArray: Point2D[] = this.clickPoints.map(p => {
        return { x: p.x, y: p.y };
      });
      const bbox = bboxOfClicksUnitary(clickArray);

      const centerPt = new paper.Point(
        bbox[0] + 0.5 * bbox[2],
        bbox[1] + 0.5 * bbox[3]
      );
      //const topLeft = new paper.Point(bbox[0], bbox[1]);
      const size = new paper.Size(bbox[2], bbox[3]);

      if (this.rectangle) {
        this.rectangle.position = centerPt;
        this.rectangle.size = size;
      } else {
        this.rectangle = this.drawRectangle(centerPt, size, 0);
      }
    }
  }

  private drawRectangle(
    from: paper.Point,
    size: paper.Size,
    opacity = 0.1
  ): paper.Shape.Rectangle {
    const rectangle = new paper.Shape.Rectangle(from, size);
    const stroke = this.props.activeClassColor();
    const transparent = new paper.Color(
      stroke.red,
      stroke.green,
      stroke.blue,
      opacity
    );
    rectangle.fillColor = transparent;
    rectangle.strokeColor = stroke;
    return rectangle;
  }

  private updateRectangle(
    item: paper.Shape.Rectangle,
    start: paper.Point,
    last: paper.Point
  ) {
    const xs = [start.x, last.x];
    const ys = [start.y, last.y];
    const minX = Math.min(...xs);
    const maxX = Math.max(...xs);
    const minY = Math.min(...ys);
    const maxY = Math.max(...ys);
    const width = maxX - minX;
    const height = maxY - minY;
    const pos = new paper.Point(minX + 0.5 * width, minY + 0.5 * height);
    const size = new paper.Size(width, height);
    item.position = pos;
    item.size = size;
  }

  private onMouseDrag(event: paper.MouseEvent) {
    if (!this.mouseDown) {
      return;
    }
    // eslint-disable-next-line
    if ((event as any).event.button === MOUSEBUTTON_RIGHT) {
      return;
    }

    if (this.clickPoints.length === 1) {
      this.isDragging = true;

      const bounds = this.props.imageBounds;
      const adjustedPt = paper.Point.min(
        bounds.bottomRight,
        paper.Point.max(bounds.topLeft, event.point)
      );

      if (this.rectangle) {
        this.updateRectangle(this.rectangle, this.mouseDown, adjustedPt);
      }
    }
  }

  private onMouseUp(event: paper.MouseEvent) {
    // eslint-disable-next-line
    switch ((event as any).event.button) {
      case MOUSEBUTTON_LEFT:
        if (this.isDragging) {
          this.addClick(event.point, event.modifiers.shift);
          this.mouseDown = null;
          this.isDragging = false;
        }
        break;
      case MOUSEBUTTON_RIGHT:
        if (!event.modifiers.shift) {
          this.extractAnnotationFromClicks();
        }
        this.resetClicks();
        break;
    }
  }

  private async updateRefinement() {
    if (!this.refineFunction) {
      return;
    }

    if (this.clickPoints.length < 2) {
      this.refineResult = null;
      return;
    }

    this.isProcessing = true;
    this.updateCursor();
    await sleep(0.1); // DOM update

    this.refineResult = await this.refineFunction(
      this.clickPoints,
      this.clickPointsNegative,
      this.props.imageElement
    );
    console.log('got refinement result', this.refineResult);
    this.refreshScreenElements();
    this.isProcessing = false;
    this.updateCursor();
  }
}
