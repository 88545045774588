import _findKey from 'lodash/findKey';

import { Ref, reactive, watch, toRefs, computed } from '@vue/composition-api';

import { arrayBufferToBase64 } from '@/api/utils';
import { Asset, FetchMedia } from '@/types';

/** Use asset image data as Base64 */
export function useActiveImageSrc(
  asset: Ref<Asset>,
  fetchMedia: Ref<FetchMedia>
) {
  const state = reactive({
    loading: false,
    error: null as Error | null,
    src: null as string | null
  });

  async function mutate() {
    if (!asset.value) {
      state.src = null;
      return;
    }

    const assetId = asset.value.id;

    state.error = null;

    const medias = asset.value.media;

    const imageMediaSlug = _findKey(medias, val =>
      val.media_type.startsWith('image/')
    );

    if (!imageMediaSlug) {
      const mediaTypes = Object.values(medias)
        .map(media => media.media_type)
        .join(',');
      state.error = new Error(`No image found in media: ${mediaTypes}`);
      return;
    }

    const mediaType = medias[imageMediaSlug].media_type;

    state.loading = true;

    try {
      const mediaArrayBuffer = await fetchMedia.value({
        assetId,
        mediaSlug: imageMediaSlug,
        mediaType
      });
      const base64 = arrayBufferToBase64(mediaArrayBuffer);
      state.src = `data:${mediaType};base64, ${base64}`;
    } catch (error) {
      state.error = error;
    } finally {
      state.loading = false;
    }
  }

  watch(asset, mutate, { immediate: true });

  return { ...toRefs(state), mutate };
}

export const SaveStatus = {
  Saved: 'Saved',
  Saving: 'Saving',
  Empty: 'Empty',
  UnsavedChanges: 'UnsavedChanges',
  NoAnnotation: 'NoAnnotation'
} as const;

export type SaveStatusStrings = keyof typeof SaveStatus;

export const useSaveStatus = ({
  loading,
  isInitialized,
  errorSaving,
  saving,
  hasUnsavedLabels,
  loadingAssetAnnotation,
  hasAnnotation
}: {
  loading: Ref<boolean>;
  isInitialized: Ref<boolean>;
  errorSaving: Ref<Error | null>;
  saving: Ref<boolean>;
  hasUnsavedLabels: Ref<boolean>;
  loadingAssetAnnotation: Ref<boolean>;
  hasAnnotation: Ref<boolean>;
}) =>
  computed(() => {
    return !isInitialized.value ||
      loading.value ||
      errorSaving.value ||
      loadingAssetAnnotation.value
      ? SaveStatus.Empty
      : saving.value
      ? SaveStatus.Saving
      : !hasAnnotation.value
      ? SaveStatus.NoAnnotation
      : hasUnsavedLabels.value
      ? SaveStatus.UnsavedChanges
      : SaveStatus.Saved;
  });
