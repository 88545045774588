






import Vue from 'vue';

export default Vue.extend({
  name: 'KeyboardShortcutIcon',
  props: {
    shortcut: {
      required: true,
      type: String
    }
  }
});
