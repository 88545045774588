import { computed, Ref, ref } from '@vue/composition-api';
import _fromPairs from 'lodash/fromPairs';
import _zip from 'lodash/zip';
import { ImageAnnotatorParameters } from '@/components/ImageAnnotator/config';
import { ImageAnnotation } from '@/components/ImageAnnotator/types';
import {
  annotationsMatch,
  deepCopyLabels
} from '@/components/ImageAnnotator/utils';

export interface AnnotationStore {
  annotations: Ref<ImageAnnotation[]>;
  addAnnotation(annotation: ImageAnnotation): void;
  deleteAnnotation(annotationId: string): void;
  updateAnnotation(annotationId: string, newValue: ImageAnnotation): void;
  updateLabelOfAnnotation(annotationId: string, newLabel: string): void;
  annotationArraysMatch(anns: ImageAnnotation[]): boolean;
}

export const createAnnotationStore = ({
  initialAnnotations,
  uiParameters
}: {
  initialAnnotations: ImageAnnotation[];
  uiParameters: ImageAnnotatorParameters;
}): AnnotationStore => {
  const annotationsRef = ref(
    deepCopyLabels(initialAnnotations, uiParameters)
  ) as Ref<ImageAnnotation[]>;

  const annotationsReadOnly = computed(() => annotationsRef.value);

  const idToAnnotation = computed(() =>
    _fromPairs(
      annotationsRef.value.map(annotation => [annotation._id, annotation])
    )
  );

  const addAnnotation = (annotation: ImageAnnotation) => {
    console.log(`Adding new annotation`, annotation);
    annotationsRef.value.push(annotation);
  };

  const deleteAnnotation = (annotationId: string) => {
    console.log(`Deleting annotation with ID`, annotationId);
    annotationsRef.value = annotationsRef.value.filter(
      annotation => annotation._id !== annotationId
    );
  };

  const updateAnnotation = (
    annotationId: string,
    newValue: ImageAnnotation
  ) => {
    console.log(`Updating annotation`, annotationId, newValue);
    annotationsRef.value = annotationsRef.value.map(annotation =>
      annotation._id === annotationId ? newValue : annotation
    );
  };

  const updateLabelOfAnnotation = (annotationId: string, newLabel: string) => {
    const oldAnnotation = idToAnnotation.value[annotationId];
    // Skeleton label should not be updated along with other geometry labels
    if (oldAnnotation.geometry.kind === 'Skeleton') {
      return;
    }
    const newAnnotation = { ...oldAnnotation, label: newLabel };
    updateAnnotation(annotationId, newAnnotation);
  };

  function annotationArraysMatch(anns: ImageAnnotation[]): boolean {
    if (annotationsRef.value == null) {
      return false;
    }
    if (anns.length != annotationsRef.value.length) {
      return false;
    }

    const allMatch = _zip(anns, annotationsRef.value).every(([val1, val2]) => {
      return !!val1 && !!val2 && annotationsMatch(val1, val2);
    });

    return allMatch;
  }

  return {
    annotations: annotationsReadOnly,
    addAnnotation,
    updateAnnotation,
    updateLabelOfAnnotation,
    deleteAnnotation,
    annotationArraysMatch
  };
};
